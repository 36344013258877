import classNames from 'classnames'
import { ReactComponent as TrashCanIcon } from 'img/trashCan.svg'
import style from './TrashCanButton.module.scss'

interface TrashCanButtonProps {
    onClick: () => void
    disabled?: boolean
    title?: string
    className?: string
}

export default function TrashCanButton({
    onClick,
    disabled,
    title,
    className,
}: TrashCanButtonProps) {
    const buttonClasses = classNames(style.button, { [`${className}`]: !!className })
    const iconClasses = classNames(style.icon, {
        [style.disabled]: disabled,
    })
    return (
        <button className={buttonClasses} onClick={onClick} title={title} disabled={disabled}>
            <TrashCanIcon className={iconClasses} />
        </button>
    )
}
