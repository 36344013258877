import { Breadcrumb, ControllerPageLink, CurrentPage, mapSuccess, OverviewPageLink } from 'common'
import ControllerModules from 'controller/components/ControllerModules/ControllerModules'
import DhcpLeases from 'controller/components/DhcpLeases/DhcpLeases'
import NetworkAdapters from 'controller/components/NetworkAdapters/NetworkAdapters'
import useControllerData from 'controller/hooks/useControllerData'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import style from './DeviceDetailsPage.module.scss'

export default function DeviceDetailsPage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }

    const { t } = useTranslation()

    const controllerData = useControllerData(controllerId)
    const adapters = mapSuccess(controllerData, (st) => st.networkAdapters)

    return (
        <>
            <Breadcrumb className={style.breadcrumb}>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <CurrentPage currentPage={t('deviceDetailsTitle')} />
            </Breadcrumb>
            <div className={style.page}>
                <DhcpLeases controllerId={controllerId} />
                <ControllerModules controllerId={controllerId} className={style.card} />
                <NetworkAdapters adapters={adapters} />
            </div>
        </>
    )
}
