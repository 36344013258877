import { AccessControl } from 'chargePoint'
import { useGraphQlMutation } from 'common'

const createChargePointQuery = `
    mutation CreateChargePoint(
        $clusterId: Int!
        $chargePointId: String!
        $accessControl: AccessControlType!
    ) {
        createChargePoint(
            clusterId: $clusterId
            chargePointId: $chargePointId
            accessControl: $accessControl
        ) {
            id
            clusterId
            chargePointId
            accessControl
        }
    }
`

interface CreateChargePointInput {
    clusterId: number
    chargePointId: string
    accessControl: AccessControl
}

interface CreateChargePointResponse {
    createChargePoint: {
        id: number
        clusterId: number
        chargePointId: string
        accessControl: AccessControl
    }
}

export default function useCreateChargePoint() {
    return useGraphQlMutation<CreateChargePointResponse, CreateChargePointInput>(
        createChargePointQuery
    )
}
