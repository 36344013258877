import {
    Breadcrumb,
    Button,
    Card,
    ConfirmModal,
    ControllerPageLink,
    CurrentPage,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import style from './ControllerOcppServerPage.module.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import useSetOcppServer from 'controller/hooks/useSetOcppServer'
import { useParams } from 'react-router-dom'
import useSetMigrationStatus from 'controller/hooks/useSetMigrationStatus'
import usePopupModal from 'common/hooks/usePopupModal'
import useGetMigrationStatus from 'controller/hooks/useGetMigrationsStatus'

const defaultOcppServer = process.env.REACT_APP_OCPP_SERVER_URL

export default function ControllerOcppServerPage(): JSX.Element {
    const { t } = useTranslation()
    const dispatchPopup = usePopupModal()
    const { controllerId } = useParams() as { controllerId: string }

    const [migrationStatusResp] = useGetMigrationStatus(controllerId)
    const [serverUri, setServerUri] = useState(defaultOcppServer as string)
    const [markedAsDone, setMarkedAsDone] = useState(false)
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)

    useEffect(() => {
        if (migrationStatusResp.status === 'success') {
            setMarkedAsDone(migrationStatusResp.data.controllerMigrationDoneStatus ?? false)
        }
    }, [migrationStatusResp])

    const [occpServerResponse, setOcppServer] = useSetOcppServer()
    const [_migrationResponse, setMigrationStatus] = useSetMigrationStatus()

    const getContent = () => {
        switch (occpServerResponse.status) {
            case 'error':
                return `Failed to load controllers: ${occpServerResponse.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return (
                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th>{t('controllerOcppTableChargPoint')}</th>
                                <th>{t('controllerOcppTableSuccess')}</th>
                                <th>{t('controllerOcppTableStatusMessage')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {occpServerResponse.data.setOcppServer.map(
                                (row: { identity: string; success: boolean; message: string }) => (
                                    <tr key={row.identity}>
                                        <td>{row.identity}</td>
                                        <td>{row.success.toString()}</td>
                                        <td>{row.message}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                )
        }
    }

    const handleOnConfirm = () => {
        setOcppServer({ input: { serverUri }, deviceId: controllerId })
        setConfirmModalVisible(false)
    }

    const handleSetOcppServer = () => {
        setConfirmModalVisible(true)
    }

    const handleMarkAsDone = () => {
        setMigrationStatus({ deviceId: controllerId, status: markedAsDone })
            .then((resp) => {
                if (resp.data) {
                    const { status, message } = resp.data.setControllerMigrationDoneStatus
                    if (status) {
                        dispatchPopup({ message: t('markedAsDoneUpdated') })
                    } else {
                        dispatchPopup({ message: message })
                    }
                }
            })
            .catch((error) => {
                dispatchPopup({ message: error.message })
            })
    }

    return (
        <>
            <Breadcrumb>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <CurrentPage currentPage={t('controllerOcppTitle')} />
            </Breadcrumb>
            <Card className={style.card}>
                <div className={style.headerWrapper}>
                    <h2>{t('controllerOcppTitle')}</h2>
                </div>
                <div className={style.inputContainer}>
                    <label htmlFor="ocppServerUri">{t('ocppServerUriFieldLabel')}:</label>
                    <input
                        id="ocppServerUri"
                        value={serverUri}
                        onChange={(event) => {
                            setServerUri(event.currentTarget.value)
                        }}
                    />
                    <Button type="primary" click={handleSetOcppServer}>
                        {t('execute')}
                    </Button>
                </div>

                <div className={style.checkboxContainer}>
                    <input
                        id="markAsDone"
                        type="checkbox"
                        className={style.checkbox}
                        checked={markedAsDone}
                        onChange={(e) => {
                            setMarkedAsDone(e.target.checked)
                        }}
                    />
                    <label htmlFor="markAsDone">{t('ocppServerUrlDoneLabel')}</label>
                    <Button className={style.saveButton} type="secondary" click={handleMarkAsDone}>
                        {t('save')}
                    </Button>
                </div>

                {getContent()}
            </Card>
            {confirmModalVisible && (
                <ConfirmModal
                    show={confirmModalVisible}
                    close={() => {
                        setConfirmModalVisible(false)
                    }}
                    onConfirm={handleOnConfirm}
                    className={style.modal}
                >
                    <h2>{t('confirmOcppUriModalTitle')}</h2>
                    <p>{t('confirmOcppUriModalText')}</p>
                </ConfirmModal>
            )}
        </>
    )
}
