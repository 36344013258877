import { useGraphQlMutation } from "common";

const requestFirmwareDownloadQuery = `
mutation DownloadFirmware($id:String!, $fw:String!){
    requestFirmwareDownload(deviceId:$id, firmware:$fw)
}
`;

export type FirmwareDownloadStatus = "ALREADY_EXISTS" | "DOWNLOAD_STARTED";

type downloadFirmwareQueryVariables = { id: string; fw: string };
type downloadFirmwareResponse = { requestFirmwareDownload: FirmwareDownloadStatus };

export default function useRequestFirmwareDownload() {
    return useGraphQlMutation<downloadFirmwareResponse, downloadFirmwareQueryVariables>(requestFirmwareDownloadQuery);
}
