import { Card, useConnectorLoadFetchFunction } from 'common'
import { DataRecord } from 'common/types/chartTypes'
import { ZoomableLoadChart } from 'controller'
import { useTranslation } from 'react-i18next'

interface ClusterLoadProps {
    controllerId: string
    className?: string
}

export default function ConnectorLoad({ controllerId, className }: ClusterLoadProps) {
    const fetchFn = useConnectorLoadFetchFunction(controllerId)

    const { t } = useTranslation()

    return (
        <Card title={t('connectorLoadTitle')} className={className}>
            <ZoomableLoadChart
                fetchFn={fetchFn}
                dataMapper={(res) => ({
                    dataKeys: res.connectors.map((conn) => conn.connectorId).sort(),
                    records: res.connectors.flatMap((conn) =>
                        conn.data.map((x) => [x[0], conn.connectorId, x[1]] as DataRecord)
                    ),
                })}
            />
        </Card>
    )
}
