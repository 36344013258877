import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import useValidatedField from 'common/hooks/useValidatedField'
import { Button, ValidatedTextField } from 'common'
import { alphaNumericValidator, serialNumberValidator } from 'config/utils/validators'
import generateReactKey from 'config/utils/generateReactKey'
import { ChargePointSetupRow, ChargePointSetupStatus } from 'electricianView/types/chargePointSetup'
import { PhaseMapper } from 'electricianView/components/PhaseMapper/PhaseMapper'
import { NetworkLocation } from 'electricianView/components/NetworkLocation/NetworkLocation'
import { normalizeSerialNumber } from 'electricianView/utils/normalizeSerialNumber'

interface CreateChargePointProps {
    existingTableRows: ChargePointSetupRow[]
    chargePointSetupRow?: ChargePointSetupRow
    saveChargePoint: (row: ChargePointSetupRow) => void
    deleteChargePoint: (row: ChargePointSetupRow) => void
    closeView: () => void
}

export function CreateChargePoint({
    existingTableRows,
    chargePointSetupRow,
    saveChargePoint,
    deleteChargePoint,
    closeView,
}: CreateChargePointProps): JSX.Element {
    const { t } = useTranslation()

    const cps = chargePointSetupRow

    const parkingSpotField = useValidatedField(cps?.parkingSpot ?? '', alphaNumericValidator)
    const smsCodeField = useValidatedField(cps?.smsCode ?? '', alphaNumericValidator)
    const serialNumberField = useValidatedField(cps?.serialNumber ?? '', serialNumberValidator)

    const descriptionField = useValidatedField(cps?.description ?? '', (_) => true)
    const clusterField = useValidatedField(cps?.cluster ?? '', (cluster) => cluster.length > 0)
    const [networkLocation, setNetworkLocation] = useState(cps?.networkLocation)
    const statusField = useValidatedField(cps?.status ?? '', (_) => true)

    const [phaseMapping, setPhaseMapping] = useState(
        cps && { netType: cps.netType, phaseMapping: cps.phaseMapping }
    )

    function makeSetupRow(): ChargePointSetupRow {
        return {
            id: cps?.id,
            parkingSpot: parkingSpotField.value,
            smsCode: smsCodeField.value,
            // Normalize serial number by removing dashes and underscores
            serialNumber: normalizeSerialNumber(serialNumberField.value),
            netType: phaseMapping?.netType ?? 'IT',
            phaseMapping: phaseMapping?.phaseMapping ?? '',
            description: descriptionField.value,
            cluster: clusterField.value,
            networkLocation: networkLocation ?? '',
            status: statusField.value as ChargePointSetupStatus,
            reactKey: cps?.reactKey ?? generateReactKey(),
        }
    }

    function getInvalidFields(): [any[], string[]] {
        const invalidFields = []
        const reasons = []

        // Check that the values in the boxes are valid

        for (let field of [parkingSpotField, smsCodeField, serialNumberField, clusterField]) {
            if (!field.isValid) {
                invalidFields.push(field)
                reasons.push('')
            }
        }

        const newCps = makeSetupRow()
        function fieldIsUnique(field: keyof ChargePointSetupRow): boolean {
            return !existingTableRows.some((row) => row !== cps && row[field] === newCps[field])
        }

        if (!fieldIsUnique('serialNumber')) {
            invalidFields.push(serialNumberField)
            reasons.push('Serial number already exists')
        }

        if (!fieldIsUnique('smsCode')) {
            invalidFields.push(smsCodeField)
            reasons.push('SMS code already exists')
        }

        // if (!fieldIsUnique('parkingSpot')) {
        //     invalidFields.push(parkingSpotField)
        //     reasons.push('Parking spot already exists')
        // }

        return [invalidFields, reasons]
    }

    const [invalidFields, invalidFieldReasons] = getInvalidFields()
    const allFieldsAreValid = phaseMapping && invalidFields.length === 0

    return (
        <div className="flex flex-col ">
            <div className="flex flex-row justify-between">
                <Button click={closeView} type="secondary">
                    {t('back')}
                </Button>
                {cps && (
                    <Button
                        className="bg-red-700 text-white"
                        type="primary"
                        click={() => {
                            deleteChargePoint(cps as ChargePointSetupRow)
                            closeView()
                        }}
                    >
                        {t('deleteChargePoint')}
                    </Button>
                )}
            </div>
            <div className="flex flex-row justify-between mt-4">
                <div className="flex flex-col flex-grow">
                    <div className="flex flex-row">
                        <div className="flex flex-col sm:flex-row gap-8 flex-grow sm:flex-grow-0">
                            <div className="flex flex-wrap sm:flex-col gap-8 sm:gap-4">
                                {[
                                    { field: smsCodeField, title: t('smsCode') },
                                    { field: parkingSpotField, title: t('parkingSpot') },
                                    { field: serialNumberField, title: t('serialNumber') },
                                    {
                                        field: clusterField,
                                        title: t('cluster'),
                                        suggestions: existingTableRows.map((x) => x.cluster),
                                    },
                                ].map((field, i) => (
                                    <Fragment key={i}>
                                        <ValidatedTextField
                                            title={field.title}
                                            field={field.field}
                                            className="w-full"
                                            classNameInner="py-4 sm:py-2 text-xl sm:text-base"
                                            suggestions={field.suggestions}
                                            forceInvalid={invalidFields.includes(field.field)}
                                        />
                                        {invalidFields.includes(field.field) &&
                                            invalidFieldReasons[
                                                invalidFields.indexOf(field.field)
                                            ] && (
                                                <div className="text-red-700 text-sm">
                                                    {
                                                        invalidFieldReasons[
                                                            invalidFields.indexOf(field.field)
                                                        ]
                                                    }
                                                </div>
                                            )}
                                    </Fragment>
                                ))}
                                <NetworkLocation
                                    setNetworkLocation={setNetworkLocation}
                                    networkLocation={networkLocation}
                                    chargePointSetupRows={existingTableRows}
                                    chargePointSetupRow={cps}
                                />
                            </div>
                            <div className="border-x-2 px-4 border-gray-200 rounded-md">
                                <PhaseMapper setPhaseMapping={setPhaseMapping} cps={cps} />
                            </div>
                            <div className="flex flex-col flex-grow gap-8 sm:gap-4">
                                <ValidatedTextField
                                    title={t('descriptionOptional')}
                                    field={descriptionField}
                                    className="w-full mb-4"
                                    classNameInner="h-full py-4 sm:py-2 text-xl sm:text-base w-full break-words"
                                    multiline
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                className="h-16 mt-12 mb-4"
                type="primary"
                click={() => {
                    if (cps?.reactKey) {
                        saveChargePoint(makeSetupRow())
                    } else {
                        saveChargePoint(makeSetupRow())
                    }
                    closeView()
                }}
                disabled={!allFieldsAreValid}
            >
                {cps?.reactKey ? t('updateChargePoint') : t('addChargePoint')}
            </Button>
        </div>
    )
}
