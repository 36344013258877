import { ChargePoint } from 'chargePoint/types/chargePoint'
import { Card, ConfirmModalButtonRow, IconButton, Loadable, Modal, SpinnerWithHeight } from 'common'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LaunchIcon } from 'img/launch.svg'
import style from './ChargePointDetails.module.scss'
import { useState } from 'react'
import { ReactComponent as RenameIcon } from 'img/rename.svg'
import { ReactComponent as RebootIcon } from 'img/restart.svg'
import RenameChargePointModal from '../RenameChargePointModal/RenameChargePointModal'
import useRebootChargePoint from 'chargePoint/hooks/useRebootChargePoint'

interface ChargePointDetailsProps {
    chargePoint: Loadable<ChargePoint>
    className?: string
}

function generateTunnelUrl(chargePoint: ChargePoint, useTLS: boolean): string | null {
    return chargePoint.ip
        ? `maksimer://deviceid=${chargePoint.controllerId}&ip=${chargePoint.ip}${
              useTLS ? '&port=443' : ''
          }`
        : null
}

export default function ChargePointDetails({
    chargePoint,
    className,
}: ChargePointDetailsProps): JSX.Element {
    const { t } = useTranslation()
    const [showRebootModal, setShowRebootModal] = useState(false)
    const [showRenameModal, setShowRenameModal] = useState(false)
    const [rebootChargePointState, executeRebootChargePoint, resetRebootChargePointState] =
        useRebootChargePoint()

    const closeRebootModal = () => {
        if (rebootChargePointState.status !== 'loading') {
            resetRebootChargePointState()
        }
        setShowRebootModal(false)
    }

    const getRebootModalContent = () => {
        if (chargePoint.status !== 'success') return null
        switch (rebootChargePointState.status) {
            case 'idle':
                return (
                    <>
                        <h2>{t('chargePointDetailsRebootTitle')}</h2>
                        <p>{t('chargePointDetailsRebootModalContent')}</p>
                        <ConfirmModalButtonRow
                            onConfirm={() =>
                                executeRebootChargePoint({
                                    chargePointId: chargePoint.data.id,
                                    deviceId: chargePoint.data.controllerId,
                                })
                            }
                            onCancel={closeRebootModal}
                        />
                    </>
                )
            case 'loading':
                return (
                    <>
                        <SpinnerWithHeight height={120} />
                    </>
                )
            case 'success':
                return (
                    <>
                        <p>{t('chargePointDetailsRebootSuccess')}</p>
                    </>
                )
            case 'error':
                return (
                    <>
                        <h2>{`Something went wrong when rebooting charge point`}</h2>
                        <p>{rebootChargePointState.error.toString()}</p>
                    </>
                )
        }
    }

    const getContent = () => {
        switch (chargePoint.status) {
            case 'loading':
                return <SpinnerWithHeight height={282} />
            case 'error':
                return `Failed to load chargePoint data ${chargePoint.error}`
            case 'success':
                return (
                    <>
                        <dl>
                            <dt>{t('chargePointDetailsDescriptionIsConnected')}</dt>
                            <dd>
                                {!!chargePoint.data.isConnected &&
                                    String(chargePoint.data.isConnected)}
                            </dd>
                            <dt>{t('chargePointDetailsDescriptionAccessControl')}</dt>
                            <dd>{chargePoint.data.accessControl}</dd>
                            <dt>{t('chargePointDetailsDescriptionClusterId')}</dt>
                            <dd>{chargePoint.data.clusterId}</dd>
                            <dt>{t('chargePointDetailsDescriptionIp')}</dt>
                            <dd>{chargePoint.data.ip}</dd>
                            <dt>{t('chargePointDetailsDescriptionSerialNumber')}</dt>
                            <dd>{chargePoint.data.serialNumber}</dd>
                            <dt>{t('chargePointDetailsDescriptionVendor')}</dt>
                            <dd>{chargePoint.data.vendor}</dd>
                            <dt>{t('chargePointDetailsDescriptionModel')}</dt>
                            <dd>{chargePoint.data.model}</dd>
                            <dt>{t('chargePointDetailsDescriptionFirmwareVersion')}</dt>
                            <dd>{chargePoint.data.firmwareVersion}</dd>
                            <dt>{t('chargePointDetailsDescriptionFirmwareStatus')}</dt>
                            <dd>{chargePoint.data.firmwareStatus}</dd>
                            <dt>{t('chargePointDetailsDescriptionDiagnosticStatus')}</dt>
                            <dd>{chargePoint.data.diagnosticStatus}</dd>
                        </dl>
                        <div className={style['tunnel-launch-container']}>
                            {[false, true].map((useTLS) => {
                                const tunnelUrl = generateTunnelUrl(chargePoint.data, useTLS)
                                return (
                                    <IconButton
                                        click={() => window.open(tunnelUrl!, '_blank')}
                                        type="primary"
                                        className={style['tunnel-launch-button']}
                                        disabled={!tunnelUrl}
                                        icon={LaunchIcon}
                                        text={
                                            t('chargePointDetailsDescriptionLaunchTunnel') +
                                            (useTLS ? ' (TLS)' : '')
                                        }
                                        key={useTLS ? 'tls' : 'non-tls'}
                                    />
                                )
                            })}
                        </div>
                        <div className="flex flex-row gap-4">
                            <IconButton
                                click={() => setShowRenameModal(true)}
                                icon={RenameIcon}
                                text={t('chargePointDetailsDescriptionRename')}
                                title={t('chargePointDetailsDescriptionRename')}
                                type="primary"
                            />
                            <IconButton
                                click={() => setShowRebootModal(true)}
                                icon={RebootIcon}
                                text={t('chargePointDetailsReboot')}
                                title={t('chargePointDetailsRebootTitle')}
                                type="primary"
                            />
                        </div>
                        <Modal
                            show={showRebootModal}
                            className={style.modal}
                            onClickOutside={closeRebootModal}
                        >
                            {getRebootModalContent()}
                        </Modal>
                        <RenameChargePointModal
                            show={showRenameModal}
                            close={() => setShowRenameModal(false)}
                            chargePointId={chargePoint.data.id}
                            controllerId={chargePoint.data.controllerId}
                        />
                    </>
                )
        }
    }

    return (
        <Card title={t('chargePointDetailsTitle')} className={className}>
            {getContent()}
        </Card>
    )
}
