import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useDeleteLocation from 'location/hooks/useDeleteLocation'
import { Location } from 'location/types/location'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './LocationConfig.module.scss'

interface DeleteLocationModalProps {
    show: boolean
    close: () => void
    location: Location
}

export default function DeleteLocationModal({ show, close, location }: DeleteLocationModalProps) {
    const { t } = useTranslation()
    const [deleteLocation, executeDeleteLocation, reset] = useDeleteLocation()
    const navigate = useNavigate()

    const getModalContent = () => {
        switch (deleteLocation.status) {
            case 'idle':
                return t('locationDeleteModal')
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete location: ${deleteLocation.error}`
            case 'success':
                return (
                    <>
                        <p>
                            {deleteLocation.data.deleteLocation
                                ? t('locationDeleteModalSuccess')
                                : `Something went wrong when deleting location. ${JSON.stringify(
                                      deleteLocation.data
                                  )}`}
                        </p>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteLocation.status === 'idle' || deleteLocation.status === 'error') {
            reset()
            close()
        }
        if (deleteLocation.status === 'success') {
            if (deleteLocation.data?.deleteLocation) {
                close()
                navigate('/locations')
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteLocation.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteLocation({ id: location.id! })}
            className={style.modal}
        >
            <h2>{t('locationDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
