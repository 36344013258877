import { useGraphQlMutation } from 'common'

const deleteLocationMutation = `
    mutation DeleteLocation($id: Int!) {
        deleteLocation(id: $id)
    }
`

interface DeleteLocationInput {
    id: number
}

interface DeleteLocationResponse {
    deleteLocation: boolean
}

export default function useDeleteLocation() {
    return useGraphQlMutation<DeleteLocationResponse, DeleteLocationInput>(deleteLocationMutation)
}
