import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

export default function useWindowWidth() {
    const [width, setWidth] = useState<number>(window.innerWidth)
    const rootRef = useRef<HTMLDivElement | null>(null)

    const onResize: EventListener = useCallback(() => {
        if (rootRef.current) {
            setWidth(rootRef.current.getBoundingClientRect().width)
        }
    }, [])

    useEffect(() => {
        rootRef.current = document.getElementById('root') as HTMLDivElement
    }, [])

    useLayoutEffect(() => {
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [onResize])

    return width
}
