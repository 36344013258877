import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import useRestartModule from 'controller/hooks/useRestartModule'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import style from './ControllerModules.module.scss'

interface RestartModalProps {
    show: boolean
    close: () => void
    controllerId: string
    targetModule: string
}

export default function RestartModal({
    show,
    close,
    controllerId,
    targetModule,
}: RestartModalProps) {
    const { t } = useTranslation()
    const [restartResult, ping, reset] = useRestartModule()

    useEffect(() => {
        reset()
        // We don't want reset in the dependency array, as it is not stable when requestState changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetModule, controllerId])

    const handleClose = () => {
        if (restartResult.status === 'success' || restartResult.status === 'error') {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (restartResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerModulesRestartConfirmModal', {
                            controllerId,
                            module: targetModule,
                        })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                ping({ deviceId: controllerId, module: targetModule })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to restart module: ${restartResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {restartResult.data.restartModule ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerModulesRestartSuccess')}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to restart module
                            </span>
                        )}{' '}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('controllerModulesRestartTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
