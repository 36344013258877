import {
    Breadcrumb,
    Card,
    ControllerLogsPageLink,
    ControllerPageLink,
    CurrentPage,
    IconButton,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import useControllerLogsDirect from 'controller/hooks/useControllerLogsDirect'
import { ReactComponent as ArrowExpand } from 'img/arrowExpand.svg'
import { ReactComponent as ArrowCollapse } from 'img/arrowCollapse.svg'
import { ReactComponent as NoteSearch } from 'img/noteSearch.svg'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import style from './ControllerLogsDirectPage.module.scss'

export default function ControllerLogsDirectPage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }
    const [limit, setLimit] = useState<number | ''>('')
    const [fullscreen, setFullscreen] = useState(false)
    const [searchQuery, setSearchQuery] = useState<string>('')

    const [req, fetch] = useControllerLogsDirect(
        controllerId,
        limit === '' ? 100 : limit,
        searchQuery
    )

    const { t } = useTranslation()

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentSearchQuery = e.currentTarget.value

        setSearchQuery(currentSearchQuery)
    }

    const handleLimitInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const asNumber = Number.parseInt(e.currentTarget.value)

        if (Number.isSafeInteger(asNumber)) {
            setLimit(asNumber)
        } else {
            setLimit('')
        }
    }

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return `Failed to load controller logs: ${req.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return (
                    <>
                        <IconButton
                            type="secondary"
                            className={style.expandCollapseButton}
                            click={() => setFullscreen(true)}
                            icon={ArrowExpand}
                            text={t('controllerLogsDirectExpandButton')}
                        />

                        {fullscreen ? (
                            <div className={style.fullscreenContainer}>
                                <div className={style.fullscreenContent}>
                                    <IconButton
                                        type="secondary"
                                        className={style.expandCollapseButton}
                                        icon={ArrowCollapse}
                                        click={() => setFullscreen(false)}
                                        text={t('controllerLogsDirectCollapseButton')}
                                    />
                                    <pre className={style.log}>{req.data}</pre>
                                </div>
                            </div>
                        ) : (
                            <pre className={style.log}>{req.data}</pre>
                        )}

                        {req.data.length === 0 && (
                            <p>{t('controllerLogsDirectIncreaseLimitHint')}</p>
                        )}
                    </>
                )
        }
    }

    return (
        <>
            <Breadcrumb className={style.breadcrumb}>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <ControllerLogsPageLink controllerId={controllerId} />
                <CurrentPage currentPage={t('controllerLogsDirectBreadcrumbTitle')} />
            </Breadcrumb>
            <Card title={t('controllerLogsDirectTitle')}>
                <div className={style.searchBar}>
                    <input
                        className={style.regexInput}
                        onChange={handleSearchInput}
                        placeholder={t('controllerLogsDirectRegexInputPlaceholder')}
                    />
                    <input
                        className={style.limitInput}
                        onChange={handleLimitInput}
                        placeholder={t('controllerLogsDirectLimitInputPlaceholder')}
                        value={limit}
                    />
                    <IconButton
                        icon={NoteSearch}
                        text={t('controllerLogsDirectSubmitText')}
                        click={() => fetch()}
                        className={style.downloadButton}
                        type="primary"
                    />
                </div>
                {getContent()}
            </Card>
        </>
    )
}
