import classNames from 'classnames'
import { IconButton } from 'common'
import NestableFrame from 'common/components/NestableFrame/NestableFrame'
import ValidatedSelectField from 'common/components/ValidatedFields/ValidatedSelectField'
import ValidatedTextField from 'common/components/ValidatedFields/ValidatedTextField'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useCreateCluster from 'config/hooks/useCreateCluster'
import useUpdateCluster from 'config/hooks/useUpdateCluster'
import { ClusterConfig, netTypeValues } from 'config/types/controllerConfig'
import generateReactKey from 'config/utils/generateReactKey'
import { numberValidator } from 'config/utils/validators'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import { ReactComponent as TrashCanIcon } from 'img/trashCan.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'
import style from './ClusterConfigItem.module.scss'
import DeleteClusterModal from './DeleteClusterModal'

interface ClusterProps {
    config: ClusterConfig
}

export default function ClusterConfigItem({ config }: ClusterProps) {
    const { t } = useTranslation()
    const isNew = !Number.isInteger(config.id)
    const [controllerConfig, setControllerConfig] = useControllerConfigState()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const dispatchPopup = usePopupModal()

    const clusterIdField = useValidatedField(config.clusterId, (value) => value.length > 0)
    const fuseLimitField = useValidatedField(config.fuseLimit.toString(), numberValidator)
    const headroomField = useValidatedField(config.headroom.toString(), numberValidator)
    const netTypeField = useValidatedField(config.netType, (_) => true)

    const [createClusterState, executeCreateCluster] = useCreateCluster()
    const [updateClusterState, executeUpdateCluster] = useUpdateCluster()

    const clusterFields = [clusterIdField, fuseLimitField, headroomField, netTypeField]

    const allValid = clusterFields.every((x) => x.isValid)
    const anyModified = clusterFields.some((x) => x.isModified) || isNew

    const isSaving =
        createClusterState.status === 'loading' || updateClusterState.status === 'loading'

    const saveEnabled = allValid && anyModified && !isSaving

    const deleteEnabled = !controllerConfig.clusters.some(
        (x) => x.parentId === config.id && Number.isInteger(x.id)
    )

    const resetFields = () => {
        clusterIdField.reset()
        fuseLimitField.reset()
        headroomField.reset()
        netTypeField.reset()
    }

    const handleSave = () => {
        const newCluster = {
            parentId: config.parentId,
            clusterId: clusterIdField.value,
            fuseLimit: Number(fuseLimitField.value),
            headroom: Number(headroomField.value),
            netType: netTypeField.value,
        }
        if (isNew) {
            executeCreateCluster({
                controllerId: config.controllerId,
                ...newCluster,
            })
                .then((res) => {
                    if (Number.isInteger(res.data?.createCluster.id)) {
                        resetFields()
                        setControllerConfig((prevConfig) => ({
                            ...prevConfig,
                            controller: {
                                ...prevConfig.controller,
                                isDirty: true,
                            },
                            clusters: prevConfig.clusters.map((c) => {
                                if (c === config) {
                                    return res.data!.createCluster
                                }
                                return c
                            }),
                        }))
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when creating cluster',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err: Error) =>
                    dispatchPopup({
                        title: 'Something went wrong when creating cluster',
                        message: err.message,
                    })
                )
        } else {
            executeUpdateCluster({
                id: config.id!,
                ...newCluster,
            })
                .then((res) => {
                    if (res.data?.updateCluster) {
                        resetFields()
                        setControllerConfig((prevConfig) => ({
                            ...prevConfig,
                            controller: {
                                ...prevConfig.controller,
                                isDirty: true,
                            },
                            clusters: prevConfig.clusters.map((c) => {
                                if (c === config) {
                                    return res.data!.updateCluster
                                }
                                return c
                            }),
                        }))
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when updating cluster',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err: Error) =>
                    dispatchPopup({
                        title: 'Something went wrong when updating cluster',
                        message: err.message,
                    })
                )
        }
    }

    const handleDelete = () => {
        if (isNew) {
            setControllerConfig((c) => ({
                ...c,
                clusters: c.clusters.filter((c) => c !== config),
            }))
        } else {
            if (
                controllerConfig.clusters.some(
                    (x) => x.parentId === config.id && Number.isInteger(x.id)
                )
            ) {
                dispatchPopup({
                    title: t('controllerConfigPageCannotDeleteTitle'),
                    message: t('controllerConfigPageClusterCannotDeleteWithChildren'),
                })
            } else if (controllerConfig.chargePoints.some((x) => x.clusterId === config.id)) {
                dispatchPopup({
                    title: t('controllerConfigPageCannotDeleteTitle'),
                    message: t('controllerConfigPageClusterCannotDeleteWithChargePoints'),
                })
            } else if (controllerConfig.sensors.some((x) => x.clusterId === config.id)) {
                dispatchPopup({
                    title: t('controllerConfigPageCannotDeleteTitle'),
                    message: t('controllerConfigPageClusterCannotDeleteWithSensors'),
                })
            } else {
                setShowDeleteModal(true)
            }
        }
    }

    const handleAddChild = () =>
        setControllerConfig((c) => ({
            ...c,
            clusters: [
                ...c.clusters,
                {
                    clusterId: '',
                    parentId: config.id!,
                    controllerId: config.controllerId,
                    fuseLimit: 0,
                    headroom: 0,
                    netType: netTypeField.value,
                    reactKey: generateReactKey(),
                },
            ],
        }))

    const isAddEnabled = Number.isInteger(config.id)

    return (
        <NestableFrame className={style.container}>
            <button
                disabled={!deleteEnabled}
                className={style.trashButton}
                title={t(
                    deleteEnabled
                        ? 'controllerConfigPageClusterDeleteButtonTitle'
                        : 'controllerConfigPageClusterDeleteButtonCantDeleteWithChildren'
                )}
                onClick={handleDelete}
            >
                <TrashCanIcon
                    className={classNames({
                        [style.trashIcon]: true,
                        [style.trashIconDisabled]: !deleteEnabled,
                    })}
                />
            </button>
            <ValidatedTextField
                title={t('controllerConfigPageClusterClusterId')}
                field={clusterIdField}
                forceModified={isNew}
                className={style.inputRow}
            />
            <div className={style.textFieldRow}>
                <ValidatedTextField
                    title={t('controllerConfigPageClusterFuseLimit')}
                    field={fuseLimitField}
                    forceModified={isNew}
                />
                <ValidatedTextField
                    title={t('controllerConfigPageClusterHeadroom')}
                    field={headroomField}
                    forceModified={isNew}
                />
            </div>
            <ValidatedSelectField
                title={t('controllerConfigPageClusterNetType')}
                field={netTypeField}
                options={netTypeValues.map((x) => ({ value: x }))}
                className={style.inputRow}
                forceModified={isNew}
            />
            <SaveButton
                text={t('controllerConfigPageClusterSave')}
                disabled={!saveEnabled}
                click={handleSave}
                loading={isSaving}
            />
            {controllerConfig.clusters
                .filter((x) => x.parentId === config.id)
                .map((childCluster) => (
                    <ClusterConfigItem
                        key={`cluster-${childCluster.reactKey || childCluster.id}`}
                        config={childCluster}
                    />
                ))}
            <IconButton
                text={
                    isAddEnabled
                        ? t('controllerConfigPageClusterAddChild')
                        : t('controllerConfigPageClusterAddDisabledHint')
                }
                type="secondary"
                icon={PlusIcon}
                className={style.inputRow}
                disabled={!isAddEnabled}
                click={handleAddChild}
            />
            <DeleteClusterModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                cluster={config}
            />
        </NestableFrame>
    )
}
