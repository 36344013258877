import { IconButton } from 'common'
import { ChargePointConfig } from 'config/types/controllerConfig'
import ChargePointConfigItem from '../ChargePointConfigItem/ChargePointConfigItem'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import style from './ChargePointConfigList.module.scss'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import { useTranslation } from 'react-i18next'
import generateReactKey from 'config/utils/generateReactKey'
import usePopupModal from 'common/hooks/usePopupModal'

interface ChargePointConfigListProps {
    chargePoints: ChargePointConfig[]
}

export default function ChargePointConfigList({ chargePoints }: ChargePointConfigListProps) {
    const { t } = useTranslation()
    const [controllerConfig, setControllerConfig] = useControllerConfigState()
    const dispatchPopup = usePopupModal()

    const onAddChargePoint = () => {
        const validClusters = controllerConfig.clusters.filter((x) => Number.isInteger(x.id))
        if (validClusters.length === 0) {
            dispatchPopup({ message: t('controllerConfigPageChargePointAddNoCluster') })
            return
        }
        const chargePoint: ChargePointConfig = {
            chargePointId: '',
            clusterId: validClusters[0].id!,
            accessControl: 'CONTROLLER_OPEN',
            connectors: [],
            reactKey: generateReactKey(),
        }

        setControllerConfig((prev) => ({
            ...prev,
            chargePoints: [...prev.chargePoints, chargePoint],
        }))
    }

    return (
        <div className={style.container}>
            {chargePoints.map((chargePoint) => (
                <ChargePointConfigItem
                    key={`chargePoint-${chargePoint.reactKey || chargePoint.id}`}
                    chargePoint={chargePoint}
                />
            ))}
            <IconButton
                text={t('controllerConfigPageChargePointAdd')}
                className={style.addChargePointButton}
                click={onAddChargePoint}
                icon={PlusIcon}
                type="secondary"
            />
        </div>
    )
}
