import { mapSuccess, useGraphQl } from 'common'
import { BusinessUnit } from 'config/types/controllerConfig'

const businessUnitClaimsQuery = `
    query GetBusinessUnits {
        businessUnits
    }
`

type BusinessUnitClaimsQueryData = {
    businessUnits: BusinessUnit[]
}

export default function useBusinessUnit() {
    const [state, refetch] = useGraphQl<BusinessUnitClaimsQueryData>(businessUnitClaimsQuery)
    return [mapSuccess(state, (data) => data.businessUnits), refetch] as const
}
