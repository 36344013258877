import { useGraphQlMutation } from 'common'
import { NetType } from 'config/types/controllerConfig'

const updateClusterQuery = `
    mutation UpdateCluster(
        $id: Int!
        $clusterId: String!
        $parentId: Int
        $fuseLimit: Float!
        $headroom: Float!
        $netType: NetType!
    ) {
        updateCluster(
            id: $id
            clusterId: $clusterId
            parentId: $parentId
            fuseLimit: $fuseLimit
            headroom: $headroom
            netType: $netType
        ) {
            id
            clusterId
            controllerId
            fuseLimit
            headroom
            netType
            parentId
        }
    }
`

interface UpdateClusterInput {
    id: number
    clusterId: string
    parentId: number | null
    fuseLimit: number
    headroom: number
    netType: NetType
}

interface UpdateClusterResponse {
    updateCluster: {
        id: number
        clusterId: string
        controllerId: number
        fuseLimit: number
        headroom: number
        netType: NetType
        parentId: number | null
    }
}

export default function useUpdateCluster() {
    return useGraphQlMutation<UpdateClusterResponse, UpdateClusterInput>(updateClusterQuery)
}
