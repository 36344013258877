import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useDeleteChargePoint from 'config/hooks/useDeleteChargePoint'
import { ChargePointConfig } from 'config/types/controllerConfig'
import { useTranslation } from 'react-i18next'
import style from './ChargePointConfigItem.module.scss'

interface DeleteChargePointModalProps {
    show: boolean
    close: () => void
    chargePoint: ChargePointConfig
}

export default function DeleteChargePointModal({
    show,
    close,
    chargePoint,
}: DeleteChargePointModalProps) {
    const { t } = useTranslation()
    const [deleteChargePointState, executeDeleteChargePoint, reset] = useDeleteChargePoint()
    const [_, setControllerConfigState] = useControllerConfigState()

    const getModalContent = () => {
        switch (deleteChargePointState.status) {
            case 'idle':
                return t('controllerConfigPageChargePointDeleteModal', {
                    chargePointId: chargePoint.chargePointId,
                })
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete charge point: ${deleteChargePointState.error}`
            case 'success':
                return (
                    <>
                        {deleteChargePointState.data.deleteChargePoint
                            ? t('controllerConfigPageChargePointDeleteModalSuccess', {
                                  chargePointId: chargePoint.chargePointId,
                              })
                            : 'Failed to delete charge point.'}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteChargePointState.status === 'idle' || deleteChargePointState.status === 'error') {
            reset()
            close()
        }
        if (deleteChargePointState.status === 'success') {
            if (deleteChargePointState.data?.deleteChargePoint) {
                setControllerConfigState((c) => ({
                    ...c,
                    controller: {
                        ...c.controller,
                        isDirty: true,
                    },
                    chargePoints: c.chargePoints.filter((x) => x.id !== chargePoint.id),
                }))
                close()
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteChargePointState.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteChargePoint({ id: chargePoint.id! })}
            className={style.modal}
        >
            <h2>{t('controllerConfigPageChargePointDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
