import { Card, Loadable, SpinnerWithHeight } from 'common'
import { Controller } from 'controller/types/controller'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ControllerActions from '../ControllerActions/ControllerActions'

interface ControllerDetailsProps {
    controller: Loadable<Controller>
}

export default function ControllerDetails({ controller }: ControllerDetailsProps): JSX.Element {
    const { t } = useTranslation()

    const getContent = () => {
        switch (controller.status) {
            case 'error':
                return `Failed to load controller: ${controller.error}`
            case 'loading':
                return <SpinnerWithHeight height={345} />
            case 'success':
                return (
                    <>
                        <dl>
                            <dt>{t('controllerDetailsDescriptionId')}</dt>
                            <dd>{controller.data.id}</dd>
                            <dt>{t('controllerDetailsDescriptionName')}</dt>
                            <dd>{controller.data.name}</dd>
                            <dt>{t('controllerDetailsDescriptionLastUpdate')}</dt>
                            <dd title={moment(controller.data.lastUpdate).toLocaleString()}>
                                {moment(controller.data.lastUpdate).fromNow()}
                            </dd>
                            <dt>{t('controllerDetailsDescriptionTotalChargePoints')}</dt>
                            <dd>{controller.data.totalChargePoints}</dd>
                            <dt>{t('controllerDetailsDescriptionConfiguredChargePoints')}</dt>
                            <dd>{controller.data.configuredChargePoints}</dd>
                            <dt>{t('controllerDetailsDescriptionConnectedChargePoints')}</dt>
                            <dd>{controller.data.connectedChargePoints}</dd>
                            <dt>{t('controllerDetailsDescriptionHardwareVersion')}</dt>
                            <dd>{controller.data.hardwareVersion}</dd>
                            <dt>{t('controllerDetailsDescriptionHardwareRevision')}</dt>
                            <dd>{controller.data.hardwareRevision}</dd>
                            <dt>{t('controllerDetailsDescriptionControllerImage')}</dt>
                            <dd>{controller.data.controllerImage}</dd>
                            <dt>{t('controllerDetailsDescriptionErpSystem')}</dt>
                            <dd>{controller.data.erpSystem}</dd>
                            <dt>{t('controllerDetailsDescriptionIsCommissioned')}</dt>
                            <dd>{String(!!controller.data.isCommissioned)}</dd>
                        </dl>
                        <ControllerActions
                            controllerId={controller.data.id}
                            showCommissionAction={!controller.data.isCommissioned}
                            showDecommissionAction={!!(controller.data.isCommissioned && !controller.data.isDecommissioned)}
                            existsInDatabase={Number.isInteger(controller.data.configId)}
                        />
                    </>
                )
        }
    }

    return <Card title={t('controllerDetailsTitle')}>{getContent()}</Card>
}
