import { Tooltip } from '@visx/xychart'
import { Datum } from 'common'
import moment from 'moment'
import { Fragment } from 'react'
import style from './LoadChartTooltip.module.scss'

interface LoadChartTooltipProps {
    dataKeys: string[]
    showVerticalCrosshair?: boolean
}

export default function LoadChartTooltip({
    dataKeys,
    showVerticalCrosshair,
}: LoadChartTooltipProps) {
    return (
        <Tooltip<Datum>
            showVerticalCrosshair={showVerticalCrosshair}
            showSeriesGlyphs
            renderTooltip={({ tooltipData, colorScale }) => {
                if (!tooltipData || !tooltipData.nearestDatum) {
                    return null
                }
                const hoverDatum = tooltipData.nearestDatum.datum

                return (
                    <div className={style.grid}>
                        <div className={style.timestamp}>{`${moment(hoverDatum.date).format(
                            'dddd, MMM DD, HH:mm'
                        )}`}</div>
                        {dataKeys.map((dataKey) => (
                            <Fragment key={dataKey}>
                                <span style={{ color: colorScale!(dataKey) }}>{dataKey}</span>
                                <span className={style.amperage}>{`${
                                    hoverDatum.lines[dataKey]?.y.toFixed(1) || '--'
                                } A`}</span>
                            </Fragment>
                        ))}
                    </div>
                )
            }}
        />
    )
}
