import { useGraphQlMutation } from 'common'

const decommissionControllerQuery = `
    mutation DecommissionController($deviceId: String!) {
        decommissionController(deviceId: $deviceId)
    }
`

interface DecommissionControllerInput {
    deviceId: string
}

interface DecommissionControllerResponse {
    decommissionController: boolean
}

export default function useCommissionController() {
    return useGraphQlMutation<DecommissionControllerResponse, DecommissionControllerInput>(
        decommissionControllerQuery
    )
}
