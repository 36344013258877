import { Button } from 'common'
import useControllerConfig from 'config/hooks/useControllerConfig'
import { InitiateChargePoints } from 'controller/components/InitializeChargePoints/InitializeChargePoints'
import ScanComponent from 'controller/components/ScanComponent/ScanComponent'
import useElectricianViewScan from 'electricianView/hooks/useElectricianViewScan'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import { normalizeSerialNumber } from 'electricianView/utils/normalizeSerialNumber'
import { IKaTableProps, ITableProps, Table, kaReducer } from 'ka-table'
import {
    deselectAllRows,
    deselectRow,
    selectAllRows,
    selectRow,
    updateData,
} from 'ka-table/actionCreators'
import { ICellProps, ICellTextProps, IHeadCellProps } from 'ka-table/props'
import { DispatchFunc } from 'ka-table/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import style from './ScanAndInit.module.scss'

const SelectionCell: React.FC<ICellTextProps> = ({
    rowKeyValue,
    dispatch,
    isSelectedRow,
    rowData,
}) => {
    return (
        <input
            type="checkbox"
            checked={isSelectedRow && rowData.hasMatch}
            onChange={(event: any) => {
                if (event.currentTarget.checked) {
                    dispatch(selectRow(rowKeyValue))
                } else {
                    dispatch(deselectRow(rowKeyValue))
                }
            }}
            disabled={!rowData.hasMatch}
        />
    )
}

const SelectionHeader = ({ areAllRowsSelected, dispatch }: IHeadCellProps) => {
    return (
        <input
            type="checkbox"
            checked={areAllRowsSelected}
            onChange={(_) => {
                if (areAllRowsSelected) {
                    dispatch(deselectAllRows())
                } else {
                    dispatch(selectAllRows())
                }
            }}
        />
    )
}

type ScanAndInitProps = {
    chargePointSetupRows: ChargePointSetupRow[]
    controllerId: string
    close: () => void
}

type ScanTableRow = {
    chargerName: string
    serialNumber: string
    hasMatch: boolean
    ip?: string
    vendor?: string
}

export function ScanAndInit({ chargePointSetupRows, controllerId, close }: ScanAndInitProps) {
    const { t } = useTranslation()

    const { accessToken } = useParams() as { accessToken?: string }
    const isManager = accessToken === undefined

    const initialTableProps: IKaTableProps = {
        columns: [
            { key: 'selection-cell', width: 2, visible: isManager },
            { key: 'chargerName', title: t('chargerName'), width: 5 },
            { key: 'serialNumber', title: t('serialNumber'), width: 6 },
            { key: 'ip', title: t('ip'), width: 5 },
            { key: 'vendor', title: t('vendor'), width: 4 },
        ],
        rowKeyField: 'serialNumber',
        data: [],
        selectedRows: [],

        childComponents: {
            cell: {
                content: (props: ICellProps) => {
                    if (props.column.key === 'selection-cell') {
                        return <SelectionCell {...props} />
                    }
                    return (
                        <div
                            className={
                                props.column.key === 'serialNumber'
                                    ? props.rowData.hasMatch
                                        ? 'text-sm text-emerald-600'
                                        : 'text-sm text-rose-600'
                                    : 'text-sm'
                            }
                        >
                            {props.rowData[props.column.key]}
                        </div>
                    )
                },
            },
            dataRow: {
                elementAttributes: (props) => {
                    if (props.isSelectedRow && props.rowData.hasMatch) {
                        return {
                            className: style['selected'],
                        }
                    }
                    if (props.rowData.hasMatch) {
                        return {
                            className: 'bg-emerald-50',
                        }
                    }
                    if (props.isSelectedRow && !props.rowData.hasMatch) {
                        return {
                            className: style['no-bg'],
                        }
                    }
                },
            },
            headCell: {
                content: (props: IHeadCellProps) => {
                    if (props.column.key === 'selection-cell') {
                        return <SelectionHeader {...props} />
                    }
                },
            },
        },
    }

    const [scanning, setScanning] = useState(false)
    const [tableProps, changeTableProps] = useState(initialTableProps)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    const scanFunc = useElectricianViewScan(controllerId, accessToken)

    const numMatching = tableProps.data?.reduce((acc, row) => acc + row.hasMatch, 0)
    const numTotal = tableProps.data?.length || 0

    const [controllerConfigLoadable, _] = useControllerConfig(+controllerId)
    const deviceId =
        controllerConfigLoadable.status === 'success'
            ? controllerConfigLoadable.data.controllerConfig.controller.deviceId
            : undefined

    return (
        <div className="flex flex-col p-4 bg-gray-200 gap-4">
            <div>
                <Button type="secondary" click={close}>
                    {t('back')}
                </Button>
            </div>
            <div className="grid grid-cols-5 gap-4">
                <div className={isManager ? 'col-span-3' : 'col-span-5'}>
                    <ScanComponent
                        onScan={(scanResponses) => {
                            // Sorry about this

                            scanResponses = scanResponses
                                .filter((r) => r.serialNumber)
                                .map((r) => ({
                                    ...r,
                                    serialNumber: normalizeSerialNumber(r.serialNumber!),
                                }))

                            const matchingRows = chargePointSetupRows.filter((row) =>
                                scanResponses.some((r) => r.serialNumber === row.serialNumber)
                            )

                            const onlyInScan = scanResponses.filter(
                                (r) =>
                                    !matchingRows.some((row) => row.serialNumber === r.serialNumber)
                            )

                            const onlyInSetup = chargePointSetupRows.filter(
                                (row) =>
                                    !matchingRows.some((r) => r.serialNumber === row.serialNumber)
                            )

                            const rows: ScanTableRow[] = []
                            for (const row of matchingRows) {
                                // Guaranteed to find a match
                                const scanResponse = scanResponses.find(
                                    (r) => r.serialNumber === row.serialNumber
                                )!

                                rows.push({
                                    chargerName: [row.smsCode, row.parkingSpot]
                                        .filter((x) => x)
                                        .join('-'),
                                    serialNumber: row.serialNumber,
                                    hasMatch: true,
                                    ip: scanResponse.ip,
                                    vendor: scanResponse.vendor,
                                })
                            }

                            for (const row of onlyInScan) {
                                rows.push({
                                    chargerName: '',
                                    // Guaranteed to have a serial number since we filtered out the ones without
                                    serialNumber: row.serialNumber!,
                                    hasMatch: false,
                                    ip: row.ip,
                                    vendor: row.vendor,
                                })
                            }

                            for (const row of onlyInSetup) {
                                rows.push({
                                    chargerName: [row.smsCode, row.parkingSpot]
                                        .filter((x) => x)
                                        .join('-'),
                                    serialNumber: row.serialNumber,
                                    hasMatch: false,
                                })
                            }

                            dispatch(updateData(rows))
                        }}
                        controllerId={controllerId}
                        hideTimeout
                        scanFunction={scanFunc}
                        onScanningChanged={(isScanning) => {
                            setScanning(isScanning)
                            dispatch(deselectAllRows())
                        }}
                    />
                </div>
                <div className="col-span-2">
                    {isManager && deviceId && (
                        <InitiateChargePoints
                            deviceId={deviceId}
                            disabled={scanning}
                            chargePointsToInitiate={(tableProps.data as ScanTableRow[])
                                .filter(
                                    (row) =>
                                        row.ip &&
                                        row.vendor &&
                                        row.hasMatch &&
                                        tableProps.selectedRows?.includes(row.serialNumber)
                                )
                                .map((row) => ({
                                    id: row.chargerName,
                                    ip: row.ip!,
                                    vendor: row.vendor!,
                                }))}
                        />
                    )}
                </div>
            </div>
            {numTotal > 0 && (
                <div className="bg-white px-4 py-2 text-sm">
                    Matches found in scan: {numMatching} / {numTotal}
                </div>
            )}
            <div className={style.table}>
                <Table {...tableProps} dispatch={dispatch} />
            </div>
        </div>
    )
}
