import { useGraphQlMutation } from 'common'

const restartModuleQuery = `
    mutation RestartModule($deviceId: String!, $module: String!) {
        restartModule(deviceId: $deviceId, module: $module)
    }
`

interface RestartModuleInput {
    deviceId: string
    module: string
}

interface RestartModuleResponse {
    restartModule?: boolean
}

export default function useRestartModule() {
    return useGraphQlMutation<RestartModuleResponse, RestartModuleInput>(restartModuleQuery)
}
