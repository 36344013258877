import classNames from 'classnames'
import { Button } from '../Button/Button'
import style from './IconButton.module.scss'

interface IconButtonProps {
    text?: string
    title?: string
    click: () => void
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    className?: string
    type: 'primary' | 'secondary'
    disabled?: boolean
    dense?: boolean
}

export default function IconButton({
    text,
    title,
    click,
    icon: Icon,
    className,
    type,
    disabled,
    dense,
}: IconButtonProps) {
    const buttonClasses = classNames(style.button, { [`${className}`]: className })
    const iconClasses = classNames(style.icon, { [style['icon-dense']]: dense })
    return (
        <Button type={type} className={buttonClasses} click={click} title={title} disabled={disabled} dense={dense}>
            <Icon className={iconClasses} />
            {text && <span>{text}</span>}
        </Button>
    )
}
