import { IconButton } from 'common'
import usePopupModal from 'common/hooks/usePopupModal'
import { ReactComponent as PowerOffIcon } from 'img/power.svg'
import { ReactComponent as PublishIcon } from 'img/publish.svg'
import { ReactComponent as RestartIcon } from 'img/restart.svg'
import { ReactComponent as TrashcanIcon } from 'img/trashCan.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommissionModal from './CommissionModal'
import DecommissionModal from './DecommissionModal'
import style from './ControllerActions.module.scss'
import PowerOffModal from './PowerOffModal'
import RebootModal from './RebootModal'

interface ControllerActionsProps {
    controllerId: string
    showCommissionAction: boolean,
    showDecommissionAction: boolean
    existsInDatabase: boolean
}

export default function ControllerActions({
    controllerId,
    showCommissionAction,
    showDecommissionAction,
    existsInDatabase,
}: ControllerActionsProps) {
    const { t } = useTranslation()

    const [showRebootModal, setShowRebootModal] = useState<boolean>(false)
    const [showShutdownModal, setShowShutdownModal] = useState<boolean>(false)
    const [showCommissionModal, setShowCommissionModal] = useState<boolean>(false)
    const [showDecommissionModal, setShowDecommissionModal] = useState<boolean>(false)

    const dispatchPopup = usePopupModal()

    const handleCommission = () => {
        if (existsInDatabase) {
            setShowCommissionModal(true)
        } else {
            dispatchPopup({
                title: t('controllerActionsCannotCommissionTitle'),
                message: t('controllerActionsCannotCommissionMessage'),
            })
        }
    }


    const handleDecommission = () => {
        if (existsInDatabase) {
            setShowDecommissionModal(true)
        } else {
            dispatchPopup({
                title: t('controllerActionsCannotDecommissionTitle'),
                message: t('controllerActionsCannotDecommissionMessage'),
            })
        }
    }

    return (
        <>
            <div className={style.buttonRow}>
                <IconButton
                    icon={RestartIcon}
                    click={() => setShowRebootModal(true)}
                    title={t('controllerActionsRebootTitle')}
                    text={t('controllerActionsReboot')}
                    type="primary"
                />
                <IconButton
                    icon={PowerOffIcon}
                    click={() => setShowShutdownModal(true)}
                    title={t('controllerActionsPowerOffTitle')}
                    text={t('controllerActionsPowerOff')}
                    type="primary"
                />
                {showCommissionAction && (
                    <IconButton
                        icon={PublishIcon}
                        click={handleCommission}
                        title={t('controllerActionsCommissionTitle')}
                        text={t('controllerActionsCommission')}
                        type="primary"
                    />
                )}
                {showDecommissionAction && (               
                    <IconButton
                        icon={TrashcanIcon}
                        click={handleDecommission}
                        title={t('controllerActionsDecommissionTitle')}
                        text={t('controllerActionsDecommission')}
                        type="primary"
                    />
                )}
            </div>
            <RebootModal
                show={showRebootModal}
                close={() => setShowRebootModal(false)}
                controllerId={controllerId}
            />
            <PowerOffModal
                show={showShutdownModal}
                close={() => setShowShutdownModal(false)}
                controllerId={controllerId}
            />
            <CommissionModal
                show={showCommissionModal}
                close={() => setShowCommissionModal(false)}
                controllerId={controllerId}
            />
             <DecommissionModal
                show={showDecommissionModal}
                close={() => setShowDecommissionModal(false)}
                controllerId={controllerId}
            />
        </>
    )
}
