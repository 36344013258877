import { useGraphQl } from 'common'
import { useMemo } from 'react'

const migrationStatusQuery = `
    query GetMigrationStaus($deviceId:String!){
        controllerMigrationDoneStatus(deviceId: $deviceId)
}`

interface MigrationStatusQueryData {
    controllerMigrationDoneStatus: boolean
}

export default function useGetMigrationStatus(deviceId: string) {
    const [state] = useGraphQl<MigrationStatusQueryData>(
        migrationStatusQuery,
        useMemo(() => ({ deviceId: deviceId }), [deviceId])
    )

    return [state] as const
}
