import { Loadable, mapSuccess, useGraphQl } from 'common'
import { useMemo } from 'react'

const dhcpLeasesQuery = `
    query DhcpLeasesQuery($id: String!) {
        controller(id: $id) {
            dhcpLeases {
                ipAddress
                macAddress
                clientId
                date
            }
            state {
                chargePoints {
                    id
                    ip
                }
            }
        }
    }
`

export interface DhcpLeasesQueryData {
    controller: {
        dhcpLeases: {
            ipAddress: string
            macAddress: string
            clientId: string
            date: string
        }[]
        state: { chargePoints: { id: string; ip: string }[] }
    }
}

interface DhcpLease {
    ipAddress: string
    macAddress: string
    clientId: string
    date: string
    chargePointId?: string
}

export default function useDhcpLeases(controllerId: string): Loadable<DhcpLease[]> {
    const [state] = useGraphQl<DhcpLeasesQueryData>(
        dhcpLeasesQuery,
        useMemo(() => ({ id: controllerId }), [controllerId])
    )

    const dataMapper = (data: DhcpLeasesQueryData) =>
        data.controller.dhcpLeases.map((lease) => ({
            ipAddress: lease.ipAddress,
            macAddress: lease.macAddress,
            clientId: lease.clientId,
            date: lease.date,
            chargePointId: data.controller.state.chargePoints.find(
                (chargePoint) => chargePoint.ip === lease.ipAddress
            )?.id,
        }))

    return useMemo(() => mapSuccess(state, dataMapper), [state])
}
