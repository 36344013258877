import { useGraphQlMutation } from 'common'

const createControllerConfigQuery = `
    mutation CreateControllerConfig(
        $locationId: Int!
        $deviceId: String!
        $name: String!
        $loadBalancingEnabled: Boolean!
        $sensorLoggerEnabled: Boolean!
        $sensorLoggerIntervalMs: Int!
        $isOcppProxyEnabled: Boolean!
    ) {
        createControllerConfig(
            locationId: $locationId
            deviceId: $deviceId
            name: $name
            loadBalancingEnabled: $loadBalancingEnabled
            sensorLoggerEnabled: $sensorLoggerEnabled
            sensorLoggerIntervalMs: $sensorLoggerIntervalMs
            isOcppProxyEnabled: $isOcppProxyEnabled
        ) {
            id
            locationId
            deviceId
            name
            loadBalancingEnabled
            sensorLoggerEnabled
            sensorLoggerIntervalMs
            isOcppProxyEnabled
        }
    }
`

interface CreateControllerConfigInput {
    locationId: number
    deviceId: string
    name: string
    loadBalancingEnabled: boolean
    sensorLoggerEnabled: boolean
    sensorLoggerIntervalMs: number,
    isOcppProxyEnabled: boolean
}

interface CreateControllerConfigResponse {
    createControllerConfig: {
        id: number
        locationId: number
        deviceId: string
        name: string
        loadBalancingEnabled: boolean
        sensorLoggerEnabled: boolean
        sensorLoggerIntervalMs: number,
        isOcppProxyEnabled: boolean
    }
}

export default function useCreateControllerConfig() {
    return useGraphQlMutation<CreateControllerConfigResponse, CreateControllerConfigInput>(
        createControllerConfigQuery
    )
}
