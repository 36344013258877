import { Breadcrumb, CurrentPage, IconLink, NavigationBar, OverviewPageLink } from 'common'
import { ControllerIssues } from 'controller'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import { ReactComponent as satelliteIcon } from 'img/satelliteUplink.svg'
import { ReactComponent as ChipIcon } from 'img/cogBox.svg'
import { useTranslation } from 'react-i18next'
import style from './IssuesPage.module.scss'

export default function IssuesPage(): JSX.Element {
    const { t } = useTranslation()
    return (
        <>
            <NavigationBar>
                <Breadcrumb>
                    <OverviewPageLink />
                    <CurrentPage currentPage={t('breadcrumbIssuesPageLink')} />
                </Breadcrumb>
                <IconLink
                    icon={LocationIcon}
                    to="/locations"
                    text={t('overviewPageLocationsLink')}
                />
                <IconLink
                    icon={satelliteIcon}
                    to="/provisioning"
                    text={t('provisioningPageLink')}
                />
<IconLink
                    icon={ChipIcon}
                    to="/devicesBatch"
                    text={t('insertBatchWithDevicesMenuTitle')}
                />
            </NavigationBar>
            <div className={style.page}>
                <ControllerIssues />
            </div>
        </>
    )
}
