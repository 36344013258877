import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import usePowerOffController from 'controller/hooks/usePowerOffController'
import { useTranslation } from 'react-i18next'

import style from './ControllerActions.module.scss'

interface PowerOffModalProps {
    show: boolean
    close: () => void
    controllerId: string
}

export default function PowerOffModal({ show, close, controllerId }: PowerOffModalProps) {
    const { t } = useTranslation()
    const [powerOffResult, powerOffController, reset] = usePowerOffController()

    const handleClose = () => {
        if (powerOffResult.status === 'success' || powerOffResult.status === 'error') {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (powerOffResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerActionsPowerOffConfirmModal', { controllerId })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                powerOffController({ deviceId: controllerId })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to power off controller: ${powerOffResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {powerOffResult.data.powerOffController ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerActionsPowerOffSuccess')}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to power off controller
                            </span>
                        )}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('controllerActionsPowerOffTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
