import moment from 'moment'

export default function tickDateFormat(dateInput: string | number) {
    const date = moment(dateInput)
    const minuteOfDay = date.hours() * 60 + date.minutes()
    if (minuteOfDay === 0) {
        return date.toDate().toDateString()
    }
    return date.seconds() === 0 ? date.format('HH:mm') : date.format('HH:mm:ss')
}

export function tickDateFormatShort(dateInput: string | number) {
    const date = moment(dateInput)
    const minuteOfDay = date.hours() * 60 + date.minutes()
    if (minuteOfDay === 0) {
        return date.format('MMM DD')
    }
    return date.seconds() === 0 ? date.format('HH:mm') : date.format('HH:mm:ss')
}
