import { Loadable } from 'common'
import { FirmwareManifest } from 'firmware/types/firmwaremanifest'
import { useTranslation } from 'react-i18next'

import { createRef, useCallback, useEffect, useState } from 'react'
import { LoadableSuccess } from 'common/types/loadable'
import { EditingMode, IKaTableProps, SortingMode, Table } from 'ka-table'
import { Tooltip } from 'react-tooltip'
import generateReactKey from 'config/utils/generateReactKey'

export interface FirmwareListProps {
    title?: string
    firmwareManifests: Loadable<FirmwareManifest[], Error>
    actions?: (fw: FirmwareManifest) => JSX.Element
    props?: any
}

function getTable(
    firmwareManifests: FirmwareManifest[],
    actions: (fw: FirmwareManifest) => JSX.Element
): JSX.Element {
    const tableProps: IKaTableProps = {
        columns: [
            { key: 'filename', title: 'Filename', style: { width: 256 } },
            { key: 'vendor', title: 'Vendor', style: { width: 196 } },
            { key: 'model', title: 'Model', style: { width: 156 } },
            { key: 'version', title: 'Version', style: { width: 96 } },
            { key: 'validUpgradeVersions', title: 'Valid upgrades', style: { width: 224 } },
            { key: 'actions', title: '' },
        ],
        data: firmwareManifests.map((fw) => ({
            ...fw,
            actions: actions(fw),
        })),
        rowKeyField: 'filename',
        sortingMode: SortingMode.Single,
        editingMode: EditingMode.None,
        childComponents: {
            cell: {
                content: (props) => {
                    if (props.column.key === 'actions') {
                        return props.rowData['actions']
                    }

                    const id = `tooltip-${generateReactKey()}`

                    let tooltipContent
                    switch (props.column.key) {
                        case 'validUpgradeVersions':
                            tooltipContent = (
                                <div className="flex flex-col">
                                    {props.rowData['validUpgradeVersions']
                                        .sort()
                                        .map((v: string) => (
                                            <div key={v}>{v}</div>
                                        ))}
                                </div>
                            )
                            break
                        default:
                            tooltipContent = props.rowData[props.column.key]
                    }

                    let cellContent
                    switch (props.column.key) {
                        case 'validUpgradeVersions':
                            cellContent = props.rowData['validUpgradeVersions'].sort().join(', ')
                            break
                        default:
                            cellContent = props.rowData[props.column.key]
                    }

                    return (
                        <>
                            <div
                                id={id}
                                className="text-ellipsis overflow-hidden whitespace-nowrap"
                            >
                                {cellContent}
                            </div>
                            <Tooltip anchorId={id} content={tooltipContent} />
                        </>
                    )
                },
            },
        },
    }

    return <Table {...tableProps} />
}

export default function FirmwareList({
    title,
    firmwareManifests,
    actions = () => <></>,
    props,
}: FirmwareListProps): JSX.Element {
    const { t } = useTranslation()

    const searchBoxRef = createRef<HTMLInputElement>()

    const [filteredFirmwareManifests, setFilteredFirmwareManifests] = useState<FirmwareManifest[]>(
        (firmwareManifests as LoadableSuccess<FirmwareManifest[]>)?.data ?? []
    )

    const filterBy = useCallback((text: string) => {
        text = text.toLowerCase()
        // safety: textChangedHandler is only called when firmwareManifests is a success, as the text field is disabled otherwise
        let fms = (firmwareManifests as LoadableSuccess<FirmwareManifest[]>).data
        if (text.length > 0) {
            fms = fms.filter((f) =>
                // If any property of a firmware manifest contains the input text, keep it
                Object.values(f).some((v) => v.toString().toLowerCase().includes(text))
            )
        }
        setFilteredFirmwareManifests(fms)
    }, [firmwareManifests, setFilteredFirmwareManifests])

    function textChangedHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const text = e.target.value
        filterBy(text)
    }

    useEffect(() => {
        if (firmwareManifests.status === 'success') {
            setFilteredFirmwareManifests(firmwareManifests.data)
            let text = ''
            if (searchBoxRef.current && searchBoxRef.current.value) {
                text = searchBoxRef.current.value
            }
            filterBy(text)
        }
    }, [firmwareManifests,filterBy, searchBoxRef])

    let content
    switch (firmwareManifests.status) {
        case 'loading':
            content = <div>Loading</div>
            break
        case 'success':
            content = getTable(filteredFirmwareManifests, actions)
            break
        case 'error':
            content = <div>{firmwareManifests.error.toString()}</div>
            break
    }

    return (
        <div>
            <div className="flex flex-row justify-between items-center" {...props}>
                <h2 className="text-lg text-slate-600">{title}</h2>
                <input
                    type="text"
                    ref={searchBoxRef}
                    placeholder={t('search')}
                    onChange={textChangedHandler}
                    disabled={firmwareManifests.status !== 'success'}
                    className="max-w-sm my-2"
                />
            </div>
            {content}
        </div>
    )
}
