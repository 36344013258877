import { useGraphQlMutation } from 'common'
import { CurrentSensorType, ModbusModel, SensorInterfaceType } from 'config/types/controllerConfig'

const createSensorQuery = `
    mutation CreateSensor(
        $clusterId: Int!
        $sensorInterface: SenorInterfaceType!
        $currentSensorType: CurrentSensorType
        $inputVoltage: Float
        $outputCurrent: Float
        $filter: String
        $model: ModbusModel
        $slaveId: Int
    ) {
        createSensor(
            clusterId: $clusterId
            sensorInterface: $sensorInterface
            currentSensorType: $currentSensorType
            inputVoltage: $inputVoltage
            outputCurrent: $outputCurrent
            filter: $filter
            model: $model
            slaveId: $slaveId
        ) {
            id
            clusterId
            sensorInterface
            currentSensorType
            inputVoltage
            outputCurrent
            filter
            model
            slaveId
        }
    }
`

interface CreateSensorInput {
    clusterId: number
    sensorInterface: SensorInterfaceType
    currentSensorType: CurrentSensorType | null
    inputVoltage: number | null
    outputCurrent: number | null
    filter: string | null
    model: ModbusModel | null
    slaveId: number | null
}

interface CreateSensorResponse {
    createSensor: {
        id: number
        clusterId: number
        sensorInterface: SensorInterfaceType | null
        currentSensorType: CurrentSensorType | null
        inputVoltage: number | null
        outputCurrent: number | null
        filter: string | null
        model: ModbusModel | null
        slaveId: number | null
    }
}

export default function useCreateSensor() {
    return useGraphQlMutation<CreateSensorResponse, CreateSensorInput>(createSensorQuery)
}
