import { mapSuccess, useGraphQl } from 'common'
import { Location } from 'location/types/location'
import { useMemo } from 'react'

const locationQuery = `
    query LocationQuery($id: Int!) {
        location(id: $id) {
            location {
                id
                name
            }
            controllers {
                id
                deviceId
                name
            }
        }
    }
`

type LocationQueryData = {
    location: {
        location: Location
        controllers: {
            id: number
            deviceId: string
            name: string
        }[]
    }
}

export default function useLocation(id: number) {
    const [state, refetch] = useGraphQl<LocationQueryData>(
        locationQuery,
        useMemo(() => ({ id: id }), [id])
    )
    return [
        mapSuccess(state, (data) => ({
            location: data.location.location,
            controllers: data.location.controllers,
        })),
        refetch,
    ] as const
}
