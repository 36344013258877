import { useGraphQlMutation } from 'common'

const commitControllerQuery = `
    mutation CommitController($id: Int!) {
        commitControllerConfig(id: $id)
    }
`

interface CommitControllerInput {
    id: number
}

interface CommitControllerResponse {
    commitControllerConfig: boolean
}

export default function useCommitController() {
    return useGraphQlMutation<CommitControllerResponse, CommitControllerInput>(
        commitControllerQuery
    )
}
