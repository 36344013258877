import { useGraphQlMutation } from 'common'

const pingModuleQuery = `
    mutation PingModule($deviceId: String!, $module: String!) {
        pingModule(deviceId: $deviceId, module: $module)
    }
`

interface PingModuleInput {
    deviceId: string
    module: string
}

interface PingModuleResponse {
    pingModule?: boolean
}

export default function usePingModule() {
    return useGraphQlMutation<PingModuleResponse, PingModuleInput>(pingModuleQuery)
}
