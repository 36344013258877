import { Button, Card, SpinnerWithHeight } from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './ProvisioningTable.module.scss'
import useProvisioningDevices from 'provisioning/hooks/useProvisioningDevices'
import usePopupModal from 'common/hooks/usePopupModal'
import { DeviceProvisioningModal } from 'provisioning'

interface AttributeArr {
    name: string;
    value: string;
}

interface Device {
    id: string;
    attributes: AttributeArr[];
    updated_ts: string;
}

interface DevicesResponse {
    menderDevicesByStatus: Device[];
}

export default function ProvisioningTable() {
    const [filter, setFilter] = useState<string>('')
    const [controlerSerialNo, setControlerSerialNo] = useState<string>('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<DevicesResponse>()
    const { t } = useTranslation()
    const dispatchPopup = usePopupModal()
    const fetchDevices = useProvisioningDevices()[1]

    useEffect(() => {
        fetchDevices({status: "pending"}).then((res) => {
            setIsLoading(false)
            if(res.data){
                setData(res.data as unknown as DevicesResponse)
            }
        })
        .catch(() => {
            setIsLoading(false)
            dispatchPopup(t('failedToRequestDevices'))
        })
    },[fetchDevices, dispatchPopup, t, isModalOpen])

    
    const deviceFilter = (device: {
        attributes: {
            [key: string]: string;
        };
        id: string;
        updated_ts: string;
    }) => {
        return (
            device.attributes.status.toLowerCase().includes(filter) ||
            device.attributes.serialno.toLowerCase().includes(filter)
        )
    }

    const getContent = () => {
        if(isLoading || !data) return <SpinnerWithHeight height={120} />

        // The attributes array contains the data which need to be displayed.
        // This remap is necessary in order for the filter function to work
        const devices = data.menderDevicesByStatus.map((device) => {
            const attrObj: { [key: string]: string } = {};
            device.attributes.map((attribute) => {
                attrObj[attribute.name] = attribute.value;
                return null;
            })
            return { ...device, attributes: attrObj };
        })
        
        return (
            <>
                <input
                   placeholder={t('searchInputPlaceholder')}
                   className={style.searchInput}
                   onChange={(e) => setFilter(e.target.value.toLowerCase())}
                />
                <table>
                    <thead>
                        <tr>
                            <th>{t('serialNumber')}</th>
                            <th>{t('deviceType')}</th>
                            <th>{t('status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices
                            .filter(deviceFilter)
                            .map((device) => {
                                return (
                                    <tr
                                        key={device.id}
                                    >
                                        <td>{device.attributes.serialno}</td>
                                        <td>{device.attributes.device_type}</td>
                                        <td>{device.attributes.status}</td>
                                        <td>
                                            <Button
                                                type="primary"
                                                click={(e) => {
                                                    e.preventDefault()
                                                    setIsModalOpen(true);
                                                    setControlerSerialNo(device.attributes.serialno)
                                                }}
                                            >
                                                {t('adopt')}
                                            </Button></td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <Card>
            {getContent()}
            {isModalOpen && <DeviceProvisioningModal
                show={isModalOpen}
                close={() => {setControlerSerialNo(''); setIsModalOpen(false); }}
                serialno={controlerSerialNo}
            />}
        </Card>
    )
}
