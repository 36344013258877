import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useDeleteConnector from 'config/hooks/useDeleteConnector'
import { ChargePointConfig, ConnectorConfig } from 'config/types/controllerConfig'
import swapListItem from 'config/utils/swapListElement'
import { useTranslation } from 'react-i18next'
import style from './ConnectorConfigList.module.scss'

interface DeleteConnectorModalProps {
    show: boolean
    close: () => void
    connector: ConnectorConfig
    chargePoint: ChargePointConfig
}

export default function DeleteConnectorModal({
    show,
    close,
    connector,
    chargePoint,
}: DeleteConnectorModalProps) {
    const { t } = useTranslation()
    const [deleteConnectorState, executeDeleteConnector, reset] = useDeleteConnector()
    const [_, setControllerConfigState] = useControllerConfigState()

    const getModalContent = () => {
        switch (deleteConnectorState.status) {
            case 'idle':
                return t('controllerConfigPageConnectorDeleteModal', {
                    connectorId: connector.connectorId,
                    chargePointId: chargePoint.chargePointId,
                })
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete charge point: ${deleteConnectorState.error}`
            case 'success':
                return (
                    <>
                        <p>
                            {deleteConnectorState.data.deleteConnector
                                ? t('controllerConfigPageConnectorDeleteModalSuccess', {
                                      connectorId: connector.connectorId,
                                  })
                                : 'Failed to delete charge point.'}
                        </p>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteConnectorState.status === 'idle' || deleteConnectorState.status === 'error') {
            reset()
            close()
        }
        if (deleteConnectorState.status === 'success') {
            if (deleteConnectorState.data?.deleteConnector) {
                const newConnectors = chargePoint.connectors.filter((x) => x.id !== connector.id)
                const newChargePoint = { ...chargePoint, connectors: newConnectors }
                setControllerConfigState((c) => ({
                    ...c,
                    controller: {
                        ...c.controller,
                        isDirty: true,
                    },
                    chargePoints: swapListItem(c.chargePoints, chargePoint, newChargePoint),
                }))
                close()
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteConnectorState.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteConnector({ id: connector.id! })}
            className={style.modal}
        >
            <h2>{t('controllerConfigPageConnectorDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
