import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useDeleteSensor from 'config/hooks/useDeleteSensor'
import { SensorConfig } from 'config/types/controllerConfig'
import { useTranslation } from 'react-i18next'
import style from './SensorConfigList.module.scss'

interface DeleteSensorModalProps {
    show: boolean
    close: () => void
    sensor: SensorConfig
}

export default function DeleteSensorModal({ show, close, sensor }: DeleteSensorModalProps) {
    const { t } = useTranslation()
    const [deleteSensorState, executeDeleteSensor, reset] = useDeleteSensor()
    const [_, setControllerConfigState] = useControllerConfigState()

    const getModalContent = () => {
        switch (deleteSensorState.status) {
            case 'idle':
                return t('controllerConfigPageSensorDeleteModal')
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete sensor: ${deleteSensorState.error}`
            case 'success':
                return (
                    <>
                        <p>
                            {deleteSensorState.data.deleteSensor
                                ? t('controllerConfigPageSensorDeleteModalSuccess')
                                : `Something went wrong when deleting sensor. ${JSON.stringify(
                                      deleteSensorState.data
                                  )}`}
                        </p>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteSensorState.status === 'idle' || deleteSensorState.status === 'error') {
            reset()
            close()
        }
        if (deleteSensorState.status === 'success') {
            if (deleteSensorState.data?.deleteSensor) {
                setControllerConfigState((prev) => ({
                    ...prev,
                    controller: {
                        ...prev.controller,
                        isDirty: true,
                    },
                    sensors: prev.sensors.filter((x) => x !== sensor),
                }))
                close()
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteSensorState.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteSensor({ id: sensor.id! })}
            className={style.modal}
        >
            <h2>{t('controllerConfigPageSensorDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
