import {
    Breadcrumb,
    Card,
    CurrentPage,
    IconButton,
    IconLink,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import useLocationList from 'location/hooks/useLocationList'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './LocationPage.module.scss'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import { ReactComponent as ChipIcon } from 'img/chip.svg'
import CreateLocationModal from 'location/components/CreateLocationModal'
import useBusinessUnitClaims from 'location/hooks/useBusinessUnitClaims'
import { ReactComponent as WrenchIcon } from 'img/wrench-cog.svg'
import { ReactComponent as satelliteIcon } from 'img/satelliteUplink.svg'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'

export default function LocationListPage() {
    const { t } = useTranslation()
    const [filter, setFilter] = useState<string>('')
    const [showCreateLocationModal, setShowCreateLocationModal] = useState(false)

    const [locations, refreshLocations] = useLocationList()
    const [businessUnits] = useBusinessUnitClaims()

    const req = useControllerIssues()

    const isRequestSuccess = req.status === "success";

    const issuesLinkText = isRequestSuccess && req.data.length > 0 ? `${t('goToIssuesPageButtonTitle')}${isRequestSuccess ? ` (${req.data.length})` : null}` : t('goToIssuesPageButtonTitle');

    const handleAddLocation = () => {
        setShowCreateLocationModal(true)
    }

    const addLocationEnabled = businessUnits.status === 'success' && businessUnits.data.length > 0

    const getContent = () => {
        switch (locations.status) {
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'error':
                return <div>{`Failed to load location list ${locations.error}`}</div>
            case 'success':
                return (
                    <div className={style.container}>
                        <div className={style.searchRow}>
                            <input
                                type="text"
                                onChange={(e) => setFilter(e.target.value.toLocaleLowerCase())}
                                placeholder={t('searchInputPlaceholder')}
                                className={style.searchInput}
                            />
                            {addLocationEnabled && (
                                <IconButton
                                    type="secondary"
                                    icon={PlusIcon}
                                    click={handleAddLocation}
                                    text={t('locationListPageAddLocation')}
                                    className={style.addLocationButton}
                                />
                            )}
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('locationListPageColumnName')}</th>
                                    <th>{t('controllerBusinessUnit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {locations.data
                                    .filter((location) =>
                                        location.name.toLocaleLowerCase().includes(filter)
                                    )
                                    .map((location) => (
                                        <tr key={location.id}>
                                            <td>
                                                <Link to={`/location/${location.id}`}>
                                                    {location.name}
                                                </Link>
                                            </td>
                                            <td>
                                                    {location.businessUnit}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {addLocationEnabled && (
                            <CreateLocationModal
                                show={showCreateLocationModal}
                                close={() => setShowCreateLocationModal(false)}
                                refreshLocations={refreshLocations}
                                allowedBusinessUnits={businessUnits.data}
                                disallowedNames={locations.data.map((location) => location.name)}
                            />
                        )}
                    </div>
                )
        }
    }

    return (
        <>
            <NavigationBar>
            <Breadcrumb>
                <OverviewPageLink />
                <CurrentPage currentPage={t('breadcrumbLocationListPageLink')} />
            </Breadcrumb>
                <IconLink
                    icon={WrenchIcon}
                    to="/issues"
                    text={issuesLinkText}
                />
                <IconLink
                    icon={ChipIcon}
                    to="/devicesBatch"
                    text={t('insertBatchWithDevicesMenuTitle')}
                />
                <IconLink
                    icon={satelliteIcon}
                    to="/provisioning"
                    text={t('provisioningPageLink')}
                />
                
            </NavigationBar>
            <Card>{getContent()}</Card>
        </>
    )
}
