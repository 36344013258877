import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import useCommissionController from 'controller/hooks/useCommissionController'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import style from './ControllerActions.module.scss'

interface CommissionModalProps {
    show: boolean
    close: () => void
    controllerId: string
}

export default function CommissionModal({ show, close, controllerId }: CommissionModalProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [commissionResult, commissionController, reset] = useCommissionController()

    useEffect(() => {
        if (
            !show &&
            (commissionResult.status === 'success' || commissionResult.status === 'error')
        ) {
            reset()
        }
    }, [commissionResult.status, reset, show])

    const getModalContent = () => {
        switch (commissionResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerActionsCommissionConfirmModal', { controllerId })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                commissionController({ deviceId: controllerId })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to commission controller: ${commissionResult.error}`}</span>
                        <Button className={style.modalCloseButton} type="primary" click={close}>
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                const closeAction = commissionResult.data.commissionController
                    ? () => navigate(0)
                    : close

                return (
                    <>
                        {commissionResult.data.commissionController ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerActionsCommissionSuccess', { controllerId })}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to commission controller
                            </span>
                        )}{' '}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={closeAction}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={close} className={style.modal}>
            <h2>{t('controllerActionsCommissionTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
