import { useGraphQlMutation } from 'common'

const deleteSensorQuery = `
    mutation DeleteSensor($id: Int!) {
        deleteSensor(id: $id)
    }
`

interface DeleteSensorInput {
    id: number
}

interface DeleteSensorResponse {
    deleteSensor: boolean
}

export default function useDeleteSensor() {
    return useGraphQlMutation<DeleteSensorResponse, DeleteSensorInput>(deleteSensorQuery)
}
