import { useGraphQlMutation } from 'common'

const configureLoggingFilterQuery = `
    mutation ConfigureLoggingFilter($deviceId: String!, $logLevel: String!) {
        configureLoggingFilter(deviceId: $deviceId, logLevel: $logLevel)
    }
`

interface ConfigureLoggingInput {
    deviceId: string
    logLevel: string
}

interface ConfigureLoggingResponse {
    configureLoggingFilter?: boolean
}

export default function useConfigureLoggingLevel() {
    return useGraphQlMutation<ConfigureLoggingResponse, ConfigureLoggingInput>(
        configureLoggingFilterQuery
    )
}
