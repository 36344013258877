import { IconButton } from 'common'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import { SensorConfig } from 'config/types/controllerConfig'
import generateReactKey from 'config/utils/generateReactKey'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import style from './SensorConfigList.module.scss'
import SensorConfigItem from './SensorConfigItem'

interface SensorConfigListProps {
    sensors: SensorConfig[]
}

export default function SensorConfigList({ sensors }: SensorConfigListProps) {
    const { t } = useTranslation()
    const [controllerConfig, setControllerConfig] = useControllerConfigState()

    const validClusters = controllerConfig.clusters.filter((x) => Number.isInteger(x.id))

    const handleAddSensor = () => {
        setControllerConfig((prev) => ({
            ...prev,
            sensors: [
                ...prev.sensors,
                {
                    clusterId: validClusters[0].id!,
                    sensorInterface: null,
                    inputVoltage: null,
                    outputCurrent: null,
                    filter: null,
                    currentSensorType: null,
                    model: null,
                    slaveId: null,
                    reactKey: generateReactKey(),
                },
            ],
        }))
    }

    return (
        <div className={style.listContainer}>
            {sensors.map((sensor) => (
                <SensorConfigItem key={`sensor-${sensor.reactKey || sensor.id}`} sensor={sensor} />
            ))}
            <IconButton
                text={
                    validClusters.length === 0
                        ? t('controllerConfigPageSensorAddDisabled')
                        : t('controllerConfigPageSensorAdd')
                }
                click={handleAddSensor}
                icon={PlusIcon}
                type="secondary"
                disabled={validClusters.length === 0}
                className={style.sensorAddButton}
            />
        </div>
    )
}
