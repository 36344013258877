import { Card, useConnectorLoadFetchFunction } from 'common'
import { Connector, ConnectorLoadResponse } from 'common/hooks/useConnectorLoadFetchFunction'
import { DataRecord, RawChartDataMapper } from 'common/types/chartTypes'
import { ZoomableLoadChart } from 'controller'
import { useTranslation } from 'react-i18next'

interface ChargePointLoadProps {
    controllerId: string
    chargePointId: string
    className?: string
}

export default function ChargePointLoad({
    controllerId,
    chargePointId,
    className,
}: ChargePointLoadProps) {
    const fetchFn = useConnectorLoadFetchFunction(controllerId)

    const { t } = useTranslation()

    return (
        <Card title={t('chargePointLoadTitle')} className={className}>
            <ZoomableLoadChart
                fetchFn={fetchFn}
                dataMapper={getDataMapperForChargePoint(chargePointId)}
            />
        </Card>
    )
}

function getDataMapperForChargePoint(
    chargePointId: string
): RawChartDataMapper<ConnectorLoadResponse> {
    const getPhaseKey = (connector: Connector, phase: number) => `${connector.connectorId}@${phase}`
    const getLimitKey = (connector: Connector) => `lim:${connector.connectorId}`

    const getConnectorKeys = (connector: Connector) => {
        const phaseKeys = [1, 2, 3].map((phase) => getPhaseKey(connector, phase))
        return [...phaseKeys, getLimitKey(connector)]
    }

    const getConnectorRecords = (connector: Connector) => {
        return connector.data.flatMap((dataPoint) => [
            [dataPoint[0], getPhaseKey(connector, 1), dataPoint[2]],
            [dataPoint[0], getPhaseKey(connector, 2), dataPoint[3]],
            [dataPoint[0], getPhaseKey(connector, 3), dataPoint[4]],
            [dataPoint[0], getLimitKey(connector), dataPoint[5]],
        ]) as DataRecord[]
    }

    return (data) => {
        const connectors = data.connectors.filter((conn) =>
            conn.connectorId.startsWith(chargePointId)
        )
        return {
            dataKeys: connectors.flatMap(getConnectorKeys),
            records: connectors.flatMap(getConnectorRecords),
        }
    }
}
