import { AccessControl, ChargePoint } from 'chargePoint'
import { ChargePriority } from 'chargePoint/types/chargePoint'
import { Loadable, mapSuccess, mergeChargePointData, useGraphQl } from 'common'
import { Cluster, Controller, NetworkAdapter } from 'controller'
import { useMemo } from 'react'

const controllerPageQuery = `
    query ControllerPageQuery($id: String!) {
        controller(id: $id) {
            deviceId
            name
            hwRevision
            hwVersion
            erp
            image
            lastUpdate
            isCommissioned
            clusters {
                id
                parent
                netType
                fuseLimit
                headroom
            }
            chargePoints(merge: true) {
                id
                accessControl
                clusterId: cluster
                connectors {
                    id
                    minAmpere
                    maxAmpere
                    phaseMapping
                    priority
                }
            }
            state {
                chargePoints {
                    id
                    ip
                    isConnected
                    vendor
                    fwVer
                    serial
                    model
                    fwStatus
                    diagStatus
                    connectors {
                        id
                        loadLimit
                        state
                        errorCode
                    }
                }
                adapters {
                    name
                    ip
                    isUp
                }
            }
            controllerConfig {
                id
                isCommissioned,
                isDecommissioned,
                isOcppProxyEnabled,
                enableRouting,
                locationId
            }
        }
    }
`

export interface ControllerPageQueryData {
    controller: {
        deviceId: string
        name: string
        hwRevision: string
        hwVersion: string
        erp: string
        image: string
        lastUpdate: string
        clusters: {
            id: string
            parent: string
            netType: string
            fuseLimit: number
            headroom: number
        }[]
        chargePoints: {
            id: string
            accessControl: AccessControl
            clusterId: string
            connectors: {
                id: number
                minAmpere: number
                maxAmpere: number
                phaseMapping: string
                priority: ChargePriority
            }[]
        }[]
        state: {
            chargePoints: {
                id: string
                ip: string
                isConnected: boolean
                vendor: string
                fwVer: string
                serial: string
                model: string
                fwStatus: string
                diagStatus: string
                connectors: {
                    id: number
                    loadLimit: number
                    state: string
                    errorCode: string
                }[]
            }[]
            adapters: {
                name: string
                ip: string
                isUp: boolean
            }[]
        }
        controllerConfig?: {
            id: number
            isCommissioned: boolean,
            isDecommissioned: boolean,
            isOcppProxyEnabled: boolean,
            enableRouting: boolean,
            locationId: number
        }
    }
}

export interface ControllerPageData {
    controller: Controller
    clusters: Cluster[]
    chargePoints: ChargePoint[]
    networkAdapters: NetworkAdapter[]
}

export default function useControllerData(controllerId: string): Loadable<ControllerPageData> {
    const [state] = useGraphQl<ControllerPageQueryData>(
        controllerPageQuery,
        useMemo(() => ({ id: controllerId }), [controllerId])
    )

    const dataMapper = (data: ControllerPageQueryData) => {
        const chargePoints = mergeChargePointData(
            data.controller.chargePoints,
            data.controller.state.chargePoints,
            controllerId
        )

        return {
            controller: {
                id: data.controller.deviceId,
                name: data.controller.name,
                hardwareVersion: data.controller.hwVersion,
                hardwareRevision: data.controller.hwRevision,
                controllerImage: data.controller.image,
                erpSystem: data.controller.erp,
                lastUpdate: data.controller.lastUpdate,
                isCommissioned: data.controller.controllerConfig?.isCommissioned,
                isDecommissioned: data.controller.controllerConfig?.isDecommissioned,
                isOcppProxyEnabled: data.controller.controllerConfig?.isOcppProxyEnabled,
                enableRouting: data.controller.controllerConfig?.enableRouting,
                configId: data.controller.controllerConfig?.id,
                locationId: data.controller.controllerConfig?.locationId,
                totalChargePoints: chargePoints.length,
                configuredChargePoints: data.controller.chargePoints.length,
                connectedChargePoints: chargePoints.filter((chargePoint) => chargePoint.isConnected)
                    .length,
            },
            clusters: data.controller.clusters.map((cluster) => ({
                id: cluster.id,
                parentId: cluster.parent,
                netType: cluster.netType,
                fuseLimit: cluster.fuseLimit,
                headroom: cluster.headroom,
            })),
            chargePoints: chargePoints,
            networkAdapters: data.controller.state.adapters,
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => mapSuccess(state, dataMapper), [state])
}
