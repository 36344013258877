interface ChartSelectionProps {
    selectionStart: number
    selectionEnd: number
    chartWidth: number
    chartHeight: number
    numDatapoints: number
}

export default function ChartSelection({
    selectionStart,
    selectionEnd,
    chartWidth,
    chartHeight,
    numDatapoints,
}: ChartSelectionProps) {
    return (
        <rect
            x={
                ((Math.min(selectionStart, selectionEnd) + 0.5) / numDatapoints) *
                    (chartWidth - 100) +
                50
            }
            width={(Math.abs(selectionStart - selectionEnd) / numDatapoints) * (chartWidth - 100)}
            opacity={0.1}
            y={50}
            height={chartHeight - 100}
            fill={'black'}
        />
    )
}
