import { Button, Spinner } from 'common'
import usePopupModal from 'common/hooks/usePopupModal'
import { ChargePointVendorType, ScanInput, ScanResponse } from 'controller/hooks/useScan'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const VENDORS: [ChargePointVendorType, string][] = [
    ['SCHNEIDER', 'Schneider'],
    // ['SCHNEIDER_AC_PRO', 'Schneider AC Pro'],
    ['VESTEL', 'Vestel'],
    // ['KEBA', 'Keba'],
    // ['IO_CHARGER', 'IO Charger'],
]

interface ScanComponentProps {
    controllerId: string
    onScan: (scanResponses: ScanResponse[]) => void
    hideTimeout?: boolean
    scanFunction: (input: ScanInput) => Promise<ScanResponse[] | null>
    onScanningChanged: (scanning: boolean) => void
}

export default function ScanComponent({
    controllerId,
    hideTimeout,
    onScan,
    scanFunction,
    onScanningChanged,
}: ScanComponentProps) {
    const { t } = useTranslation()

    const dispatchPopup = usePopupModal()

    const [scanning, setScanning] = useState(null as ChargePointVendorType | null)
    const [scanned, setScanned] = useState({} as Record<ChargePointVendorType, boolean>)
    const [timeoutMs, setTimeoutMs] = useState(60000)

    const [scanResponses, setScanResponses] = useState([] as ScanResponse[])

    function scanForVendor(vendor: ChargePointVendorType): Promise<ScanResponse[] | null> {
        setScanning(vendor)
        setScanned({ ...scanned, [vendor]: false })
        onScanningChanged(true)

        return scanFunction({ deviceId: controllerId, vendor, timeoutMs })
            .then((res) => {
                setScanning(null)
                onScanningChanged(false)
                setScanned({ ...scanned, [vendor]: true })
                return res as ScanResponse[]
            })
            .catch((err) => {
                setScanning(null)
                onScanningChanged(false)
                console.error(err)
                dispatchPopup({
                    type: 'error',
                    title: 'Error',
                    message: err.toString(),
                })
                return null
            })
    }

    function mergeScanResponses(newScanResponses: ScanResponse[]) {
        const existingRows = scanResponses
        const tmp = existingRows.concat(
            newScanResponses.filter(
                (row) => !existingRows.find((existingRow) => existingRow.ip === row.ip)
            )
        )
        setScanResponses(tmp)
        onScan(tmp)
    }

    return (
        <div className="flex flex-col align-middle justify-center text-center bg-white border border-gray-200">
            <div className="flex justify-center items-center h-16 border-b border-gray-50">
                <div className="font-semibold text-sm text-gray-500">{t('scan')}</div>
            </div>
            <div className="flex flex-col gap-2 px-1 my-2">
                {!hideTimeout && (
                    <>
                        <label htmlFor="timeout-selector" className="text-xs text-gray-500">
                            {t('scanTimeout')}
                        </label>
                        <select
                            id="timeout-selector"
                            onChange={(e) => setTimeoutMs(parseInt(e.target.value))}
                            className="mb-4 mx-2 text-sm"
                            defaultValue={timeoutMs}
                            disabled={scanning !== null}
                        >
                            <option value="10000">10s</option>
                            <option value="30000">30s</option>
                            <option value="60000">1min</option>
                            <option value="90000">1min 30s</option>
                            <option value="120000">2min</option>
                        </select>
                    </>
                )}

                <div id="scan-targets" className="flex flex-col p-2 gap-2">
                    <label htmlFor="scan-targets" className="text-xs text-gray-500 mb-2">
                        {t('scanTargets')}
                    </label>
                    {VENDORS.map(([vendor, vendorDisplayName]) => (
                        <Button
                            click={() =>
                                scanForVendor(vendor).then(
                                    (data) => data && mergeScanResponses(data)
                                )
                            }
                            disabled={scanning !== null}
                            type="secondary"
                            dense
                            key={vendor}
                        >
                            <div className="flex flex-row">
                                <div>{vendorDisplayName}</div>
                                <div className="flex flex-row gap-2 ml-auto">
                                    {scanning === vendor && <Spinner size={16} />}
                                    {scanned[vendor] && <div className="text-green-700">✓</div>}
                                </div>
                            </div>
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    )
}
