import { useGraphQlMutation } from 'common'

const adoptDeviceQuery = `
    mutation  AdoptDevice($serialno:String!){
      adoptDevice(serialNumber:$serialno){
          status
          message
        }}
`

interface AdoptDeviceInput {
    serialno: string
}

interface AdoptDeviceResponse {
    adoptDevice?: { status: boolean, message: string }
}

export default function useDeviceAdoption() {
    return useGraphQlMutation<AdoptDeviceResponse, AdoptDeviceInput>(
        adoptDeviceQuery
    )
}
