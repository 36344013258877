import { useGraphQlMutation } from 'common'

const requestDiagnosticFilesQuery = `
mutation RequestDiagnostics($deviceId:String!, $chargePointId:String!, $from:DateTime!, $to:DateTime!){
  requestDiagnostics(input:{deviceId:$deviceId, id:$chargePointId, from:$from, to:$to})
}
`

export type RequestDiagnosticsMutationVariables = {
    deviceId: string
    chargePointId: string
    from: string
    to: string
}
export type RequestDiagnosticsMutationData = { requestDiagnostics: boolean }

export default function useRequestDiagnostics() {
    return useGraphQlMutation<
        RequestDiagnosticsMutationData,
        RequestDiagnosticsMutationVariables
    >(requestDiagnosticFilesQuery)
}
