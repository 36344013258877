import { dataKeyComparator, useApiGetFunction } from 'common'
import { useCallback } from 'react'

// [timestamp, current, l1current, l2current, l3current]
export type ClusterLoadDataPoint = [string, number, number, number, number]

export interface ClusterLoadResponse {
    clusters: Cluster[]
}

interface Cluster {
    clusterId: string
    data: ClusterLoadDataPoint[]
}

export default function useClusterLoadFetchFunction(
    controllerId: string
): (startDate: string, endDate: string, binSizeInMinutes: number) => Promise<ClusterLoadResponse> {
    const get = useApiGetFunction<ClusterLoadResponse>()

    return useCallback(
        (startDate: string, endDate: string, binSizeInMinutes: number) =>
            get(
                `time-series/${controllerId}/cluster-load?startDate=${startDate}&endDate=${endDate}&binSize=${binSizeInMinutes}`
            ).then((res) => ({
                clusters: res.clusters.sort((a, b) => dataKeyComparator(a.clusterId, b.clusterId)),
            })),
        [controllerId, get]
    )
}
