import { Button, Modal, SpinnerWithHeight, ValidatedTextField } from 'common'
import useValidatedField from 'common/hooks/useValidatedField'
import useCreateControllerConfig from 'config/hooks/useCreateControllerConfig'
import { stringNonEmptyValidator } from 'config/utils/validators'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'
import style from './CreateControllerModal.module.scss'

interface CreateControllerModalProps {
    show: boolean
    close: () => void
    locationId: number
    refreshLocation: () => void
}

export default function CreateControllerModal({
    show,
    close,
    locationId,
    refreshLocation,
}: CreateControllerModalProps) {
    const { t } = useTranslation()
    const deviceIdField = useValidatedField<string>('', stringNonEmptyValidator)
    const nameField = useValidatedField<string>('', () => true)

    const [createControllerConfig, executeCreateControllerConfig, resetCreateControllerConfig] =
        useCreateControllerConfig()

    const handleClose = () => {
        close()
        if (createControllerConfig.status !== 'loading') {
            resetCreateControllerConfig()
        }
        if (createControllerConfig.status === 'success') {
            refreshLocation()
        }
    }

    const handleSave = () => {
        const newControllerConfig = {
            deviceId: deviceIdField.value,
            name: nameField.value,
            locationId,
            loadBalancingEnabled: true,
            sensorLoggerEnabled: false,
            sensorLoggerIntervalMs: 1500,
            isOcppProxyEnabled: false
        }
        executeCreateControllerConfig(newControllerConfig)
    }

    const fields = [deviceIdField, nameField]
    const allValid = fields.every((field) => field.isValid)

    const saveEnabled = allValid && createControllerConfig.status !== 'loading'

    const getContent = () => {
        switch (createControllerConfig.status) {
            case 'idle':
                return (
                    <>
                        <ValidatedTextField
                            field={deviceIdField}
                            title={t('createControllerModalFieldDeviceId')}
                        />
                        <ValidatedTextField
                            field={nameField}
                            title={t('createControllerModalFieldName')}
                        />
                        <div className={style.inputRow}>
                            <Button type="secondary" click={handleClose}>
                                {t('modalCancel')}
                            </Button>
                            <SaveButton
                                click={handleSave}
                                text={t('createControllerModalSaveButton')}
                                disabled={!saveEnabled}
                            />
                        </div>
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={60} />
            case 'success':
                return (
                    <>
                        <p>{t('createControllerModalSuccess')}</p>
                        <div className={style.inputRow}>
                            <Button type="secondary" click={handleClose}>
                                {t('modalClose')}
                            </Button>
                        </div>
                    </>
                )
            case 'error':
                return `Failed to create controller ${createControllerConfig.error}`
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('createControllerModalTitle')}</h2>
            {getContent()}
        </Modal>
    )
}
