import { Card, SpinnerWithHeight } from 'common'
import { ControllerIssue } from 'controller'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'
import { ReactComponent as ChecklistIcon } from 'img/checklist.svg'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './ControllerIssues.module.scss'

export default function ControllerIssues(): JSX.Element {
    const req = useControllerIssues()
    const { t } = useTranslation()

    const getIssueDescription = (issue: ControllerIssue) => {
        switch (issue.issueType) {
            case 'controllerConnection':
                return t('controllerIssuesControllerConnectionIssue', {
                    lastUpdate: issue.lastUpdate ? moment(issue.lastUpdate).fromNow() : '?',
                })
            case 'chargePointConnection':
                return issue.connectedChargePoints < issue.totalChargePoints
                    ? t('controllerIssuesChargePointConnectionIssue', {
                          count: issue.totalChargePoints - issue.connectedChargePoints,
                      })
                    : t('controllerIssuesChargePointConfigurationIssue', {
                          count: issue.totalChargePoints - issue.configuredChargePoints,
                      })
        }
    }

    const getContent = () => {
        switch (req.status) {
            case 'loading':
                return <SpinnerWithHeight height={110} />
            case 'error':
                return `Failed to load controller issues ${req.error}`
            case 'success':
                return req.data.length === 0 ? (
                    <div className={style.noIssues}>
                        <ChecklistIcon className={style.noIssuesIcon} />
                        <p>{t('controllerIssuesNoIssues')}</p>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('controllerIssuesDescriptionId')}</th>
                                <th>{t('controllerIssuesDescriptionName')}</th>
                                <th>{t('controllerIssuesDescriptionIssue')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {req.data.map((issue) => (
                                <tr key={issue.controllerId}>
                                    <td>
                                        <Link to={`/controller/${issue.controllerId}`}>
                                            {issue.controllerId}
                                        </Link>
                                    </td>
                                    <td>{issue.controllerName}</td>
                                    <td>{getIssueDescription(issue)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
        }
    }

    return <Card title={t('controllerIssuesTitle')}>{getContent()}</Card>
}
