import { useGraphQlMutation } from 'common'

const deleteConnectorQuery = `
    mutation DeleteConnector($id: Int!) {
        deleteConnector(id: $id)
    }
`

interface DeleteConnectorInput {
    id: number
}

interface DeleteConnectorResponse {
    deleteConnector: boolean
}

export default function useDeleteConnector() {
    return useGraphQlMutation<DeleteConnectorResponse, DeleteConnectorInput>(deleteConnectorQuery)
}
