import { StateTimeline } from 'chargePoint/types/stateTimeline'
import { Loadable, useApiGetFunction } from 'common'
import { useEffect, useState } from 'react'

// [timestamp, isConnected]
export type ChargePointConnectionState = [string, boolean]

export interface ChargePointConnectionStateResponse {
    deviceId: string
    chargePointId: string
    data: ChargePointConnectionState[]
}

export default function useChargePointConnectionState(
    controllerId: string,
    chargePointId: string,
    startDate: string,
    endDate: string
): Loadable<StateTimeline> {
    const get = useApiGetFunction<ChargePointConnectionStateResponse>()
    const [state, setState] = useState<Loadable<StateTimeline>>({
        status: 'loading',
    })

    useEffect(() => {
        get(
            `time-series/${controllerId}/${chargePointId}/charge-point-connection-state?startDate=${startDate}&endDate=${endDate}`
        )
            .then((res) =>
                setState({
                    status: 'success',
                    data: {
                        name: 'Connection',
                        stateNames: ['Disconnected', 'Connected'],
                        stateColors: ['#a00', '#0a0'],
                        points: res.data
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map((x) => ({ date: x[0], stateOrdinal: Number(x[1]) })),
                    },
                })
            )
            .catch((err) => setState({ status: 'error', error: err }))
    }, [controllerId, chargePointId, startDate, endDate, get])

    return state
}
