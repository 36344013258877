import { IconButton, ValidatedSelectField, ValidatedTextField } from 'common'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useUpdateControllerConfig from 'config/hooks/useUpdateControllerConfig'
import { ControllerConfig } from 'config/types/controllerConfig'
import {
    deviceIdValidator,
    integerValidator,
    stringNonEmptyValidator,
} from 'config/utils/validators'
import { ReactComponent as TrashCanIcon } from 'img/trashCan.svg'
import { ReactComponent as UploadIcon } from 'img/publish.svg'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'
import style from './ControllerDeviceConfig.module.scss'
import DeleteControllerModal from './DeleteControllerModal'
import { useState } from 'react'
import useCommitController from 'config/hooks/useCommitController'

interface ControllerConfigProps {
    controller: ControllerConfig
}

export default function ControllerDeviceConfig({ controller }: ControllerConfigProps) {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const deviceIdField = useValidatedField(controller.deviceId, deviceIdValidator)
    const nameField = useValidatedField(controller.name, stringNonEmptyValidator)
    const loadBalancingEnabledField = useValidatedField(
        controller.loadBalancingEnabled.toString(),
        () => true
    )
    const sensorLoggerEnabledField = useValidatedField(
        controller.sensorLoggerEnabled.toString(),
        () => true
    )
    const sensorLoggerIntervalField = useValidatedField(
        controller.sensorLoggerIntervalMs.toString(),
        integerValidator
    )
    const isOcppProxyEnabledField = useValidatedField(
        controller.isOcppProxyEnabled.toString(),
        () => true
    )
    const enableRoutingField = useValidatedField(
        controller.enableRouting.toString(),
        () => true
    )
    const { t } = useTranslation()
    const [controllerConfigState, setControllerConfig] = useControllerConfigState()
    const dispatchPopup = usePopupModal()
    const [updateControllerConfigState, executeUpdateControllerConfig] = useUpdateControllerConfig()
    const [commitController, executeCommitController] = useCommitController()

    const booleanOptions = [
        { value: 'true', text: t('booleanEnabled') },
        { value: 'false', text: t('booleanDisabled') },
    ]

    const fields = [
        deviceIdField,
        nameField,
        loadBalancingEnabledField,
        sensorLoggerEnabledField,
        isOcppProxyEnabledField,
        enableRoutingField,
        ...(sensorLoggerEnabledField.value === 'true' ? [sensorLoggerIntervalField] : []),
    ]

    const allValid = fields.every((field) => field.isValid)
    const anyModified = fields.some((field) => field.isModified)
    const saveEnabled = allValid && anyModified && updateControllerConfigState.status !== 'loading'

    const resetFields = () => fields.forEach((field) => field.reset())

    const handleSave = () => {
        executeUpdateControllerConfig({
            id: controller.id,
            locationId: controller.locationId,
            name: nameField.value,
            deviceId: deviceIdField.value,
            loadBalancingEnabled: loadBalancingEnabledField.value === 'true',
            sensorLoggerEnabled: sensorLoggerEnabledField.value === 'true',
            isOcppProxyEnabled:  isOcppProxyEnabledField.value === 'true',
            enableRouting:  enableRoutingField.value === 'true',
            sensorLoggerIntervalMs:
                sensorLoggerEnabledField.value === 'true'
                    ? +sensorLoggerIntervalField.value
                    : controller.sensorLoggerIntervalMs            
        })
            .then((res) => {
                if (Number.isInteger(res.data?.updateControllerConfig.id)) {
                    setControllerConfig((prev) => ({
                        ...prev,
                        controller: {
                            ...prev.controller,
                            ...res.data?.updateControllerConfig,
                            isDirty: true,
                        },
                    }))
                    resetFields()
                } else {
                    dispatchPopup({
                        title: 'Something went wrong when updating controller',
                        message: JSON.stringify(res.data?.updateControllerConfig),
                    })
                }
            })
            .catch((err) => {
                dispatchPopup({
                    title: 'Something when wrong when updating controller',
                    message: err.message,
                })
            })
    }

    const handleCommit = () => {
        executeCommitController({ id: controller.id })
            .then((res) => {
                if (res.data?.commitControllerConfig) {
                    setControllerConfig((prev) => ({
                        ...prev,
                        controller: {
                            ...prev.controller,
                            isDirty: false,
                        },
                    }))
                } else {
                    dispatchPopup({
                        title: 'Something went wrong when committing controller',
                        message: JSON.stringify(res.data?.commitControllerConfig),
                    })
                }
            })
            .catch((err) => {
                dispatchPopup({
                    title: 'Something when wrong when committing controller',
                    message: err.message,
                })
            })
    }

    const commitMessage = controllerConfigState.controller.isDirty ? (
        <p className={style.commitDirty}>{t('controllerConfigPageDeviceCommitDirty')}</p>
    ) : (
        <p className={style.commitNotDirty}>{t('controllerConfigPageDeviceCommitNotDirty')}</p>
    )

    const deleteEnabled =
        controllerConfigState.chargePoints.length === 0 &&
        controllerConfigState.sensors.length === 0 &&
        controllerConfigState.clusters.length === 0

    const deleteControllerText = deleteEnabled
        ? t('controllerConfigPageDeviceDelete')
        : t('controllerConfigPageDeviceDeleteDisabled')

    return (
        <div className={style.container}>
            <ValidatedTextField
                field={deviceIdField}
                title={t('controllerConfigPageDeviceFieldDeviceId')}
            />
            <ValidatedTextField
                field={nameField}
                title={t('controllerConfigPageDeviceFieldName')}
            />
            <ValidatedSelectField
                field={loadBalancingEnabledField}
                options={booleanOptions}
                title={t('controllerConfigPageDeviceFieldLoadBalancingEnabled')}
            />
            <div className={style.inputRow}>
                <ValidatedSelectField
                    field={sensorLoggerEnabledField}
                    options={booleanOptions}
                    title={t('controllerConfigPageDeviceFieldSensorLoggerEnabled')}
                />
                {sensorLoggerEnabledField.value === 'true' && (
                    <ValidatedTextField
                        field={sensorLoggerIntervalField}
                        title={t('controllerConfigPageDeviceFieldSensorLoggerInterval')}
                    />
                )}
            </div>
            <ValidatedSelectField
                field={isOcppProxyEnabledField}
                options={booleanOptions}
                title={t('controllerConfigPageDeviceFieldIsOcppProxyEnabled')}
            />
             <ValidatedSelectField
                field={enableRoutingField}
                options={booleanOptions}
                title={t('controllerConfigPageDeviceFieldEnableRouting')}
            />
            <IconButton
                icon={TrashCanIcon}
                type="primary"
                text={deleteControllerText}
                click={() => setShowDeleteModal(true)}
                className={style.button}
                disabled={!deleteEnabled}
            />
            <SaveButton
                click={handleSave}
                disabled={!saveEnabled}
                loading={updateControllerConfigState.status === 'loading'}
                text={t('controllerConfigPageDeviceSave')}
            />
            <div className={style.commitButtonContainer}>
                <SaveButton
                    icon={UploadIcon}
                    text={t('controllerConfigPageDeviceCommit')}
                    click={handleCommit}
                    className={style.button}
                    loading={commitController.status === 'loading'}
                    disabled={commitController.status === 'loading'}
                />
                {commitMessage}
            </div>
            <DeleteControllerModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                controller={controller}
            />
        </div>
    )
}
