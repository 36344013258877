// This hook fetches the devices that were already adopted
// but not yet assigned to a location.

import { mapSuccess, useGraphQl } from 'common'

const GetHardwareVersionsQuery = `
 query {
  allHardwareVersions{
    id
    hwVersion
    hwRevision    
  }
}
`

export interface IHardwareVersion {
    id: string
    hwVersion: string
    hwRevision: number
}

type GetHardwareVersionsQueryData = {
    allHardwareVersions: IHardwareVersion[]
}

export default function useGetHardwareVersions() {
    const [state, refetch] = useGraphQl<GetHardwareVersionsQueryData>(GetHardwareVersionsQuery)
    return [mapSuccess(state, (data) => data.allHardwareVersions), refetch] as const
}
