import { DiagnosticFile } from 'chargePoint/types/diagnosticFile'
import { mapSuccess, useGraphQl } from 'common'
import { useMemo } from 'react';

const requestDiagnosticFilesQuery = `
query RequestDiagnosticFiles($id:String!, $cpId:String!){
    controller(id:$id){
        diagnosticsFilesFor(chargePointId:$cpId){
            name, uri, lastModified
        }
    }
}
`

export type RequestDiagnosticFilesQueryVariables = { id: string; cpId: string }
export type RequestDiagnosticFilesQueryData = {
    controller: { diagnosticsFilesFor: DiagnosticFile[] }
}

export default function useRequestDiagnosticFiles(deviceId: string, chargePointId: string) {
    const [state, reexecuteQuery] = useGraphQl<RequestDiagnosticFilesQueryData>(
        requestDiagnosticFilesQuery,
        useMemo(() => ({ id: deviceId, cpId: chargePointId }), [deviceId, chargePointId])
    );
    return [mapSuccess(state, (data) => data.controller.diagnosticsFilesFor), reexecuteQuery] as const;
}
