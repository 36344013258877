export function numberValidator(value: string) {
    return value.length > 0 && !isNaN(Number(value))
}

export function integerValidator(value: string) {
    return value.length > 0 && Number.isInteger(+value)
}

export function stringNonEmptyValidator(value: string) {
    return value.length > 0
}

export function alphaNumericValidator(value: string) {
    return value.length > 0 && /^[a-zA-Z0-9]+$/.test(value)
}

export function serialNumberValidator(value: string) {
    return value.length > 0 && /^[a-zA-Z0-9-_]+$/.test(value)
}

export function deviceIdValidator(value: string) {
    return value.length > 0 && /^[a-zA-Z0-9-_]+$/.test(value)
}
