import classNames from 'classnames'
import {
    Breadcrumb,
    Card,
    ControllerPageLink,
    CurrentPage,
    IconButton,
    IconLink,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import LoggingConfigurationModal from 'controller/components/LoggingConfigurationModal/LoggingConfigurationModal'
import { SeverityLevel } from 'controller/hooks/useControllerLogs'
import useControllerLogs, { ControllerLog } from 'controller/hooks/useControllerLogs'
import { ReactComponent as ChevronDown } from 'img/chevronDown.svg'
import { ReactComponent as Chip } from 'img/chip.svg'
import { ReactComponent as Cog } from 'img/cog.svg'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import style from './ControllerLogsPage.module.scss'

const severityLevelTranslationKeys = [
    'controllerLogSeverityLevelAll',
    'controllerLogSeverityLevelInfo',
    'controllerLogSeverityLevelWarning',
    'controllerLogSeverityLevelError',
]

export default function ControllerLogsPage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }
    const [searchParams] = useSearchParams()
    const [minSeverity, setMinSeverity] = useState<SeverityLevel>(0)
    const [limit, setLimit] = useState<number | null>(null)
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.get('query') || '')
    const [showConfigModal, setShowConfigModal] = useState<boolean>(false)

    const { t } = useTranslation()

    const [req, refetch] = useControllerLogs(controllerId, limit ?? 100, searchQuery, minSeverity)
    const refetchRef = useRef<typeof refetch>(refetch)
    refetchRef.current = refetch

    const searchTimerRef = useRef<NodeJS.Timeout | null>(null)

    const searchDebounced = () => {
        if (searchTimerRef.current !== null) {
            clearTimeout(searchTimerRef.current)
        }
        searchTimerRef.current = setTimeout(() => refetchRef.current(), 1000)
    }

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentSearchQuery = e.currentTarget.value
        setSearchQuery(currentSearchQuery)
        searchDebounced()
    }

    const handleLimitInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentLimit = Number.parseInt(e.currentTarget.value) || null
        setLimit(currentLimit)
        searchDebounced()
    }

    const handleSetSeverity = (severity: SeverityLevel) => {
        setMinSeverity(severity)
        searchDebounced()
    }

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return `Failed to load controller logs: ${req.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return (
                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th>{t('controllerLogSeverity')}</th>
                                <th>{t('controllerLogDate')}</th>
                                <th>{t('controllerLogLogger')}</th>
                                <th>{t('controllerLogMessage')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {req.data.map((row, i) => (
                                <LogRow
                                    severity={row.severity}
                                    date={row.date}
                                    logger={row.logger}
                                    message={row.message}
                                    key={i}
                                />
                            ))}
                        </tbody>
                    </table>
                )
        }
    }

    return (
        <>
            <NavigationBar>
                <Breadcrumb>
                    <OverviewPageLink />
                    <ControllerPageLink controllerId={controllerId} />
                    <CurrentPage currentPage={t('controllerLogsTitle')} />
                </Breadcrumb>
                <IconLink
                    to={`/controller/${controllerId}/logs/direct`}
                    icon={Chip}
                    text={t('controllerLogsFetchDirectlyLink')}
                />
            </NavigationBar>
            <Card>
                <div className={style.headerWrapper}>
                    <h2>{t('controllerLogsTitle')}</h2>
                    <IconButton
                        icon={Cog}
                        text={t('controllerLogsConfigureLogging')}
                        click={() => setShowConfigModal(true)}
                        type="secondary"
                    />
                </div>
                <div className={style.searchBar}>
                    <h3>{t('controllerLogsFilter')}</h3>
                    <fieldset id="severityLevel">
                        <legend>{t('controllerLogsMinimumSeverity')}</legend>
                        {severityLevelTranslationKeys.map((tKey, i) => (
                            <div key={tKey} className={style.radioButton}>
                                <input
                                    id={tKey}
                                    type="radio"
                                    name="severityLevel"
                                    onChange={() => handleSetSeverity(i as SeverityLevel)}
                                    checked={minSeverity === i}
                                />
                                <label htmlFor={tKey}>{t(tKey)}</label>
                            </div>
                        ))}
                    </fieldset>
                    <div className={style.inputRow}>
                        <input
                            className={style.searchInput}
                            onChange={handleSearchInput}
                            value={searchQuery}
                            placeholder={t('controllerLogsSearch')}
                        />
                        <input
                            className={style.limitInput}
                            onChange={handleLimitInput}
                            value={limit ?? ''}
                            placeholder={'Limit (default 100)'}
                        />
                    </div>
                </div>
                <div>
                    <LoggingConfigurationModal
                        show={showConfigModal}
                        close={() => setShowConfigModal(false)}
                        controllerId={controllerId}
                    />
                </div>
                {getContent()}
            </Card>
        </>
    )
}

function LogRow({ severity, date, logger, message }: ControllerLog) {
    const { t } = useTranslation()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const classes = classNames(style.message, { [style.messageExpanded]: isExpanded })
    const chevronClasses = classNames(style.chevron, { [style.chevronExpanded]: isExpanded })
    return (
        <tr>
            <td>{t(severityLevelTranslationKeys[severity])}</td>
            <td title={date}>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</td>
            <td title={logger}>{logger.split('.').at(-1)}</td>
            <td className={style.messageCell}>
                <pre className={classes}>{message}</pre>

                <button
                    className={style.messageExpandButton}
                    onClick={() => setIsExpanded((isExp) => !isExp)}
                >
                    <ChevronDown className={chevronClasses} />
                </button>
            </td>
        </tr>
    )
}
