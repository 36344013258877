import { ValidatedSelectField } from 'common'
import useValidatedField, { ValidatedField } from 'common/hooks/useValidatedField'
import { NetType, netTypeValues } from 'config/types/controllerConfig'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Disclaimer: The whole phase mapping logic is a bit of a mess for historical reasons.
 * The phase mapping used in the backend is a packed string representation of the phase mapping,
 * where the index of the character in the string represents the position in the box, and the value
 * of the character represents the phase on the charger.
 * This is a bit difficult to understand, so we display a more human readable representation in the frontend,
 * and internally work with an unpacked representation.
 */

const validTNMappings = [
    // 3 phase
    'N123',
    'N132',
    'N213',
    'N231',
    'N312',
    'N321',
    // 1 phase
    'N1XX',
    'NX1X',
    'NXX1',
]
const validITMappings = ['XNLX', 'XNXL', 'XXNL', 'XLNX', 'XLXN', 'XXLN']

function packPhaseMapping(N: string, L1: string, L2: string, L3: string): string {
    const phases = [N, L1, L2, L3]
    const out = Array(4).fill('X')

    const isTN = N === 'N'
    if (isTN) {
        out[0] = 'N'
    }

    for (let chargePointIndex = 0; chargePointIndex < 4; chargePointIndex++) {
        const phase = phases[chargePointIndex]
        if (phase === '-' || phase === ' ') {
            continue
        }
        const boxIndex = Number(phase.replace('L', ''))
        out[boxIndex] = isTN
            ? chargePointIndex
            : chargePointIndex.toString().replace('0', 'N').replace('1', 'L')
    }

    return out.join('')
}

function unpackPhase(phase: string): string {
    if ('123'.includes(phase)) {
        return 'L' + phase
    } else {
        return phase.replace('X', '-')
    }
}

function unpackPhaseMapping(phaseMapping: string): [string, string, string, string] {
    const isTN = phaseMapping[0] === 'N'
    if (isTN) {
        return [
            unpackPhase(phaseMapping[0]),
            unpackPhase(phaseMapping[1]),
            unpackPhase(phaseMapping[2]),
            unpackPhase(phaseMapping[3]),
        ]
    } else {
        const neutral = phaseMapping.indexOf('N')
        const phase1 = phaseMapping.indexOf('L')
        const out = Array(4).fill(' ')
        out[0] = 'L' + neutral
        out[1] = 'L' + phase1
        return out as [string, string, string, string]
    }
}

const phaseMappingDict = {
    '1': 'L1',
    '2': 'L2',
    '3': 'L3',
    N: 'N',
    L: 'L1',
}
/**
 * Returns a string representation of the phase mapping that is only used in the frontend.
 * It is used to display the phase mapping in a human readable way.
 *
 * L1→L3 means that phase 1 in the charger is connected to phase 3 in the box.
 *
 * Examples:
 * f("N321") → "L1→L3,L2→L2,L3→L1"
 * f("N1XX") → "L1→L1"
 * f("NXX1") → "L1→L3"
 * f("XNLX") → "N→L1,L1→L2"
 * f("XNXL") → "N→L1,L1→L3"
 */
export function prettifyPhaseMapping(netType: NetType, phaseMapping: string) {
    const parts = []

    for (let i = 1; i < phaseMapping.length; i++) {
        if (phaseMapping[i] === 'X') {
            continue
        }
        const k = phaseMapping[i] as keyof typeof phaseMappingDict
        const fromPhase = phaseMappingDict[k] as string
        const toPhase = 'L' + i
        parts.push(`${fromPhase}→${toPhase}`)
    }

    return `${netType},${parts.join(',')}`
}

interface PhaseMapperProps {
    setPhaseMapping: (data: { netType: NetType; phaseMapping: string } | undefined) => void
    cps?: ChargePointSetupRow
}

export function PhaseMapper({ cps, setPhaseMapping }: PhaseMapperProps) {
    const { t } = useTranslation()

    const [initialN, initialL1, initialL2, initialL3] = cps
        ? unpackPhaseMapping(cps.phaseMapping)
        : ['N', 'L1', 'L2', 'L3']

    const NField = useValidatedField(initialN, (_) => true)
    const L1Field = useValidatedField(initialL1, (_) => true)
    const L2Field = useValidatedField(initialL2, (_) => true)
    const L3Field = useValidatedField(initialL3, (_) => true)

    const netTypeField = useValidatedField(
        cps?.netType ?? 'TN',
        (_) => true,
        (netType) => {
            if (netType === 'IT' || netType === 'TT') {
                NField.resetInitialValue('L2')
                L1Field.resetInitialValue('L1')
                L2Field.resetInitialValue(' ')
                L3Field.resetInitialValue(' ')
            } else {
                NField.resetInitialValue('N')
                L1Field.resetInitialValue('L1')
                L2Field.resetInitialValue('L2')
                L3Field.resetInitialValue('L3')
            }
        }
    )

    function validatePhaseSelection(
        netType: NetType,
        neutral: string,
        phase1: string,
        phase2: string,
        phase3: string
    ) {
        const mapping = packPhaseMapping(neutral, phase1, phase2, phase3)
        return (netType === 'TN' ? validTNMappings : validITMappings).includes(mapping)
    }

    const phaseSelectionIsValid = validatePhaseSelection(
        netTypeField.value as NetType,
        NField.value,
        L1Field.value,
        L2Field.value,
        L3Field.value
    )

    function getPhaseOptions(netType: NetType, field: ValidatedField<string>): { value: string }[] {
        if (netType === 'IT' || netType === 'TT') {
            if (field === NField || field === L1Field) {
                return [{ value: 'L1' }, { value: 'L2' }, { value: 'L3' }]
            } else {
                return [{ value: ' ' }]
            }
        } else {
            if (field === NField) {
                return [{ value: 'N' }]
            } else {
                return [{ value: 'L1' }, { value: 'L2' }, { value: 'L3' }, { value: '-' }]
            }
        }
    }

    useEffect(() => {
        if (phaseSelectionIsValid) {
            const netType = netTypeField.value as NetType
            const phaseMapping = packPhaseMapping(
                NField.value,
                L1Field.value,
                L2Field.value,
                L3Field.value
            )
            setPhaseMapping({ netType, phaseMapping })
        } else {
            setPhaseMapping(undefined)
        }
    }, [L1Field.value, L2Field.value, L3Field.value, netTypeField.value, NField.value, phaseSelectionIsValid, setPhaseMapping])

    return (
        <>
            <ValidatedSelectField
                field={netTypeField}
                title={t('netType')}
                options={netTypeValues.map((x) => ({ value: x }))}
                forceModified={true}
                className="w-full"
                classNameInner="px-6 py-4 sm:px-4 sm:py-2 text-xl w-full"
            />
            <div className="grid grid-cols-7 gap-4 mt-2 pt-2">
                <>
                    <div className="col-span-3">{t('chargerPhase')}</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-3 ml-auto">{t('boxPhase')}</div>
                </>
                {[
                    { field: NField, title: 'N' },
                    { field: L1Field, title: 'L1' },
                    { field: L2Field, title: 'L2' },
                    { field: L3Field, title: 'L3' },
                ].map((phaseSelector, i) => (
                    <Fragment key={i}>
                        <div className="col-span-3 py-4 sm:py-2 text-xl">{phaseSelector.title}</div>
                        <div className="col-span-1 py-4 sm:py-2 text-center">→</div>
                        <ValidatedSelectField
                            field={phaseSelector.field}
                            options={getPhaseOptions(
                                netTypeField.value as NetType,
                                phaseSelector.field
                            )}
                            disabled={
                                (netTypeField.value === 'TN' && phaseSelector.field === NField) ||
                                (netTypeField.value !== 'TN' &&
                                    (phaseSelector.field === L2Field ||
                                        phaseSelector.field === L3Field))
                            }
                            forceUnmodified={true}
                            className="col-span-3 w-full"
                            classNameInner="py-4 sm:py-2 w-full text-xl"
                            placeholder=" "
                        />
                    </Fragment>
                ))}
            </div>
            <div className="mt-4">
                {phaseSelectionIsValid ? (
                    <div className="text-green-500 text-lg">{t('phaseSelectionValid')}</div>
                ) : (
                    <div className="text-red-500 text-lg">{t('phaseSelectionInvalid')}</div>
                )}
            </div>
        </>
    )
}
