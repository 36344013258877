import { useGraphQlMutation } from 'common'

const rebootControllerQuery = `
    mutation RebootController($deviceId: String!) {
        rebootController(deviceId: $deviceId)
    }
`

interface RebootControllerInput {
    deviceId: string
}

interface RebootControllerResponse {
    rebootController?: boolean
}

export default function useRebootController() {
    return useGraphQlMutation<RebootControllerResponse, RebootControllerInput>(
        rebootControllerQuery
    )
}
