import { ReactNode } from 'react'
import style from './Card.module.scss'

interface CardProps {
    title?: string
    children: ReactNode
    className?: string
}

export default function Card({ children, title, className }: CardProps) {
    return (
        <div className={style.card + ` ${className ? className : ''}`}>
            {title ? <h2>{title}</h2> : null}
            {children}
        </div>
    )
}
