import { Button, Modal } from 'common'
import { useTranslation } from 'react-i18next'
import style from './ProvisioningActions.module.scss'
import useBusinessUnit from 'provisioning/hooks/useBusinessList'
import { IadoptedDevice } from 'provisioning/hooks/useGetProvisionedDevices'
import useGetLocationsByBUs from 'provisioning/hooks/useGetLocationsByBUs'
import { BusinessUnit, EBusinessUnits } from 'config/types/controllerConfig';
import { useState } from 'react'
import useAssignDevice, { assignDeviceMutationArgs } from 'provisioning/hooks/useAssignDevice'

interface DeviceAdoptingModalProps {
    show: boolean
    close: () => void
    device?: IadoptedDevice
}

export default function DeviceAdoptingModal({ show, close, device }: DeviceAdoptingModalProps) {
    const { t } = useTranslation()
    const [chosenBu, setChosenBu] = useState<BusinessUnit>()
    const [chosenLocation, setChosenLocation] = useState<{
        id: number;
        deviceId: string;
        name: string;
    }>()
    const [deviceName, setDeviceName] = useState<string>()
    const [error, setError] = useState<string>()

    const [loadableLocations] = useGetLocationsByBUs(chosenBu as BusinessUnit)
    const [loadableBusinessUnits] = useBusinessUnit()
    const [assignDeviceState, assignDevice] = useAssignDevice()

    function textChangedHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setDeviceName(e.target.value)
    }

    const areBusLoaded = loadableBusinessUnits.status === 'success';
    const areLocationsLoaded = loadableLocations.status === 'success';

    const handleClose = () => {
        close()
    }

    const onDeviceAssignment = async ({ deviceId, name, locationId, provisioningId }: assignDeviceMutationArgs) => {
        if (!deviceId || !name || !locationId || !provisioningId) {
            setError(t('assignVariablesUnset'));
        }

        await assignDevice({ deviceId, name, locationId, provisioningId })
        close()
    }

    if (!device?.serialNumber || !device?.hardwareVersion) {
        return (
            <Modal show={show} onClickOutside={handleClose} className={style.deviceAdoptingModal}>
                <h4>{t('deviceNotRecognized')}</h4>
            </Modal>
        )
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.deviceAdoptingModal}>
            <h2>{t('assignToLocationModal')}</h2>
            <div className={style.informationSection}>
                <span className={style.label}><strong>{t('serialNumber')}:</strong></span>
                <span>{device.serialNumber}</span>
            </div>
            <div className={style.informationSection}>
                <span className={style.label}><strong>{t('deviceType')}:</strong></span>
                <span>{device.hardwareVersion}</span>
            </div>
            <form className={style.assignToLocationForm} noValidate>
                <div className={style.informationSection}>
                    <label htmlFor="businessUnitsDropDown" className="">
                        <strong>{t('pleaseChooseBu')}</strong>
                    </label>
                    <select
                        id="businessUnitsDropDown"
                        onChange={(e) => setChosenBu(e.target.value as keyof typeof EBusinessUnits)}
                        className="mb-4"
                        disabled={!areBusLoaded}
                        placeholder={t('selectBuDropDownPlaceholder')}
                    >
                        <option value="" key="" data-testid="select">
                            {t('selectBuDropDownPlaceholder')}
                        </option>
                        {areBusLoaded && loadableBusinessUnits.data.map(businessUnit => {
                            return <option value={businessUnit} key={businessUnit}>{businessUnit}</option>
                        })}
                    </select>
                </div>
                <div className={style.informationSection}>
                    <label htmlFor="locationsDropDown" className="">
                        <strong>{t('pleaseChooseLocation')}</strong>
                    </label>
                    <select
                        id="locationsDropDown"
                        onChange={(e) => {
                            if (areLocationsLoaded) {
                                setChosenLocation(loadableLocations.data.locationsByBusinessUnit[Number(e.target.value)])
                            }
                        }}
                        className="mb-4"
                        disabled={!areLocationsLoaded || !chosenBu}
                        placeholder={t('pleaseChooseBu')}
                    >
                        <option value="" key="" data-testid="select">
                            {t('selectLocationDropDownPlaceholder')}
                        </option>
                        {areLocationsLoaded && loadableLocations.data.locationsByBusinessUnit.map((location, index) => {
                            return <option value={index as number} key={location.id}>{location.name}</option>
                        })}
                    </select>
                </div>
                <div className={style.informationSection}>
                    <span className={style.label}><strong>{t('name')}:</strong></span>
                    <input
                        type="text"
                        placeholder={t('selectDeviceNamePlaceholder')}
                        onChange={textChangedHandler}
                        className="max-w-sm my-2"
                    />
                </div>
                <div className={`mt-4 ${style.informationSection}`}>
                    <span className={style.label}></span>
                    <Button
                        type="secondary"
                        disabled={assignDeviceState.status === 'loading'}
                        click={() => onDeviceAssignment({ deviceId: device.serialNumber, name: deviceName as string, locationId: chosenLocation?.id as number, provisioningId: device.provisioningId })}
                        >
                            {t('assignToLocation')}
                    </Button>
                </div>
            </form>
            {error ??
                <p>{error}</p>
            }
        </Modal>
    )
}
