import { Breadcrumb, CurrentPage, IconButton, Modal, OverviewPageLink, Spinner } from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { ReactComponent as LinkIcon } from 'img/link.svg'
import { ReactComponent as RadarIcon } from 'img/radar.svg'
import { ReactComponent as ApproveIcon } from 'img/check-decagram.svg'
import { ReactComponent as ContentCopyIcon } from 'img/content-copy.svg'
import { useAxios } from 'common/hooks/useApi'
import { addWeeks, lightFormat } from 'date-fns'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import useGetChargePointSetup from 'electricianView/hooks/useGetChargePointSetup'
import { ReactComponent as FileExportOutlineIcon } from 'img/file-export-outline.svg'
import { LocationPageLink } from 'common/components/Breadcrumb/Breadcrumb'
import { ChargePointTable } from 'electricianView/components/ChargePointTable/ChargePointTable'
import usePostChargePointSetup from 'electricianView/hooks/usePostChargePointSetup'
import usePutChargePointSetup from 'electricianView/hooks/usePutChargePointSetup'
import useDeleteChargePointSetup from 'electricianView/hooks/useDeleteChargePointSetup'
import usePopupModal from 'common/hooks/usePopupModal'
import generateReactKey from 'config/utils/generateReactKey'
import SaveButton from 'config/components/SaveButton/SaveButton'
import style from './ElectricianViewPage.module.scss'
import { ScanAndInit } from 'electricianView/components/ScanAndInit/ScanAndInit'
import { ExportToCsv } from 'export-to-csv-fix-source-map'
import ApproveModal from 'electricianView/components/ApproveModal/ApproveModal'
import { prettifyPhaseMapping } from 'electricianView/components/PhaseMapper/PhaseMapper'

export default function ElectricianViewPage(): JSX.Element {
    const { locationId, controllerId, accessToken } = useParams() as {
        locationId: string
        controllerId: string
        accessToken?: string
    }

    // Access token is only used for electricians, so if it's undefined, we're a manager
    // This is used to determine if we should show manager-only features.
    const isManager = accessToken === undefined

    const createChargePointSetupRow = usePostChargePointSetup(controllerId, accessToken)
    const updateChargePointSetupRow = usePutChargePointSetup(controllerId, accessToken)
    const deleteChargePointSetupRow = useDeleteChargePointSetup(controllerId, accessToken)

    const { t } = useTranslation()
    const dispatchPopup = usePopupModal()

    const getAxiosInstance = useAxios()
    const [_, fetchChargePointSetupRowsFromDatabase] = useGetChargePointSetup(
        controllerId,
        accessToken
    )

    const [backendRows, setBackendRows] = useState<ChargePointSetupRow[]>([])
    const [localRows, setLocalRows] = useLocalStorage<ChargePointSetupRow[]>(
        `chargePointSetupRows-${controllerId}`,
        []
    )

    const [isRetryInProgress, setIsRetryInProgress] = useState(false)
    const [showApproveModal, setShowApproveModal] = useState(false)

    useEffect(() => {
        fetchChargePointSetupRowsFromDatabase().then((res) => {
            setBackendRows(res.data.map((x) => ({ ...x, reactKey: generateReactKey() })))
        })
        // Get is determined by controllerId and accessToken, so we don't need to add it to the dependency array
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controllerId, accessToken])

    const backendRowsNotInLocal = backendRows.filter(
        (x) => !localRows.some((y) => y.serialNumber === x.serialNumber)
    )
    const chargePointSetupRows = [...backendRowsNotInLocal, ...localRows]

    const initializedChargePointSetupRows = chargePointSetupRows.filter(
        (cpsr) => cpsr.status === 'Initialized'
    )
    const uninitializedChargePointSetupRows = chargePointSetupRows.filter(
        (cpsr) => cpsr.status !== 'Initialized'
    )

    const retryFailed = async () => {
        const failedRows = localRows.filter((x) => x.failedToSave)

        for (const row of failedRows) {
            await saveRow({ ...row, failedToSave: false }, true)
        }
    }

    const handleRetry = () => {
        setIsRetryInProgress(true)
        retryFailed().finally(() => setIsRetryInProgress(false))
    }

    const saveRow = async (row: ChargePointSetupRow, isRetry: boolean = false) => {
        setLocalRows((prev) => {
            // Swap out the row if it already exists
            if (prev.some((x) => x.reactKey === row.reactKey)) {
                return prev.map((x) => (x.reactKey === row.reactKey ? row : x))
            }
            return [...prev, row]
        })

        if (!Number.isSafeInteger(row.id)) {
            await createChargePointSetupRow({
                parkingSpot: row.parkingSpot,
                smsCode: row.smsCode,
                serialNumber: row.serialNumber,
                netType: row.netType,
                phaseMapping: row.phaseMapping,
                description: row.description ?? '',
                networkLocation: row.networkLocation,
                status: 'NotInitialized',
                cluster: row.cluster,
            })
                .then((res) => {
                    setBackendRows((prev) => [...prev, res])
                    setLocalRows((prev) => prev.filter((x) => x.reactKey !== row.reactKey))
                })
                .catch((err) => {
                    setLocalRows((prev) => {
                        if (isRetry) {
                            dispatchPopup({ title: 'Failed to save', message: err.toString() })
                        }
                        return prev.map((x) =>
                            x.reactKey === row.reactKey ? { ...x, failedToSave: true } : x
                        )
                    })
                })
        } else {
            await updateChargePointSetupRow({
                id: row.id!,
                parkingSpot: row.parkingSpot,
                smsCode: row.smsCode,
                serialNumber: row.serialNumber,
                netType: row.netType,
                phaseMapping: row.phaseMapping,
                description: row.description ?? '',
                networkLocation: row.networkLocation,
                status: 'NotInitialized',
                cluster: row.cluster,
            })
                .then((res) => {
                    setBackendRows((prev) => prev.map((x) => (x.id === row.id ? res : x)))
                    setLocalRows((prev) => prev.filter((x) => x.reactKey !== row.reactKey))
                })
                .catch((err) => {
                    if (isRetry) {
                        dispatchPopup({ title: 'Failed to save', message: err.toString() })
                    }
                    setLocalRows((prev) =>
                        prev.map((x) =>
                            x.reactKey === row.reactKey ? { ...x, failedToSave: true } : x
                        )
                    )
                })
        }
    }

    const deleteRow = (row: ChargePointSetupRow) => {
        if (!Number.isSafeInteger(row.id)) {
            setLocalRows((prev) => prev.filter((x) => x.reactKey !== row.reactKey))
        } else {
            deleteChargePointSetupRow({ id: row.id! })
                .then((res) => {
                    if (res) {
                        setBackendRows((prev) => prev.filter((x) => x.id !== row.id))
                    } else {
                        dispatchPopup({
                            type: 'error',
                            title: 'Failed to delete',
                            message: `Delete request returned ${res}`,
                        })
                    }
                })
                .catch((err) => {
                    dispatchPopup({
                        type: 'error',
                        title: 'Unexpected error while trying to delete',
                        message: `${err}`,
                    })
                })
        }
    }

    const [linkModalVisible, setLinkModalVisible] = useState(false)
    const [scanAndInitVisible, setScanAndInitVisible] = useState(false)
    const [electricianLink, setElectricianLink] = useState<string | undefined>(undefined)
    const [tokenExpiryDate, setTokenExpiryDate] = useState<Date | undefined>(undefined)

    function getElectricianAuthToken() {
        return getAxiosInstance().then(async (axios) => {
            const expiryDate = addWeeks(new Date(), 1)
            const token = await axios
                .post(`/api/charge-point-setup/${controllerId}/create-token`, null, {
                    params: { expiryDate: expiryDate.toISOString() },
                })
                .then((res) => res.data)
            return { token, expiryDate }
        })
    }

    return (
        <>
            <Modal
                onClickOutside={() => setLinkModalVisible(false)}
                show={linkModalVisible}
                className="w-full max-w-xl"
            >
                <div className="flex flex-col p-4 gap-4">
                    <h2 className="border-b border-b-gray-200 pb-1">{t('electricianLink')}</h2>
                    {electricianLink ? (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-row items-center gap-4">
                                    <div className="text-xs font-mono break-all">
                                        {electricianLink}
                                    </div>
                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(electricianLink)
                                        }}
                                        title={t('copyLinkToClipboard')}
                                        className="p-0.5 rounded-md outline outline-transparent active:outline-blue-400 focus:outline-blue-200"
                                    >
                                        <ContentCopyIcon className="w-5 fill-gray-500" />
                                    </button>
                                </div>
                                <p className="text-xs">
                                    The link expire{' '}
                                    {lightFormat(tokenExpiryDate!, 'yyyy-MM-dd HH:mm')}
                                </p>
                            </div>
                        </>
                    ) : (
                        <Spinner size={16} />
                    )}
                </div>
            </Modal>
            {isManager && (
                <Breadcrumb className="text-sm sm:text-base mb-4">
                    <OverviewPageLink />
                    <LocationPageLink
                        locationName={`location-${locationId}`}
                        locationId={locationId}
                    />
                    <CurrentPage currentPage={t('electricianViewTitle')} />
                </Breadcrumb>
            )}
            <div className="flex flex-row justify-end gap-4">
                <IconButton
                    click={() => {
                        exportToCsv(chargePointSetupRows)
                    }}
                    type="secondary"
                    dense
                    text={t('electricianViewExportToCsv')}
                    icon={FileExportOutlineIcon}
                />
                {isManager && (
                    <IconButton
                        click={() => {
                            setShowApproveModal(true)
                        }}
                        type="primary"
                        dense
                        text={t('electricianViewPageApprove')}
                        icon={ApproveIcon}
                    />
                )}
                <IconButton
                    click={() => {
                        setScanAndInitVisible(true)
                    }}
                    type="primary"
                    dense
                    text={isManager ? t('scanAndInit') : t('scan')}
                    icon={RadarIcon}
                    className="fill-white"
                />
                {isManager && (
                    <IconButton
                        click={() => {
                            setElectricianLink(undefined)
                            setLinkModalVisible(true)
                            getElectricianAuthToken().then(({ token, expiryDate }) => {
                                const url = new URL(
                                    `/location/${locationId}/controller/${controllerId}/electrician/${token}`,
                                    window.location.href
                                )
                                setElectricianLink(url.toString())
                                setTokenExpiryDate(expiryDate)
                            })
                        }}
                        type="primary"
                        dense
                        text={t('createLink')}
                        icon={LinkIcon}
                        className="fill-white"
                    />
                )}
            </div>
            <div className="pt-4 flex flex-col gap-4">
                <ChargePointTable
                    chargePointSetupRows={initializedChargePointSetupRows}
                    readOnly={true}
                />
                {(localRows.some((row) => row.failedToSave) || isRetryInProgress) && (
                    <div className="flex flex-row gap-4">
                        <p className={style.failedToSaveText}>
                            {t('electricianViewPageFailedToSave')}
                        </p>
                        <SaveButton
                            click={handleRetry}
                            text={t('electricianViewPageRetry')}
                            loading={isRetryInProgress}
                            disabled={isRetryInProgress}
                        />
                    </div>
                )}
                <ChargePointTable
                    chargePointSetupRows={uninitializedChargePointSetupRows}
                    saveChargePointSetupRow={saveRow}
                    deleteChargePointSetupRow={deleteRow}
                    readOnly={false}
                />
                {scanAndInitVisible && (
                    <div className="absolute top-0 left-0 bottom-0 right-0 sm:relative sm:mt-4 bg-white max-w-4xl">
                        <ScanAndInit
                            chargePointSetupRows={uninitializedChargePointSetupRows}
                            controllerId={controllerId}
                            close={() => setScanAndInitVisible(false)}
                        />
                    </div>
                )}
                <ApproveModal
                    show={showApproveModal}
                    close={() => setShowApproveModal(false)}
                    controllerId={+controllerId}
                />
            </div>
        </>
    )
}

function exportToCsv(rows: ChargePointSetupRow[]) {
    const data = rows.map((row) => {
        const [s_switch, s_loop, s_charger] = row.networkLocation.split('-')
        return {
            'Parking Spot': row.parkingSpot,
            'SMS Code': row.smsCode,
            'Serial Number': row.serialNumber,
            'Net Type': row.netType,
            'Phase Mapping': prettifyPhaseMapping(row.netType, row.phaseMapping)
                .split(',')
                .slice(1)
                .sort()
                .join(', '),

            // 'Network Location': row.networkLocation,
            Cluster: row.cluster,

            Switch: s_switch,
            Loop: s_loop,
            Charger: s_charger,

            Description: row.description,
            Status: 'NotInitialized',
        }
    })
    const options = {
        fieldSeparator: ';',
        quoteStrings: '"',
        useKeysAsHeaders: true,
    }
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(data)
}
