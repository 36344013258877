import { Button, FetchFunction, SpinnerWithHeight, useZoomableTimeSeries } from 'common'
import LoadChart from 'common/components/LoadChart/LoadChart'
import { RawChartData } from 'common/types/chartTypes'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './ZoomableLoadChart.module.scss'
import { useZoom } from 'controller/contexts/ZoomContext'

interface ZoomableLoadChartProps<TData> {
    fetchFn: FetchFunction<TData>
    dataMapper: (data: TData) => RawChartData
    onClickLegendItem?: (itemKey: string) => void
}

export default function ZoomableLoadChart<TData>({
    fetchFn,
    dataMapper,
    onClickLegendItem,
}: ZoomableLoadChartProps<TData>) {
    const [selectedTimeRange, setSelectedTimeRange] = useState<7 | 30>(7)
    const [chartStartDate, setChartStartDate] = useState(() =>
        moment().subtract(selectedTimeRange, 'days').toISOString()
    )
    const [chartEndDate] = useState(() => moment().toISOString())
    const [isZoomedLocal, setIsZoomedLocal] = useState<boolean>(false)
    const { isZoomed, startDate, endDate, setZoom, mirrorZoom } = useZoom();

    const { t } = useTranslation()

    const { chartData, setChartBounds, resetChartBounds } = useZoomableTimeSeries(
        fetchFn,
        dataMapper,
        chartStartDate,
        chartEndDate
    )

    let displayResetButton = isZoomedLocal || (mirrorZoom && isZoomed);

    useEffect(() => {
        if (mirrorZoom && startDate && endDate && setZoom) {
            setZoom(true, startDate, endDate);
            setIsZoomedLocal(true);
            setChartBounds(
                startDate,
                endDate
            )
            return
        }

        if (mirrorZoom && !startDate && !endDate) {
            resetChartBounds()
        }
    }, [mirrorZoom, isZoomed, startDate, endDate, setZoom, setChartBounds, isZoomedLocal, resetChartBounds])

    const handleZoom = (startIndex: number, endIndex: number) => {
        if (chartData.status !== 'success') {
            return
        }
        setIsZoomedLocal(true)
        if (mirrorZoom) {
            setZoom && setZoom(true, chartData.data.dataPoints[startIndex].date, chartData.data.dataPoints[endIndex].date)
        }
        setChartBounds(
            chartData.data.dataPoints[startIndex].date,
            chartData.data.dataPoints[endIndex].date
        )
    }

    const getNoDataFoundMessage = (selectedTimeRange: number) => {
        if(selectedTimeRange === 30){
            return <h3>No data found for the previous month.</h3>
        }
        return <h3>No data found for the last 7 days.</h3>
    }


    const getGraph = () => {
        switch (chartData.status) {
            case 'error':
                return `Failed to load chart data: ${chartData.error}`
            case 'loading':
                return <SpinnerWithHeight height={250} />
            case 'success':
                return (
                    <>
                        <div className={style.buttonRow}>
                            {selectedTimeRange !== 7 && (
                                <Button
                                    type="secondary"
                                    click={() => {
                                        setSelectedTimeRange(7)
                                        setZoom && setZoom(false)
                                        setChartStartDate(
                                            moment().subtract(7, 'days').toISOString()
                                        )
                                    }}
                                >
                                    {t('zoomableChartShowWeek')}
                                </Button>
                            )}
                            {selectedTimeRange !== 30 && (
                                <Button
                                    type="secondary"
                                    click={() => {
                                        setSelectedTimeRange(30)
                                        setZoom && setZoom(false)
                                        setChartStartDate(
                                            moment().subtract(30, 'days').toISOString()
                                        )
                                    }}
                                >
                                    {t('zoomableChartShowMonth')}
                                </Button>
                            )}
                            {displayResetButton && (
                                <Button
                                    click={() => {
                                        setZoom && setZoom(false, undefined, undefined)
                                        setIsZoomedLocal(false)
                                        resetChartBounds()
                                    }}
                                    type="secondary"
                                >
                                    {t('zoomableChartResetZoom')}
                                </Button>
                            )}
                        </div>
                        {chartData.data.dataKeys.length > 0 ? <LoadChart
                            dataPoints={chartData.data.dataPoints}
                            dataKeys={chartData.data.dataKeys}
                            onZoom={handleZoom}
                            onClickLegendItem={onClickLegendItem}
                        /> : getNoDataFoundMessage(selectedTimeRange)}
                    </>
                )
        }
    }
    return <div className={style.container}>{getGraph()}</div>
}
