import style from './Tabs.module.scss'

export type Tab = {
    text: string;
    value: string;
};

type TabsProps = {
    tabs: Tab[];
    onTabClick: (activeButton: Tab) => void;
    activeTab: Tab;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
};

function Tabs({ tabs, onTabClick, activeTab, children, onClick }: TabsProps): JSX.Element {
    return (
        <section role="tablist" className={style.container} data-testid="tabsContainer">
            {tabs.map((tab) => {
                const selected = tab.value === activeTab.value;
                return (
                    <label
                        htmlFor={tab.value}
                        key={tab.value}
                        role="tab"
                        aria-selected={selected}
                        className={selected ? style.tabLabelSelected : style.tabLabelUnselected}
                    >
                        {tab.text}
                        <input
                            name="radioTabs"
                            type="radio"
                            id={tab.value}
                            checked={selected}
                            onChange={() => onTabClick(tab)}
                            onClick={onClick}
                        />
                    </label>
                );
            })}
            {children}
        </section>
    );
}

export default Tabs;
