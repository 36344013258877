import axios from 'axios'
import { useAxios } from 'common/hooks/useApi'

interface DeleteChargePointSetupParams {
    id: number
}

export default function useDeleteChargePointSetup(controllerId: string, accessToken?: string) {
    const getAuthenticatedAxiosInstance = useAxios()

    return async (params: DeleteChargePointSetupParams) => {
        const axiosClient = accessToken
            ? axios.create({ baseURL: process.env.REACT_APP_BACKEND_API_URL })
            : await getAuthenticatedAxiosInstance()

        const path =
            `/api/charge-point-setup/${controllerId}` + (accessToken ? `/${accessToken}` : '')
        return axiosClient.delete(path, { params }).then((res) => res.data as boolean)
    }
}
