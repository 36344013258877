import { IconButton, ValidatedTextField } from 'common'
import NestableFrame from 'common/components/NestableFrame/NestableFrame'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import { stringNonEmptyValidator } from 'config/utils/validators'
import useLocation from 'location/hooks/useLocation'
import useUpdateLocation from 'location/hooks/useUpdateLocation'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SaveButton from '../SaveButton/SaveButton'
import style from './LocationConfig.module.scss'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import { useState } from 'react'
import CreateControllerModal from '../CreateControllerModal/CreateControllerModal'
import TrashCanButton from '../TrashCanButton/TrashCanButton'
import DeleteLocationModal from './DeleteLocationModal'

type LocationConfigType = (ReturnType<typeof useLocation>[0] & { status: 'success' })['data']

interface LocationConfigProps {
    config: LocationConfigType
    refreshLocation: () => void
}

export default function LocationConfig({ config, refreshLocation }: LocationConfigProps) {
    const { location, controllers } = config
    const { t } = useTranslation()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const nameField = useValidatedField(location.name, stringNonEmptyValidator)

    const [showCreateControllerModal, setShowCreateControllerModal] = useState(false)

    const [updateLocation, executeUpdateLocation] = useUpdateLocation()

    const dispatchPopup = usePopupModal()

    const handleSave = () => {
        executeUpdateLocation({
            id: location.id,
            name: nameField.value,
        })
            .then((res) => {
                if (res.data?.updateLocation.name) {
                    nameField.reset()
                    refreshLocation()
                } else {
                    dispatchPopup({
                        title: 'Something went wrong when updating location',
                        message: JSON.stringify(res),
                    })
                }
            })
            .catch((err) =>
                dispatchPopup({
                    title: 'Something went wrong when updating location',
                    message: err.toString(),
                })
            )
    }

    const saveEnabled =
        nameField.isValid && nameField.isModified && updateLocation.status !== 'loading'

    const deleteEnabled = controllers.length === 0

    return (
        <div className={style.controllerList}>
            <div className={style.row}>
                <ValidatedTextField field={nameField} title={t('locationConfigPageFieldName')} />
                <div className={style.saveButtonContainer}>
                    <SaveButton
                        click={handleSave}
                        text={t('locationConfigPageSaveButton')}
                        loading={updateLocation.status === 'loading'}
                        disabled={!saveEnabled}
                    />
                </div>
                <div className={style.deleteButtonContainer}>
                    <TrashCanButton
                        onClick={() => setShowDeleteModal(true)}
                        disabled={!deleteEnabled}
                    />
                </div>
            </div>
            <h2>{t('locationConfigPageControllersHeading')}</h2>
            {controllers.map((controller) => (
                <NestableFrame key={controller.id}>
                    <p className={style.deviceId}>
                        <Link
                            to={`/location/${config.location.id}/controller/${controller.id}/config`}
                        >
                            {controller.deviceId}
                        </Link>
                    </p>
                    <p className={style.name}>{controller.name}</p>
                </NestableFrame>
            ))}
            <IconButton
                type="secondary"
                icon={PlusIcon}
                text={t('locationConfigCreateControllerButton')}
                click={() => setShowCreateControllerModal(true)}
            />
            <CreateControllerModal
                show={showCreateControllerModal}
                locationId={location.id}
                close={() => setShowCreateControllerModal(false)}
                refreshLocation={refreshLocation}
            />
            <DeleteLocationModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                location={location}
            />
        </div>
    )
}
