import { AccessControl } from 'chargePoint'
import { useGraphQlMutation } from 'common'

const updateChargePointQuery = `
    mutation UpdateChargePoint(
        $id: Int!
        $clusterId: Int!
        $chargePointId: String!
        $accessControl: AccessControlType!
    ) {
        updateChargePoint(
            id: $id
            clusterId: $clusterId
            chargePointId: $chargePointId
            accessControl: $accessControl
        ) {
            id
            clusterId
            chargePointId
            accessControl
        }
    }
`

interface UpdateChargePointInput {
    id: number
    clusterId: number
    chargePointId: string
    accessControl: AccessControl
}

interface UpdateChargePointResponse {
    updateChargePoint: {
        id: number
        clusterId: number
        chargePointId: string
        accessControl: AccessControl
    }
}

export default function useUpdateChargePoint() {
    return useGraphQlMutation<UpdateChargePointResponse, UpdateChargePointInput>(
        updateChargePointQuery
    )
}
