import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import { useTranslation } from 'react-i18next'
import style from './ProvisioningActions.module.scss'
import useDeviceAdoption from 'controller/hooks/useDeviceAdoption'

interface DeviceAdoptingModalProps {
    show: boolean
    close: () => void
    serialno: string
}

export default function DeviceAssignmentModal({ show, close, serialno }: DeviceAdoptingModalProps) {
    const { t } = useTranslation()
    const [adoptionResult, adoptDevice, reset] = useDeviceAdoption()

    const handleClose = () => {
        if (adoptionResult.status === 'success' || adoptionResult.status === 'error') {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (adoptionResult.status) {
            case 'idle':
                return (
                    <>
                        {t('adoptDeviceConfirmModal', { serialno })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                adoptDevice({ serialno })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to adopt the device: ${adoptionResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {adoptionResult.data.adoptDevice && adoptionResult.data.adoptDevice.message ? (
                            <span className={style.actionResultSuccess}>
                                {adoptionResult.data.adoptDevice.message}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to adopt device {serialno}.
                            </span>
                        )}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('adoptDeviceModalTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
