import { useGraphQlMutation } from 'common'
import { NetType } from 'config/types/controllerConfig'

const createClusterQuery = `
    mutation CreateCluster(
        $controllerId: Int!
        $clusterId: String!
        $parentId: Int
        $fuseLimit: Float!
        $headroom: Float!
        $netType: NetType!
    ) {
        createCluster(
            controllerId: $controllerId
            clusterId: $clusterId
            parentId: $parentId
            fuseLimit: $fuseLimit
            headroom: $headroom
            netType: $netType
        ) {
            id
            clusterId
            controllerId
            fuseLimit
            headroom
            netType
            parentId
        }
    }
`

interface CreateClusterInput {
    controllerId: number
    clusterId: string
    parentId: number | null
    fuseLimit: number
    headroom: number
    netType: NetType
}

interface CreateClusterResponse {
    createCluster: {
        id: number
        clusterId: string
        controllerId: number
        fuseLimit: number
        headroom: number
        netType: NetType
        parentId: number | null
    }
}

export default function useCreateCluster() {
    return useGraphQlMutation<CreateClusterResponse, CreateClusterInput>(createClusterQuery)
}
