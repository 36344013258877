import { chargePriorityValues } from 'chargePoint/types/chargePoint'
import { ValidatedSelectField, ValidatedTextField } from 'common'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useCreateConnector from 'config/hooks/useCreateConnector'
import useUpdateConnector from 'config/hooks/useUpdateConnector'
import {
    ChargePointConfig,
    ConnectorConfig,
    ControllerConfigComposite,
} from 'config/types/controllerConfig'
import swapListItem from 'config/utils/swapListElement'
import { integerValidator, numberValidator, stringNonEmptyValidator } from 'config/utils/validators'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'
import TrashCanButton from '../TrashCanButton/TrashCanButton'
import style from './ConnectorConfigList.module.scss'
import DeleteConnectorModal from './DeleteConnectorModal'

interface ConnectorConfigItemProps {
    connector: ConnectorConfig
    chargePoint: ChargePointConfig
}

export default function ConnectorConfigItem({ connector, chargePoint }: ConnectorConfigItemProps) {
    const isNew = !Number.isInteger(connector.id)
    const { t } = useTranslation()
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [_, setControllerConfig] = useControllerConfigState()

    const dispatchPopup = usePopupModal()

    const connectorIdField = useValidatedField(connector.connectorId.toString(), integerValidator)
    const minAmpereField = useValidatedField(connector.minAmpere.toString(), numberValidator)
    const maxAmpereField = useValidatedField(connector.maxAmpere.toString(), numberValidator)
    const phaseMappingField = useValidatedField(connector.phaseMapping, stringNonEmptyValidator)
    const priorityField = useValidatedField(connector.priority, stringNonEmptyValidator)

    const [createConnectorState, executeCreateConnector] = useCreateConnector()
    const [updateConnectorState, executeUpdateConnector] = useUpdateConnector()

    const connectorFields = [
        connectorIdField,
        minAmpereField,
        maxAmpereField,
        phaseMappingField,
        priorityField,
    ]

    const allValid = connectorFields.every((field) => field.isValid)
    const anyModified = connectorFields.some((field) => field.isModified)

    const isSaving =
        createConnectorState.status === 'loading' || updateConnectorState.status === 'loading'

    const saveEnabled = allValid && anyModified && !isSaving

    const resetFields = () => {
        connectorFields.forEach((field) => field.reset())
    }

    const handleDelete = () => {
        if (isNew) {
            setControllerConfig((prev) => {
                const newConnectors = chargePoint.connectors.filter((x) => x !== connector)
                const newChargePoint = { ...chargePoint, connectors: newConnectors }

                return {
                    ...prev,
                    chargePoints: swapListItem(prev.chargePoints, chargePoint, newChargePoint),
                }
            })
        } else {
            setShowDeleteModal(true)
        }
    }

    const handleSave = () => {
        if (isNew) {
            executeCreateConnector({
                chargePointId: connector.chargePointId,
                connectorId: Number(connectorIdField.value),
                minAmpere: Number(minAmpereField.value),
                maxAmpere: Number(maxAmpereField.value),
                phaseMapping: phaseMappingField.value,
                priority: priorityField.value,
            })
                .then((res) => {
                    if (Number.isInteger(res.data?.createConnector.chargePointId)) {
                        setControllerConfig((prev) => ({
                            ...replaceConnector(prev, connector, res.data!.createConnector),
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when creating connector',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) =>
                    dispatchPopup({ title: 'Failed to save connector', message: err.toString() })
                )
        } else {
            executeUpdateConnector({
                id: connector.id!,
                connectorId: Number(connectorIdField.value),
                minAmpere: Number(minAmpereField.value),
                maxAmpere: Number(maxAmpereField.value),
                phaseMapping: phaseMappingField.value,
                priority: priorityField.value,
            })
                .then((res) => {
                    if (Number.isInteger(res.data?.updateConnector.chargePointId)) {
                        setControllerConfig((prev) => ({
                            ...replaceConnector(prev, connector, res.data!.updateConnector),
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when updating connector',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) =>
                    dispatchPopup({ title: 'Failed to update connector', message: err.toString() })
                )
        }
    }

    return (
        <div className={style.item}>
            <ValidatedTextField
                field={connectorIdField}
                title={t('controllerConfigPageConnectorFieldConnectorId')}
                className={style.field}
                forceModified={isNew}
            />
            <ValidatedTextField
                field={minAmpereField}
                title={t('controllerConfigPageConnectorFieldMinAmpere')}
                className={style.field}
                forceModified={isNew}
            />
            <ValidatedTextField
                field={maxAmpereField}
                title={t('controllerConfigPageConnectorFieldMaxAmpere')}
                className={style.field}
                forceModified={isNew}
            />
            <ValidatedTextField
                field={phaseMappingField}
                title={t('controllerConfigPageConnectorFieldPhaseMapping')}
                className={style.field}
                forceModified={isNew}
            />
            <ValidatedSelectField
                field={priorityField}
                title={t('controllerConfigPageConnectorFieldPriority')}
                options={chargePriorityValues.map((x) => ({ value: x }))}
                className={style.field}
                forceModified={isNew}
            />
            <div className={style.buttonContainer}>
                <SaveButton
                    text={t('controllerConfigPageConnectorSave')}
                    click={handleSave}
                    disabled={!saveEnabled}
                    loading={isSaving}
                />
                <TrashCanButton className={style.trashCanButton} onClick={handleDelete} />
            </div>
            <DeleteConnectorModal
                connector={connector}
                chargePoint={chargePoint}
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </div>
    )
}

function replaceConnector(
    prev: ControllerConfigComposite,
    prevConnector: ConnectorConfig,
    newConnector: ConnectorConfig
): ControllerConfigComposite {
    const oldChargePoint = prev.chargePoints.find((x) => x.id === prevConnector.chargePointId)!
    const newChargePoint = {
        ...oldChargePoint,
        connectors: swapListItem(oldChargePoint.connectors, prevConnector, newConnector),
    }
    return {
        ...prev,
        chargePoints: swapListItem(prev.chargePoints, oldChargePoint, newChargePoint),
    }
}
