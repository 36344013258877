import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import useRebootController from 'controller/hooks/useRebootController'
import { useTranslation } from 'react-i18next'

import style from './ControllerActions.module.scss'

interface RebootModalProps {
    show: boolean
    close: () => void
    controllerId: string
}

export default function RebootModal({ show, close, controllerId }: RebootModalProps) {
    const { t } = useTranslation()
    const [rebootResult, rebootController, reset] = useRebootController()

    const handleClose = () => {
        if (rebootResult.status === 'success' || rebootResult.status === 'error') {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (rebootResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerActionsRebootConfirmModal', { controllerId })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                rebootController({ deviceId: controllerId })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to reboot controller: ${rebootResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {rebootResult.data.rebootController ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerActionsRebootSuccess')}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to reboot controller
                            </span>
                        )}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={close} className={style.modal}>
            <h2>{t('controllerActionsRebootTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
