import {
    ChartData,
    dataKeyComparator,
    FetchFunction,
    Loadable,
    mapSuccess,
    roundToBinSize,
    toSecondsIsoString,
} from 'common'
import { RawChartData } from 'common/types/chartTypes'
import createChartData from 'common/utils/createChartData'
import unique from 'just-unique'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface ZoomableTimeSeriesReturnValue {
    chartData: Loadable<ChartData>
    setChartBounds: (startDate: string, endDate: string) => void
    resetChartBounds: () => void
}

export default function useZoomableTimeSeries<T>(
    fetchFunction: FetchFunction<T>,
    dataMapper: (data: T) => RawChartData,
    startDateLimit: string,
    endDateLimit: string
): ZoomableTimeSeriesReturnValue {
    const lowresBinSize = getBinSize(
        new Date(endDateLimit).getTime() - new Date(startDateLimit).getTime()
    )
    const [lowresChartData, setLowresChartData] = useState<Loadable<T>>(() => ({
        status: 'loading',
    }))

    const [currentChartData, setCurrentChartData] = useState<Loadable<T>>(() => ({
        status: 'loading',
    }))

    const [currentStartDate, setCurrentStartDate] = useState<string>(startDateLimit)
    const [currentEndDate, setCurrentEndDate] = useState<string>(endDateLimit)
    const [currentBinSize, setCurrentBinSize] = useState<number>(lowresBinSize)
    const [rootDataKeys, setRootDataKeys] = useState<string[]>([])

    const requestNumberRef = useRef<number>(0)

    useEffect(() => {
        setLowresChartData({ status: 'loading' })
        setCurrentChartData({ status: 'loading' })
        setCurrentBinSize(lowresBinSize)
        setCurrentStartDate(startDateLimit)
        setCurrentEndDate(endDateLimit)

        fetchFunction(startDateLimit, endDateLimit, lowresBinSize)
            .then((res) => {
                setLowresChartData({ status: 'success', data: res })
                setCurrentChartData({ status: 'success', data: res })
                setRootDataKeys(dataMapper(res).dataKeys)
            })
            .catch((err) => {
                setLowresChartData({ status: 'error', error: err })
                setCurrentChartData({ status: 'error', error: err })
            })
        // Don't rerun effect when dataMapper changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lowresBinSize, startDateLimit, endDateLimit, fetchFunction])

    const setChartBounds = useCallback(
        (startDate: string, endDate: string) => {
            const requestNumber = ++requestNumberRef.current
            const startTimestamp = new Date(startDate).getTime()
            const endTimestamp = new Date(endDate).getTime()
            const binSize = getBinSize(endTimestamp - startTimestamp)

            const newStartTimeIso = toSecondsIsoString(
                new Date(roundToBinSize(startTimestamp, binSize, 'floor'))
            )
            const newEndTimeIso = toSecondsIsoString(
                new Date(roundToBinSize(endTimestamp, binSize, 'ceil'))
            )

            const isNewBoundsSmaller =
                newStartTimeIso >= currentStartDate && newEndTimeIso <= currentEndDate

            const mappedState = isNewBoundsSmaller ? currentChartData : lowresChartData

            setCurrentChartData(mappedState)
            setCurrentStartDate(newStartTimeIso)
            setCurrentEndDate(newEndTimeIso)

            if (binSize === currentBinSize && isNewBoundsSmaller) {
                // We already have the data in the correct and don't need to refetch
                return
            }

            fetchFunction(newStartTimeIso, newEndTimeIso, binSize).then((res) => {
                if (requestNumber !== requestNumberRef.current) {
                    // This is the response of an old request. Ignore
                    return
                }
                setCurrentChartData({ status: 'success', data: res })
                setCurrentStartDate(newStartTimeIso)
                setCurrentEndDate(newEndTimeIso)
                setCurrentBinSize(binSize)
            })
        },
        [
            lowresChartData,
            currentChartData,
            currentStartDate,
            currentEndDate,
            currentBinSize,
            fetchFunction,
        ]
    )

    const resetChartBounds = useCallback(() => {
        setCurrentBinSize(lowresBinSize)
        setCurrentChartData(lowresChartData)
        setCurrentStartDate(startDateLimit)
        setCurrentEndDate(endDateLimit)
    }, [lowresBinSize, lowresChartData, startDateLimit, endDateLimit])

    const chartData = useMemo(
        () =>
            mapSuccess(currentChartData, (data) => {
                const newChartData = createChartData(
                    currentStartDate,
                    currentEndDate,
                    currentBinSize,
                    dataMapper(data)
                )

                let dataKeys = rootDataKeys

                if (!dataKeysEquals(newChartData.dataKeys, dataKeys)) {
                    if (newChartData.dataKeys.every((x) => dataKeys.includes(x))) {
                        // New data keys is a subset. Chart is likely zoomed in.
                        dataKeys = unique([...newChartData.dataKeys, ...rootDataKeys]).sort(
                            dataKeyComparator
                        )
                        if (!dataKeysEquals(dataKeys, rootDataKeys)) {
                            setRootDataKeys(dataKeys)
                        }
                    } else {
                        // Data keys not a subset. It's likely that the dataMapper changed.
                        dataKeys = newChartData.dataKeys
                        setRootDataKeys(dataKeys)
                    }
                }
                return { ...newChartData, dataKeys }
            }),
        [
            currentChartData,
            currentStartDate,
            currentEndDate,
            currentBinSize,
            dataMapper,
            rootDataKeys,
        ]
    )

    return {
        chartData,
        setChartBounds,
        resetChartBounds,
    }
}

function getBinSize(timespanLength: number): number {
    const days = timespanLength / (1000 * 3600 * 24)

    if (days < 1) return 1
    if (days < 5) return 5
    if (days < 10) return 15
    return 60
}

const dataKeysEquals = (a: string[], b: string[]) =>
    a.length === b.length && a.every((value, i) => value === b[i])
