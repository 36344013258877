import { Loadable, useConnectorLoadFetchFunction } from 'common'
import sortInPlaceByKey from 'common/utils/sortInPlaceByKey'
import moment from 'moment'
import { useEffect, useState } from 'react'

interface ConnectorLoadDatum {
    connectorId: string
    phases: number[]
    date: string
}

export default function useCurrentConnectorLoad(controllerId: string) {
    const queryFn = useConnectorLoadFetchFunction(controllerId)

    const [req, setReq] = useState<Loadable<ConnectorLoadDatum[]>>({ status: 'loading' })

    useEffect(() => {
        queryFn(moment().subtract(1, 'hour').toISOString(), moment().add(1, 'day').toISOString(), 1)
            .then((res) =>
                setReq({
                    status: 'success',
                    data: res.connectors.flatMap((connector) => {
                        const latestRow = sortInPlaceByKey(connector.data, (x) => x[0]).at(-1)
                        if (!latestRow) {
                            return []
                        }
                        return [
                            {
                                connectorId: connector.connectorId,
                                phases: latestRow.slice(2, 5) as number[],
                                date: latestRow[0],
                            },
                        ]
                    }),
                })
            )
            .catch((err) => setReq({ status: 'error', error: err }))
    }, [queryFn])

    return req
}
