import { ChartData, Datum, RawChartData } from 'common/types/chartTypes'
import groupBy from 'just-group-by'
import generateTimestampSequence from './generateTimestampSequence'
import toSecondsIsoString from './toSecondsIsoString'

export default function createChartData(
    startDate: string,
    endDate: string,
    binSizeInMinutes: number,
    rawChartData: RawChartData
): ChartData {
    const timestamps = generateTimestampSequence(startDate, endDate, binSizeInMinutes).map(
        toSecondsIsoString
    )
    const byTimestamp = groupBy(rawChartData.records, (record) =>
        toSecondsIsoString(new Date(record[0]))
    )

    const dataPoints: Datum[] = timestamps.map((timestamp) => ({
        date: timestamp,
        lines: Object.fromEntries(
            byTimestamp[timestamp]
                ?.filter((record) => record[2] != null)
                .map((record) => [record[1], { x: timestamp, y: record[2] }]) || []
        ),
    }))

    return {
        dataKeys: rawChartData.dataKeys,
        dataPoints,
    }
}
