import { Breadcrumb, ControllerPageLink, CurrentPage, OverviewPageLink, Spinner } from 'common'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useAvailableFirmware from 'firmware/hooks/useAvailableFirmware'
import useControllerFirmware from 'firmware/hooks/useAvailableFirmwareForController'
import FirmwareList from 'firmware/components/FirmwareList'
import useRemoveFirmware from 'firmware/hooks/useRemoveFirmware'
import useRequestFirmwareDownload from 'firmware/hooks/useRequestFirmwareDownload'
import { FirmwareManifest } from 'firmware/types/firmwaremanifest'
import { useEffect, useState } from 'react'

import { ReactComponent as CloudUploadIcon } from 'img/cloud-upload.svg'
import { ReactComponent as MinusCircleOutlineIcon } from 'img/minus-circle-outline.svg'
import { ReactComponent as ChipIcon } from 'img/chip.svg'
import UploadFirmwareModal from 'firmware/components/UploadFirmwareModal'
import useControllerData from 'controller/hooks/useControllerData'
import { ChargePoint } from 'chargePoint'

export default function ControllerFirmwarePage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }

    const { t } = useTranslation()

    const controllerDataLoadable = useControllerData(controllerId)
    const [controllerFirmware, refreshControllerFirmware] = useControllerFirmware(controllerId)
    const [backendFirmware] = useAvailableFirmware()

    const removeFirmware = useRemoveFirmware()[1]
    const downloadFirmware = useRequestFirmwareDownload()[1]

    const [currentlyDownloading, setCurrentlyDownloading] = useState<Record<string, boolean>>({})

    useEffect(() => {
        if (controllerFirmware.status === 'success') {
            for (const fw of controllerFirmware.data) {
                if (currentlyDownloading[fw.filename]) {
                    setCurrentlyDownloading((prev) => ({ ...prev, [fw.filename]: false }))
                }
            }
        }
    }, [currentlyDownloading, controllerFirmware])

    function firmwareExistsOnController(fw: FirmwareManifest) {
        if (controllerFirmware.status !== 'success') {
            return false
        }

        return controllerFirmware.data.some((controllerFw) => controllerFw.filename === fw.filename)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (Object.values(currentlyDownloading).some((v) => v)) {
                refreshControllerFirmware()
            }
        }, 2500)

        return () => clearInterval(interval)
    }, [currentlyDownloading, refreshControllerFirmware])

    const [showUploadModal, setShowUploadModal] = useState(false)
    const [selectedFirmware, setSelectedFirmware] = useState<FirmwareManifest | undefined>(
        undefined
    )

    function setDownloadStatus(fw: FirmwareManifest, status: boolean) {
        setCurrentlyDownloading((prev) => ({ ...prev, [fw.filename]: status }))
    }

    function downloadClickHandler(fw: FirmwareManifest) {
        setDownloadStatus(fw, true)

        downloadFirmware({ id: controllerId, fw: fw.filename })
            .then((res) => {
                switch (res.data?.requestFirmwareDownload) {
                    case 'DOWNLOAD_STARTED':
                        break
                    case 'ALREADY_EXISTS':
                        refreshControllerFirmware()
                        setDownloadStatus(fw, false)
                        break
                    default:
                        console.error(res.error)
                        setDownloadStatus(fw, false)
                }
            })
            .catch((error) => {
                console.error(error)
                setDownloadStatus(fw, false)
            })
    }

    const chargePoints =
        controllerDataLoadable.status === 'success' ? controllerDataLoadable.data.chargePoints : []

    return (
        <>
            <Breadcrumb>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <CurrentPage currentPage={t('controllerFirmwareTitle')} />
            </Breadcrumb>

            <div className="grid gap-8 mt-4">
                <FirmwareList
                    title={t('availableFirmwareOnController')}
                    firmwareManifests={controllerFirmware}
                    actions={(fw) => {
                        const availableUpgrades = chargePoints.filter(
                            (chargePoint: ChargePoint) =>
                                chargePoint?.vendor === fw?.vendor &&
                                chargePoint?.model === fw?.model &&
                                fw.validUpgradeVersions.includes(chargePoint?.firmwareVersion ?? '')
                        )

                        return (
                            <div className="flex flex-row justify-end gap-4">
                                <div
                                    className={
                                        availableUpgrades.length > 0
                                            ? 'font-semibold px-2.5 bg-emerald-100 rounded-2xl text-emerald-500'
                                            : 'font-semibold px-2.5 bg-gray-100 rounded-2xl text-gray-500'
                                    }
                                    title={t('chargePointsAvailableForUpgrade')}
                                >
                                    {availableUpgrades.length}
                                </div>
                                <button
                                    onClick={() => {
                                        setSelectedFirmware(fw)
                                        setShowUploadModal(true)
                                    }}
                                    title={t('showUpgradeOptions')}
                                >
                                    <ChipIcon className="w-6 fill-gray-500 hover:fill-blue-400" />
                                </button>
                                <button
                                    onClick={() => {
                                        removeFirmware({ id: controllerId, fw: fw.filename })
                                            .then(() => {
                                                refreshControllerFirmware()
                                            })
                                            .catch((err) => console.error(err))
                                    }}
                                    title={t('deleteFirmwareFromController')}
                                >
                                    <MinusCircleOutlineIcon className="w-6 fill-gray-500 hover:fill-red-400" />
                                </button>
                            </div>
                        )
                    }}
                />
                <FirmwareList
                    title={t('availableFirmwareInBackend')}
                    firmwareManifests={backendFirmware}
                    actions={(fw) => {
                        const disabled =
                            firmwareExistsOnController(fw) || currentlyDownloading[fw.filename]

                        const availableUpgrades = chargePoints.filter(
                            (chargePoint: ChargePoint) =>
                                chargePoint?.vendor === fw?.vendor &&
                                chargePoint?.model === fw?.model &&
                                fw.validUpgradeVersions.includes(chargePoint?.firmwareVersion ?? '')
                        )

                        return (
                            <div className="flex flex-row justify-end gap-4">
                                <div
                                    className={
                                        availableUpgrades.length > 0
                                            ? 'font-semibold px-2.5 bg-emerald-100 rounded-2xl text-emerald-500'
                                            : 'font-semibold px-2.5 bg-gray-100 rounded-2xl text-gray-500'
                                    }
                                    title={t('chargePointsAvailableForUpgrade')}
                                >
                                    {availableUpgrades.length}
                                </div>
                                <button
                                    disabled={disabled}
                                    onClick={() => downloadClickHandler(fw)}
                                    title={
                                        disabled
                                            ? t('firmwareAlreadyExistsOnController')
                                            : t('downloadFirmwareToController')
                                    }
                                >
                                    {currentlyDownloading[fw.filename] ? (
                                        <Spinner size={16} />
                                    ) : (
                                        <CloudUploadIcon
                                            className={
                                                disabled
                                                    ? 'w-6 fill-gray-200'
                                                    : 'w-6 fill-gray-500 hover:fill-blue-400'
                                            }
                                        />
                                    )}
                                </button>
                            </div>
                        )
                    }}
                />
            </div>
            <UploadFirmwareModal
                show={showUploadModal}
                onClickOutside={() => {
                    setShowUploadModal(false)
                    setSelectedFirmware(undefined)
                }}
                firmwareManifest={selectedFirmware}
                controllerId={controllerId}
                chargePoints={chargePoints}
            />
        </>
    )
}
