import { IconButton, Spinner } from 'common'
import { ReactComponent as SaveIcon } from 'img/save.svg'
import style from './SaveButton.module.scss'

interface SaveButtonProps {
    text?: string
    title?: string
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    click: () => void
    className?: string
    loading?: boolean
    disabled?: boolean
}

export default function SaveButton({
    text,
    title,
    icon,
    click,
    className,
    loading,
    disabled,
}: SaveButtonProps) {
    return (
        <div className={style.container}>
            <IconButton
                type="primary"
                text={text}
                title={title}
                icon={icon || SaveIcon}
                click={click}
                disabled={disabled}
                className={className}
            />
            {loading && <Spinner className={style.spinner} size={30} />}
        </div>
    )
}
