import { useGraphQlMutation } from 'common'

const updateLocationQuery = `
    mutation UpdateLocation(
        $id: Int!
        $name: String!
    ) {
        updateLocation(
            id: $id
            name: $name
        ) {
            id
            name
        }
    }
`

interface UpdateLocationInput {
    id: number
    name: string
}

interface UpdateLocationResponse {
    updateLocation: {
        id: number
        name: string
    }
}

export default function useUpdateLocation() {
    return useGraphQlMutation<UpdateLocationResponse, UpdateLocationInput>(updateLocationQuery)
}
