import { ValidatedSelectField } from 'common'
import useValidatedField from 'common/hooks/useValidatedField'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface NetworkLocationProps {
    setNetworkLocation: (networkLocation: string | undefined) => void
    chargePointSetupRow?: ChargePointSetupRow
    chargePointSetupRows: ChargePointSetupRow[]
    networkLocation: string | undefined
}

const numberOptions = [
    { value: '-' },
    ...Array.from(Array(20).keys()).map((i) => ({ value: (i + 1).toString() })),
]

function assembleNetworkLocation(
    switchField: string,
    portField: string,
    chargerField: string
): string {
    return `${switchField}-${portField}-${chargerField}`
}

export function NetworkLocation({
    networkLocation,
    chargePointSetupRow,
    chargePointSetupRows,
    setNetworkLocation,
}: NetworkLocationProps) {
    const { t } = useTranslation()

    const [initialSwitch, initialLoop, initialCharger] = networkLocation
        ? networkLocation.split('-')
        : ['-', '-', '-']

    const switchField = useValidatedField(initialSwitch, (v) => v !== '-')
    const portField = useValidatedField(initialLoop, (v) => v !== '-')
    const chargerField = useValidatedField(initialCharger, (v) => v !== '-')

    const isDuplicate = chargePointSetupRows
        .filter((row) => row !== chargePointSetupRow)
        .map((row) => row.networkLocation)
        .includes(assembleNetworkLocation(switchField.value, portField.value, chargerField.value))

    useEffect(() => {
        if (isDuplicate) {
            setNetworkLocation(undefined)
        } else if (switchField.value && portField.value && chargerField.value) {
            const newNetworkLocation = assembleNetworkLocation(
                switchField.value,
                portField.value,
                chargerField.value
            )
            setNetworkLocation(newNetworkLocation)
        }
    }, [switchField.value, portField.value, chargerField.value, isDuplicate, setNetworkLocation])

    return (
        <div className="grid grid-cols-3 gap-4 w-full">
            {[
                { title: t('switch'), field: switchField },
                { title: t('port'), field: portField },
                { title: t('charger'), field: chargerField },
            ].map(({ title, field }) => (
                <Fragment key={title}>
                    <ValidatedSelectField
                        className="col-span-1 w-full"
                        classNameInner="w-full py-4 sm:py-2 text-xl sm:text-base"
                        title={title}
                        options={numberOptions}
                        field={field}
                    />
                </Fragment>
            ))}
            {isDuplicate && (
                <div className="col-span-3 text-red-500 text-xl sm:text-base">
                    {t('duplicateNetworkLocation')}
                </div>
            )}
        </div>
    )
}
