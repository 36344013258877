import { useGraphQlMutation } from 'common'

const deleteControllerConfigMutation = `
    mutation DeleteControllerConfig($id: Int!) {
        deleteControllerConfig(id: $id)
    }
`

interface DeleteControllerConfigInput {
    id: number
}

interface DeleteControllerConfigResponse {
    deleteControllerConfig: boolean
}

export default function useDeleteControllerConfig() {
    return useGraphQlMutation<DeleteControllerConfigResponse, DeleteControllerConfigInput>(
        deleteControllerConfigMutation
    )
}
