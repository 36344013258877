import { useState } from 'react'

export interface ValidatedField<T> {
    value: T
    isModified: boolean
    isValid: boolean
    setValue: (value: T) => void
    initialValue: T
    resetInitialValue: (value: T) => void
    validator: (value: T) => boolean
    reset: () => void
}

export default function useValidatedField<T>(
    initialValue: T,
    validator: (value: T) => boolean,
    onChange?: (value: T) => void
): ValidatedField<T> {
    const [value, setValueInternal] = useState<T>(initialValue)
    const setValue = (value: T) => {
        setValueInternal(value)
        onChange && onChange(value)
    }
    const [unmodifiedValue, setUnmodifiedValue] = useState(initialValue)

    const resetInitialValue = (value: T) => {
        setValue(value)
        setUnmodifiedValue(value)
    }

    const reset = () => setUnmodifiedValue(value)
    return {
        value,
        setValue,
        isModified: value !== unmodifiedValue,
        isValid: validator(value),
        resetInitialValue,
        initialValue: unmodifiedValue,
        validator,
        reset,
    }
}
