import { useGraphQlMutation } from 'common'

const powerOffControllerQuery = `
    mutation PowerOffController($deviceId: String!) {
        powerOffController(deviceId: $deviceId)
    }
`

interface PowerOffControllerInput {
    deviceId: string
}

interface PowerOffControllerResponse {
    powerOffController?: boolean
}

export default function usePowerOffController() {
    return useGraphQlMutation<PowerOffControllerResponse, PowerOffControllerInput>(
        powerOffControllerQuery
    )
}
