import { Button, Modal } from 'common'
import { useTranslation } from 'react-i18next'
import style from './PopupModal.module.scss'
import { PopupModalMessage } from 'common/hooks/usePopupModal'
import classNames from 'classnames'
import { ReactComponent as AlertCircleIcon } from 'img/alert-circle-outline.svg'

interface PopupModalProps {
    show: boolean
    close: () => void
    message: PopupModalMessage
}

export default function PopupModal({ show, close, message }: PopupModalProps) {
    const { t } = useTranslation()

    const iconClasses = classNames(style.icon, {
        [style.error]: message.type === 'error' || message.type === undefined,
        [style.info]: message.type === 'info',
    })

    const modalClasses = classNames(style.modal, {
        [style.error]: message.type === 'error' || message.type === undefined,
        [style.info]: message.type === 'info',
    })

    return (
        <Modal show={show} onClickOutside={close} className={modalClasses}>
            {message.title && (
                <h2>
                    <AlertCircleIcon className={iconClasses} />
                    {message.title}
                </h2>
            )}
            <p>{message.message}</p>
            <Button className={style.closeButton} type="primary" click={close}>
                {t('modalClose')}
            </Button>
        </Modal>
    )
}
