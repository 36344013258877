export default function roundToBinSize(
    epochMillis: number,
    binSizeMinutes: number,
    roundType: 'round' | 'floor' | 'ceil' = 'round'
) {
    const granularityMillis = binSizeMinutes * 60000

    if (roundType === 'round') {
        return Math.round(epochMillis / granularityMillis) * granularityMillis
    } else if (roundType === 'floor') {
        return Math.floor(epochMillis / granularityMillis) * granularityMillis
    }
    return Math.ceil(epochMillis / granularityMillis) * granularityMillis
}
