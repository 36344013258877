// Copied from https://usehooks.com/useLocalStorage/

import { useCallback, useEffect, useState } from 'react'

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }

        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return initialValue
        }
    })

    // The original implementation from usehooks.com didn't behave correctly when using update function
    // This hack fixes it
    const [saveTrigger, setSaveTrigger] = useState(0)

    useEffect(() => {
        if (saveTrigger > 0) {
            try {
                // Save to local storage
                if (typeof window !== 'undefined') {
                    window.localStorage.setItem(key, JSON.stringify(storedValue))
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveTrigger])

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.

    const setValue: typeof setStoredValue = useCallback((value) => {
        setStoredValue(value)
        setSaveTrigger((c) => c + 1)
    }, [])

    return [storedValue, setValue] as const
}
