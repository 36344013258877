import { PopupModalContext, PopupModalMessage } from 'common/hooks/usePopupModal'
import { useState } from 'react'
import PopupModal from './components/PopupModal/PopupModal'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import style from './MainLayout.module.scss'

interface MainLayoutProps {
    children?: React.ReactNode
}

export default function MainLayout({ children }: MainLayoutProps): JSX.Element {
    const [popupMessages, setPopupMessages] = useState<PopupModalMessage[]>([])
    return (
        <PopupModalContext.Provider
            value={(newMessage) => setPopupMessages((messages) => [...messages, newMessage])}
        >
            <div className={style.pageWrapper}>
                <Header />
                <div className={style.content}>{children}</div>
                <Footer />
                {popupMessages.length > 0 && (
                    <PopupModal
                        show={popupMessages.length > 0}
                        close={() => setPopupMessages((messages) => messages.slice(0, -1))}
                        message={popupMessages.at(-1)!}
                    />
                )}
            </div>
        </PopupModalContext.Provider>
    )
}
