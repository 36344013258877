import useInitiateChargePoints, {
    ChargePointInitiationInput,
    PartialChargePointInitiationInput,
} from 'chargePoint/hooks/useInitiateChargePoints'
import { Button } from 'common'
import usePopupModal from 'common/hooks/usePopupModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type InitializeChargePointsProps = {
    deviceId: string
    chargePointsToInitiate: PartialChargePointInitiationInput[]
    disabled?: boolean
}

export function InitiateChargePoints({
    deviceId,
    chargePointsToInitiate,
    disabled,
}: InitializeChargePointsProps) {
    const { t } = useTranslation()
    const [initiateChargePointsState, initiateChargePoints] = useInitiateChargePoints()
    const dispatchPopup = usePopupModal()

    const [maxAmpere, setMaxAmpere] = useState(32)
    const [disableAuthentication, setDisableAuthentication] = useState(false)

    return (
        <div className="flex flex-col align-middle justify-center bg-white border border-gray-200">
            <div className="flex justify-center items-center h-16 border-b border-gray-50">
                <div className="font-semibold text-sm text-gray-500">
                    {t('initiateChargePoints')}
                </div>
            </div>
            <div id="init-options" className="flex flex-col p-2 gap-2">
                <div className="flex flex-col gap-4">
                    <div className="block w-full px-1">
                        <label htmlFor="max-ampere" className="text-xs text-gray-500 mb-2">
                            {t('maxAmpere')}
                        </label>
                        <input
                            type="number"
                            id="max-ampere"
                            name="max-ampere"
                            min="0"
                            max="9999"
                            step="1"
                            value={maxAmpere}
                            onChange={(e) => setMaxAmpere(parseInt(e.target.value))}
                            disabled={disabled}
                            className="ml-4 w-24"
                        />
                    </div>
                    <div className="block px-1">
                        <label htmlFor="disable-auth" className="text-xs text-gray-500 mb-2">
                            {t('disableAuthentication')}
                        </label>
                        <input
                            type="checkbox"
                            id="disable-auth"
                            name="disable-auth"
                            onChange={(e) => setDisableAuthentication(e.target.checked)}
                            disabled={disabled}
                            className="ml-4 w-auto"
                        />
                    </div>
                </div>
                <Button
                    click={() => {
                        initiateChargePoints({
                            id: deviceId,
                            setup: chargePointsToInitiate.map((cp) => ({
                                ...cp,
                                maxAmpere,
                                disableAuthentication,
                            })) as ChargePointInitiationInput[],
                        })
                            .then((res) => {
                                if (res.error || !res.data) {
                                    dispatchPopup({
                                        type: 'error',
                                        title: t('initiateChargePointsError'),
                                        message: res.error?.toString() ?? '',
                                    })
                                } else {
                                    dispatchPopup({
                                        type: 'info',
                                        title: t('initiateChargePointsSuccess'),
                                        message: t('initiateChargePointsSuccessMessage'),
                                    })
                                }
                            })
                            .catch((err) => {
                                dispatchPopup({
                                    type: 'error',
                                    title: t('initiateChargePointsError'),
                                    message: err.toString(),
                                })
                            })
                    }}
                    disabled={
                        disabled ||
                        chargePointsToInitiate.length === 0 ||
                        initiateChargePointsState.status === 'loading'
                    }
                    type="primary"
                    dense
                >
                    {t('initiateChargePoints')}
                </Button>
            </div>
        </div>
    )
}
