import { DelayedLoadable } from 'common'
import { useCallback, useState } from 'react'
import { AnyVariables, useMutation } from 'urql'

export default function useGraphQlMutation<TResult, TQueryParams extends AnyVariables>(
    query: string
) {
    const [requestState, setRequestState] = useState<DelayedLoadable<TResult>>(() => ({
        status: 'idle',
    }))

    const [_, executeMutation] = useMutation<TResult, TQueryParams>(query)

    const wrappedExecuteMutation = useCallback<typeof executeMutation>(
        (variables, context) => {
            setRequestState({ status: 'loading' })
            return executeMutation(variables, context).then((res) => {
                if (res.error) {
                    setRequestState({ status: 'error', error: res.error })
                    throw res.error
                } else {
                    setRequestState({ status: 'success', data: res.data! })
                    return res
                }
            })
        },
        [executeMutation]
    )

    const reset = useCallback(
        () => requestState.status !== 'idle' && setRequestState({ status: 'idle' }),
        [setRequestState, requestState]
    )

    return [requestState, wrappedExecuteMutation, reset] as const
}
