import React, { useContext } from 'react'

export interface PopupModalMessage {
    title?: string
    message: string
    type?: 'error' | 'info'
}

export const PopupModalContext = React.createContext<(message: PopupModalMessage) => void>(
    null as any
)

export default function usePopupModal() {
    return useContext(PopupModalContext)
}
