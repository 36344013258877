import axios from 'axios'
import { useAxios } from 'common/hooks/useApi'
import { ScanInput, ScanResponse } from 'controller/hooks/useScan'

/*
    Functionally equivalent to useScan, but with two differences:
    
    1. Uses Axios instead of GraphQL
    2. Takes a controllerId as a parameter instead of a deviceId.
    
    The first is to make it available to electricians who are not logged in.
    The latter is required as the deviceId is not known in the electrician view.
*/

export default function useElectricianViewScan(controllerId: string, accessToken?: string) {
    const getAuthenticatedAxiosInstance = useAxios()

    return async (params: ScanInput) => {
        const axiosClient = accessToken
            ? axios.create({ baseURL: process.env.REACT_APP_BACKEND_API_URL })
            : await getAuthenticatedAxiosInstance()

        const path =
            `/api/charge-point-setup/${controllerId}/scan` + (accessToken ? `/${accessToken}` : '')

        return axiosClient.post(path, null, { params }).then((res) => res.data as ScanResponse[])
    }
}
