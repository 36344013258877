import { InteractionRequiredAuthError } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import axios, { AxiosInstance } from 'axios'
import { Loadable } from 'common'
import { useCallback, useEffect, useState } from 'react'

export function useApiGet<T>(path: string): Loadable<T> {
    const { instance: msalInstance, accounts: msalAccounts } = useMsal()
    const [requestState, setRequestState] = useState<Loadable<T>>(() => ({
        status: 'loading',
    }))

    useEffect(() => {
        const msalArgs = {
            scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
            account: msalAccounts[0],
        }

        msalInstance
            .acquireTokenSilent(msalArgs)
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(msalArgs)
                }
                throw error
            })
            .then((res) =>
                axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/api/${path}`, {
                    headers: {
                        Authorization: `Bearer ${res.accessToken}`,
                    },
                })
            )
            .then((res) => setRequestState({ status: 'success', data: res.data }))
            .catch((error) => setRequestState({ status: 'error', error: error }))
    }, [msalAccounts, msalInstance, path])

    return requestState
}

export function useApiGetFunction<T>(): (path: string) => Promise<T> {
    const { instance: msalInstance, accounts: msalAccounts } = useMsal()

    return useCallback(
        (path: string) => {
            const msalArgs = {
                scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
                account: msalAccounts[0],
            }

            return msalInstance
                .acquireTokenSilent(msalArgs)
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenRedirect(msalArgs)
                    }
                    throw error
                })
                .then((res) =>
                    axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/api/${path}`, {
                        headers: {
                            Authorization: `Bearer ${res.accessToken}`,
                        },
                    })
                )
                .then((res) => res.data)
        },
        [msalAccounts, msalInstance]
    )
}

export function useAxios(): () => Promise<AxiosInstance> {
    const { instance: msalInstance, accounts: msalAccounts } = useMsal()
    return async () => {
        const msalArgs = {
            scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
            account: msalAccounts[0],
        }

        const bearerToken = await msalInstance
            .acquireTokenSilent(msalArgs)
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(msalArgs)
                }
                throw error
            })
            .then((res) => res.accessToken);

        return axios.create({
            baseURL: process.env.REACT_APP_BACKEND_API_URL,
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        })
    }
}

export function useApiPostFunction<T>(): (path: string) => Promise<T> {
    const { instance: msalInstance, accounts: msalAccounts } = useMsal()

    return useCallback(
        (path: string) => {
            const msalArgs = {
                scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
                account: msalAccounts[0],
            }

            return msalInstance
                .acquireTokenSilent(msalArgs)
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenRedirect(msalArgs)
                    }
                    throw error
                })
                .then((res) =>
                    axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/api/${path}`, {
                        headers: {
                            Authorization: `Bearer ${res.accessToken}`,
                        },
                    })
                )
                .then((res) => res.data)
        },
        [msalAccounts, msalInstance]
    )
}
