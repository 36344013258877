import { ChargePriority } from 'chargePoint/types/chargePoint'
import { useGraphQlMutation } from 'common'

const createConnectorQuery = `
    mutation CreateConnector(
        $chargePointId: Int!
        $connectorId: Int!
        $priority: ChargePriority!
        $minAmpere: Float!
        $maxAmpere: Float!
        $phaseMapping: String!
    ) {
        createConnector(
            chargePointId: $chargePointId
            connectorId: $connectorId
            priority: $priority
            minAmpere: $minAmpere
            maxAmpere: $maxAmpere
            phaseMapping: $phaseMapping
        ) {
            id
            chargePointId
            connectorId
            priority
            minAmpere
            maxAmpere
            phaseMapping
        }
    }
`

interface CreateConnectorInput {
    chargePointId: number
    connectorId: number
    priority: ChargePriority
    minAmpere: number
    maxAmpere: number
    phaseMapping: string
}

interface CreateConnectorResponse {
    createConnector: {
        id: number
        chargePointId: number
        connectorId: number
        priority: ChargePriority
        minAmpere: number
        maxAmpere: number
        phaseMapping: string
    }
}

export default function useCreateConnector() {
    return useGraphQlMutation<CreateConnectorResponse, CreateConnectorInput>(createConnectorQuery)
}
