import { ChargePoint } from 'chargePoint'
import { Card, Loadable, SpinnerWithHeight } from 'common'
import { EditingMode, SortingMode, Table, IKaTableProps, ITableProps, kaReducer, SortDirection, DataType } from 'ka-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ReactComponent as LinkIcon } from 'img/link.svg'
import { ReactComponent as LinkOffIcon } from 'img/link-off.svg'
import { useEffect, useState } from 'react'
import { DispatchFunc } from 'ka-table/types'
import { defaultKaTableSort } from 'common/utils/kaTableSort'

interface ChargePointListProps {
    chargePoints: Loadable<ChargePoint[]>
    className?: string
}

export default function ChargePointList({
    chargePoints,
    className,
}: ChargePointListProps): JSX.Element {
    const { t } = useTranslation()

    const getIsConnectedContent = (isConnected: boolean | undefined) => {
        if (isConnected === true) {
            return <LinkIcon className="fill-green-600 w-4" />
        } else if (isConnected === false) {
            return <LinkOffIcon className="fill-yellow-600 w-4" />
        }
    }

    const [searchText, setSearchText] = useState('')

    const tablePropsInit: IKaTableProps = {
        columns: [
            { key: 'isConnected', title: t('chargePointListTableColumnStatus'), width: 64 },
            { key: 'id', title: t('chargePointListTableColumnId'), width: 128, sortDirection: SortDirection.Ascend, dataType: DataType.String, filterRowValue: '' },
            { key: 'accessControl', title: t('chargePointListTableColumnAccessControl') },
            { key: 'clusterId', title: t('chargePointListTableColumnCluster') },
            { key: 'firmwareVersion', title: t('chargePointListTableColumnFirmwareVersion') },
            { key: 'connectors', title: t('chargePointListTableColumnConnectors') },
        ],
        data: [],
        rowKeyField: 'id',
        sortingMode: SortingMode.Single,
        sort: defaultKaTableSort,
        editingMode: EditingMode.None,
        searchText: searchText,
        search: ({ searchText, rowData, column }) => {
            if (column.key === 'id') {
                return rowData.id.toLowerCase().includes(searchText.toLowerCase())
            } 
                return false
        },
        // filteringMode: FilteringMode.FilterRow,
        childComponents: {
            cellText: {
                content: (props) => {
                    const chargePoint = props.rowData as ChargePoint
                    switch (props.column.key) {
                        case 'id':
                            const chargePointLink = `/controller/${chargePoint.controllerId}/chargepoint/${chargePoint.id}`
                            return <Link to={chargePointLink}>{chargePoint.id}</Link>
                        case 'isConnected':
                            return getIsConnectedContent(props.value)
                        case 'connectors':
                            return props.value
                        // .filter((connector: Connector) => connector.state)
                        // .map((connector: Connector) => `${connector.id}-${connector.state}`)
                    }
                },
            },
        },
    }

    const [tableProps, changeTableProps] = useState(tablePropsInit)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    useEffect(() => {
        if (chargePoints.status !== 'success') return
        changeTableProps((prevState) => ({
            ...prevState,
            data: chargePoints.data.map((chargePoint, index) => ({
                ...chargePoint,
                index,
                connectors: (
                    <div className="flex flex-col">
                        {chargePoint.connectors
                            .filter((x) => x.state)
                            .map((connector, index) => (
                                <div
                                    key={index}
                                    className="text-xs font-mono text-right w-36"
                                >{`#${connector.id} - ${connector.state}`}</div>
                            ))}
                    </div>
                ),
            })),
        }))
    }, [changeTableProps, chargePoints])

    let content: JSX.Element | string = <SpinnerWithHeight height={144} />
    switch (chargePoints.status) {
        case 'success':
            content = (
                <>
                    <div className="flex w-full">
                        <input
                            className="ml-auto max-w-sm my-2"
                            placeholder={t('search')}
                            type="search"
                            value={searchText}
                            onChange={(event) => {
                                setSearchText(event.currentTarget.value)
                                dispatch({ type: 'Search', searchText: event.currentTarget.value })
                            }}
                        />
                    </div>
                    <Table {...tableProps} dispatch={dispatch} />
                </>
            )
            break
        case 'error':
            content = chargePoints.error.toString()
            break
    }

    return (
        <Card title={t('chargePointListTitle')} className={className}>
            {content}
        </Card>
    )
}
