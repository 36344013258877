import { useGraphQl } from 'common'
import { ControllerConfigComposite } from 'config/types/controllerConfig'
import { useMemo } from 'react'

const controllerConfigQuery = `
    query ControllerConfigQuery($id: Int!){
        controllerConfig(id: $id) {
            location {
                id
                name
            }
            controller {
                id
                deviceId
                locationId
                name
                loadBalancingEnabled
                sensorLoggerEnabled
                sensorLoggerIntervalMs
                isOcppProxyEnabled,
                enableRouting,
                isDirty
                isCommissioned
            }
            clusters {
                id
                clusterId
                controllerId
                fuseLimit
                headroom
                netType
                parentId
            }
            chargePoints {
                id
                clusterId
                chargePointId
                accessControl
                connectors {
                    id
                    chargePointId
                    connectorId
                    minAmpere
                    maxAmpere
                    phaseMapping
                    priority
                }
            }
            sensors {
                id
                clusterId
                sensorInterface
                currentSensorType
                inputVoltage
                outputCurrent
                filter
                model
                slaveId
            }
        }
    }
`
interface ControllerConfigQueryData {
    controllerConfig: ControllerConfigComposite
}

export default function useControllerConfig(controllerId: number) {
    const [state, refresh] = useGraphQl<ControllerConfigQueryData>(
        controllerConfigQuery,
        useMemo(() => ({ id: controllerId }), [controllerId])
    )

    return [state, refresh] as const
}
