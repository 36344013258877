import { Card, IconButton, SpinnerWithHeight } from 'common'
import useControllerModules from 'controller/hooks/useControllerModules'
import { ReactComponent as RestartIcon } from 'img/restart.svg'
import { ReactComponent as SatelliteUplinkIcon } from 'img/satelliteUplink.svg'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './ControllerModules.module.scss'
import PingModal from './PingModal'
import RestartModal from './RestartModal'

interface ControllerModulesProps {
    controllerId: string
    className?: string
}

export default function ControllerModules({ controllerId, className }: ControllerModulesProps) {
    const req = useControllerModules(controllerId)
    const { t } = useTranslation()
    const [restartModalTarget, setRestartModalTarget] = useState<string | null>(null)
    const [pingModalTarget, setPingModalTarget] = useState<string | null>(null)

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return `Failed to load controller modules: ${req.error}`
            case 'loading':
                return <SpinnerWithHeight height={100} />
            case 'success':
                return (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('controllerModulesModuleName')}</th>
                                <th>{t('controllerModulesState')}</th>
                                <th>{t('controllerModulesUpdateTime')}</th>
                                <th>{t('controllerModulesActions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {req.data.map((module) => (
                                <tr key={module.name}>
                                    <td>{module.name}</td>
                                    <td>{module.state}</td>
                                    <td title={moment(module.updateTime).toLocaleString()}>
                                        {moment(module.updateTime).format('MMM DD HH:mm:ss')}
                                    </td>
                                    <td className={style.buttonRow}>
                                        <IconButton
                                            text={t('controllerModulesRestart')}
                                            title={t('controllerModulesRestartTitle')}
                                            icon={RestartIcon}
                                            type="primary"
                                            click={() => setRestartModalTarget(module.name)}
                                        />
                                        <IconButton
                                            text={t('controllerModulesPing')}
                                            title={t('controllerModulesPingTitle')}
                                            icon={SatelliteUplinkIcon}
                                            type="primary"
                                            click={() => setPingModalTarget(module.name)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
        }
    }
    return (
        <Card title={t('controllerModulesPageTitle')} className={className}>
            {getContent()}
            <PingModal
                show={!!pingModalTarget}
                controllerId={controllerId}
                targetModule={pingModalTarget || ''}
                close={() => setPingModalTarget(null)}
            />
            <RestartModal
                show={!!restartModalTarget}
                controllerId={controllerId}
                targetModule={restartModalTarget || ''}
                close={() => setRestartModalTarget(null)}
            />
        </Card>
    )
}
