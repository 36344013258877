import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import useDecommissionController from 'controller/hooks/useDecommissionController'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import style from './ControllerActions.module.scss'

interface DecommissionModalProps {
    show: boolean
    close: () => void
    controllerId: string
}

export default function DecommissionModal({ show, close, controllerId }: DecommissionModalProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [decommissionResult, decommissionController, reset] = useDecommissionController()

    useEffect(() => {
        if (
            !show &&
            (decommissionResult.status === 'success' || decommissionResult.status === 'error')
        ) {
            reset()
        }
    }, [decommissionResult.status, reset, show])

    const getModalContent = () => {
        switch (decommissionResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerActionsDecommissionConfirmModal', { controllerId })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                decommissionController({ deviceId: controllerId })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to decommission controller: ${decommissionResult.error}`}</span>
                        <Button className={style.modalCloseButton} type="primary" click={close}>
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                const closeAction = decommissionResult.data.decommissionController
                    ? () => navigate(0)
                    : close

                return (
                    <>
                        {decommissionResult.data.decommissionController ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerActionsDecommissionSuccess', { controllerId })}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to commission controller
                            </span>
                        )}{' '}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={closeAction}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={close} className={style.modal}>
            <h2>{t('controllerActionsDecommissionTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
