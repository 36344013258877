import { useGraphQlMutation } from 'common'

const deleteClusterQuery = `
    mutation DeleteCluster($id: Int!) {
        deleteCluster(id: $id)
    }
`

interface DeleteClusterInput {
    id: number
}

interface DeleteClusterResponse {
    deleteCluster: boolean
}

export default function useDeleteCluster() {
    return useGraphQlMutation<DeleteClusterResponse, DeleteClusterInput>(deleteClusterQuery)
}
