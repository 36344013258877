import classNames from 'classnames'
import { ValidatedField } from 'common/hooks/useValidatedField'
import style from './ValidatedFields.module.scss'
import React from 'react'

interface ValidatedTextFieldProps {
    title: string
    field: ValidatedField<string>
    forceModified?: boolean
    forceInvalid?: boolean
    className?: string
    classNameInner?: string
    multiline?: boolean
    suggestions?: string[]
}

export default function ValidatedTextField({
    title,
    field,
    forceModified,
    forceInvalid,
    className,
    classNameInner,
    multiline,
    suggestions,
}: ValidatedTextFieldProps) {
    const textFieldClasses = classNames({
        [style.field]: true,
        [style.modified]: field.isModified || forceModified,
        [style.invalid]: !field.isValid || forceInvalid,
        [`${classNameInner}`]: !!classNameInner,
    })

    const containerClasses = classNames({
        [style.container]: true,
        [`${className}`]: !!className,
    })

    const textField = React.createElement(multiline ? 'textarea' : 'input', {
        type: 'text',
        value: field.value,
        className: textFieldClasses,
        onChange: (e: any) => field.setValue(e.target.value),
        list: suggestions ? title : undefined,
    })

    return (
        <div className={containerClasses}>
            <label className={style.label}>{title}</label>
            {textField}
            {suggestions && (
                <datalist id={title}>
                    {suggestions.map((suggestion, index) => (
                        <option key={index} value={suggestion} />
                    ))}
                </datalist>
            )}
        </div>
    )
}
