import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { ReactComponent as LogoutIcon } from 'img/logout.svg'
import style from './LogoutButton.module.scss'

const handleLogout = (msalInstance: IPublicClientApplication) => {
    msalInstance.logoutRedirect().catch((e) => {
        console.error(e)
    })
}

export default function LogoutButton(): JSX.Element {
    const { instance: msalInstance } = useMsal()

    return (
        <button className={style.button} onClick={() => handleLogout(msalInstance)}>
            <LogoutIcon className={style.icon} />
            Logout
        </button>
    )
}
