import { mapSuccess, useGraphQl } from "common";
import { FirmwareManifest } from "../types/firmwaremanifest";
import { useMemo } from "react";

const availableFirmwareForControllerQuery = `
query AvailableFirmware($id: String!) {
	controller(id: $id) {
		availableFirmware {
			version
			filename
			hash
			vendor
			model
			validUpgradeVersions
		} 
	}
}
`;

type AvailableFirmwareForControllerQueryData = { controller: { availableFirmware: FirmwareManifest[] } };

export default function useAvailableFirmwareForController(id: string) {
    const [state, reexecuteQuery] = useGraphQl<AvailableFirmwareForControllerQueryData>(
        availableFirmwareForControllerQuery,
        useMemo(() => ({ id: id }), [id])
    );
    return [mapSuccess(state, (data) => data.controller.availableFirmware), reexecuteQuery] as const;
}
