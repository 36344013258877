import style from './StateTimelineLegend.module.scss'

interface StateTimelineLegendProps {
    colors: string[]
    stateNames: string[]
    margin: number
}

export default function StateTimelineLegend({
    colors,
    stateNames,
    margin,
}: StateTimelineLegendProps) {
    const svgSize = 20
    const legendItem = (i: number) => (
        <div className={style.legendItem} key={i}>
            <svg width={svgSize} height={svgSize}>
                <rect x={0} width={svgSize} y={0} height={svgSize} fill={colors[i]} rx={4} />
            </svg>
            {stateNames[i]}
        </div>
    )
    return (
        <div className={style.container} style={{ marginLeft: margin, marginRight: margin }}>
            {stateNames.map((_, i) => legendItem(i))}
        </div>
    )
}
