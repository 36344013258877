import {
    Breadcrumb,
    Button,
    Card,
    IconLink,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import {
    DevicesBatchPageLink,
} from 'common/components/Breadcrumb/Breadcrumb'
import { ReactComponent as WrenchIcon } from 'img/wrench-cog.svg'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import { ReactComponent as SatelliteIcon } from 'img/satelliteUplink.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './DevicesBatchPage.module.scss'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'
import { useNavigate } from 'react-router-dom'
import  { PreviewTable, useInsertDevicesBatch, useGetHardwareVersions } from '../index'

export type possibleDelimeters = "," | ";"

const devicesArrayToPayload = (devicesArray: string[], delimeter: possibleDelimeters) => {
    const devicesPayload = devicesArray.map(device => {
        const deviceData = device.split(delimeter)
        return {
            serialNumber: deviceData[0],
            primaryMac: deviceData[1],
            secondaryMac: deviceData[2]
    }})
    return devicesPayload
}

function dataToDevicesArray(data: string){
    const devices = data.split('\n').map(deviceData => deviceData.trim())
    return devices
}

function dataPreviewAndPayload(data: string, delimeter: possibleDelimeters){
    return {
        payload: devicesArrayToPayload(dataToDevicesArray(data), delimeter)
    }
}

export default function DevicesBatchPage() {
    const { t } = useTranslation()
    const req = useControllerIssues()
    const navigate = useNavigate();
    const [allHardwareVersions] = useGetHardwareVersions()
    const [insertDeviceBatchState, insertDeviceBatch] = useInsertDevicesBatch()
    const [delimeter, setDelimeter] = useState<possibleDelimeters>(",");
    const [batchName, setBatchName] = useState<string>();
    const [hardwareVersion, setHardwareVersion] = useState<string>();
    const [deviceListData, setDeviceListData] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const isHardwareVersionsLoaded = allHardwareVersions.status === "success";
    const isRequestSuccess = req.status === "success";
    const issuesLinkText = isRequestSuccess && req.data.length > 0 ? `${t('goToIssuesPageButtonTitle')}${isRequestSuccess ? ` (${req.data.length})` : null}` : t('goToIssuesPageButtonTitle');

    return (
        <>
            <NavigationBar>
                <Breadcrumb className={style.breadcrumb}>
                    <OverviewPageLink />
                    <DevicesBatchPageLink />
                </Breadcrumb>
                <IconLink
                    icon={LocationIcon}
                    to="/locations"
                    text={t('overviewPageLocationsLink')}
                />
                <IconLink
                    icon={WrenchIcon}
                    to="/issues"
                    text={issuesLinkText}
                />
                <IconLink
                    icon={SatelliteIcon}
                    to="/provisioning"
                    text={t('provisioningPageLink')}
                />
            </NavigationBar>
            <Card title={t('importBatchPageTitle')}>
               <form className={style.assignToLocationForm} noValidate>
                    <div className={`${style.informationSection} mt-2`}>
                         <span className={style.label}><strong>{t('batchNameLabel')}:</strong></span>
                         <input
                            type="text"
                            placeholder={t('batchNamePlaceholder')}
                            onChange={(e) => setBatchName(e.target.value)}
                            className="max-w-sm my-2"
                            required
                        />
                    </div>
                <div className={style.informationSection}>
                    <label htmlFor="hardwareVersionDropDown" className="">
                        <strong>{t('controllerDetailsDescriptionHardwareVersion')}:</strong>
                    </label>
                    <select
                        id="hardwareVersionDropDown"
                        onChange={(e) => {
                            setHardwareVersion(e.target.value);
                        }}
                        className="mb-4"
                        disabled={!isHardwareVersionsLoaded}
                        placeholder={t('controllerDetailsDescriptionHardwareVersion')}
                        required
                    >
                        <option value="" key="" data-testid="select">
                            {t('controllerDetailsDescriptionHardwareVersion')}
                        </option>
                        {isHardwareVersionsLoaded && allHardwareVersions.data.map(hardwareVersion => {
                            return <option value={hardwareVersion.id} key={hardwareVersion.id}>{hardwareVersion.hwVersion}</option>
                        })}
                    </select>
                </div>
                    <h3>{t('importDeviceList')}</h3>
                <div className={style.informationSection}>
                    <span className={style.label}><strong>{t('devicesListData')}:</strong></span>
                    <textarea
                        placeholder={t('importCsvDataPlaceholder')}
                        onChange={(e)=>{
                            setDeviceListData(e.target.value)
                        }}
                        className={`max-w-lg ${style.devicesListData}`}
                        required
                    />
                    <div>
                <label htmlFor="csvSeparatorDropdown" className="w-max">
                        <strong className='ml-2'>{t('delimeter')}:</strong>
                    </label>
                    <select
                        id="csvSeparatorDropdown"
                        onChange={(e) => setDelimeter(e.target.value as possibleDelimeters)}
                        className="ml-2"
                        defaultValue={','}
                    >
                        <option value={','}>Comma (,)</option>
                        <option value={';'}>Semicolon (;)</option>
                    </select>
                    </div>
                    </div>
                <div className={`mt-4 ${style.informationSection}`}>
                <span className={style.label}></span>
                <Button
                        type="secondary"
                        disabled={allHardwareVersions.status === 'loading'}
                        click={(e) => {
                            e.preventDefault()
                            setShowPreview(prevState => !prevState)
                            dataPreviewAndPayload(deviceListData, delimeter)
                        }}
                        >
                            {t('previewDataButton')}
                    </Button>
                </div>
                {deviceListData.length > 25 && showPreview ? <PreviewTable data={dataPreviewAndPayload(deviceListData, delimeter).payload} /> : null }
                <div className={`mt-10 ${style.informationSection}`}>
                    <Button
                        type="secondary"
                        disabled={insertDeviceBatchState.status === 'loading'}
                        click={(e) => {
                            e.preventDefault()
                            if(!batchName || !hardwareVersion || !deviceListData){
                                setError("Please fill all fields")
                                return
                            }
                            const isDeviceListValid =  dataToDevicesArray(deviceListData).some(device => device.length < 25)
                            if(isDeviceListValid){
                                setError("Some device data might be missing")
                                return
                            }
                            const payload = {
                                input: {
                                    "batchName": batchName,
                                    "hardwareVersionId" : hardwareVersion,
                                    "devices": dataPreviewAndPayload(deviceListData, delimeter).payload
                                }
                            }
                            insertDeviceBatch(payload)
                            navigate('/')
                            return
                        }}
                        >
                            {t('importSerialNumbers')}
                    </Button>
                    {error ? <p className={style.errorMessage}>{error}</p> : null}
                </div>
            </form>
            </Card>
        </>
    )
}
