import ChargePointAccessControl from 'chargePoint/components/ChargePointAccessControl/ChargePointAccessControl'
import ChargePointConnectorList from 'chargePoint/components/ChargePointConnectorList/ChargePointConnectorList'
import ChargePointDetails from 'chargePoint/components/ChargePointDetails/ChargePointDetails'
import ChargePointLoad from 'chargePoint/components/ChargePointLoad/ChargePointLoad'
import ZoomableStateTimelineChart from 'chargePoint/components/ZoomableStateTimelineChart/ZoomableStateTimelineChart'
import useChargePointConnectionState from 'chargePoint/hooks/useChargePointConnectionState'
import useChargePointData from 'chargePoint/hooks/useChargePointData'
import useConnectorState from 'chargePoint/hooks/useConnectorState'
import {
    Breadcrumb,
    Card,
    ControllerPageLink,
    CurrentPage,
    IconLink,
    Loadable,
    mapSuccess,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import style from './ChargePointPage.module.scss'
import { ReactComponent as ClipboardPulse } from 'img/clipboard-pulse.svg'
import { ReactComponent as ClipboardClock } from 'img/clipboard-text-clock-outline.svg'
import { ReactComponent as TextListIcon } from 'img/formatListText.svg'

interface DataTimeRange {
    dataTimeRange: 7 | 30
    startDate: string
    endDate: string
}

export default function ChargePointPage(): JSX.Element {
    const { controllerId, chargePointId } = useParams() as {
        controllerId: string
        chargePointId: string
    }
    const { t } = useTranslation()

    const [{ dataTimeRange, startDate, endDate }, setDataTimeRange] = useState<DataTimeRange>(
        () => ({
            dataTimeRange: 7,
            startDate: moment().subtract(7, 'days').toISOString(),
            endDate: moment().toISOString(),
        })
    )

    const req = useChargePointData(controllerId, chargePointId)[0]

    const connectionTimeline = useChargePointConnectionState(
        controllerId,
        chargePointId,
        startDate,
        endDate
    )

    const connectorStates = useConnectorState(controllerId, chargePointId, startDate, endDate)

    const getChartLoadingOrErrorContent = (chartData: Loadable<unknown>) => {
        switch (chartData.status) {
            case 'error':
                return `Failed to load chart data: ${chartData.error}`
            case 'loading':
                return <SpinnerWithHeight height={163} />
        }
    }

    const expandSelectedTimeRange = (newTimeRange: 7 | 30) => {
        if (newTimeRange > dataTimeRange) {
            setDataTimeRange({
                dataTimeRange: newTimeRange,
                startDate: moment().subtract(newTimeRange, 'days').toISOString(),
                endDate: moment().toISOString(),
            })
        }
    }
    
    if(req.status === 'success' && !req.data.chargePoint){
        return (<>
           <NavigationBar>
                <Breadcrumb className={style.breadcrumb}>
                    <OverviewPageLink />
                    <ControllerPageLink controllerId={controllerId || ''} />
                    <CurrentPage currentPage={chargePointId || ''} />
                </Breadcrumb>
            </NavigationBar>

            <div className={style.containerNotFoundChargePoint}>
                {t('chargePointNotFound', {chargePointId})}
            </div>
        </>)
    }

    return (
        <>
            <NavigationBar>
                <Breadcrumb className={style.breadcrumb}>
                    <OverviewPageLink />
                    <ControllerPageLink controllerId={controllerId || ''} />
                    <CurrentPage currentPage={chargePointId || ''} />
                </Breadcrumb>
                <IconLink
                    to={`/controller/${controllerId}/logs?query=${chargePointId}`}
                    icon={TextListIcon}
                    text={t('chargePointLogsTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/chargepoint/${chargePointId}/transactions`}
                    icon={ClipboardClock}
                    text={t('chargePointTransactionsTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/chargepoint/${chargePointId}/diagnostics`}
                    icon={ClipboardPulse}
                    text={t('chargePointDiagnosticsTitle')}
                />
            </NavigationBar>
            <div className={style.page}>
                <ChargePointDetails chargePoint={mapSuccess(req, (st) => st.chargePoint)} />
                <ChargePointAccessControl
                    controllerId={controllerId!}
                    chargePointId={chargePointId!}
                    currentAccessControl={
                        (req.status === 'success' && req.data.chargePoint?.accessControl) ||
                        undefined
                    }
                />
                <ChargePointConnectorList
                    connectors={mapSuccess(req, (st) => st.chargePoint.connectors)}
                    controllerId={controllerId}
                    chargePointId={chargePointId}
                />
                <ChargePointLoad
                    controllerId={controllerId}
                    chargePointId={chargePointId}
                    className={style.loadChart}
                />
                <Card className={style.stateTimeline} title={t('connectionStatusTitle')}>
                    {connectionTimeline.status === 'success' ? (
                        <ZoomableStateTimelineChart
                            stateTimeline={connectionTimeline.data}
                            expandSelectedTimeRange={expandSelectedTimeRange}
                        />
                    ) : (
                        getChartLoadingOrErrorContent(connectionTimeline)
                    )}
                </Card>
                <Card className={style.stateTimeline} title={t('chargePointConnectorStatusTitle')}>
                    {connectorStates.status === 'success'
                        ? connectorStates.data.map((timeline) => (
                              <ZoomableStateTimelineChart
                                  key={timeline.name}
                                  stateTimeline={timeline}
                                  expandSelectedTimeRange={expandSelectedTimeRange}
                              />
                          ))
                        : getChartLoadingOrErrorContent(connectorStates)}
                </Card>
            </div>
        </>
    )
}
