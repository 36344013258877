import { Breadcrumb, CurrentPage, IconLink, NavigationBar } from 'common'
import { ControllerList } from 'controller'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import { ReactComponent as SatelliteIcon } from 'img/satelliteUplink.svg'
import { ReactComponent as ChipIcon } from 'img/chip.svg'
import { useTranslation } from 'react-i18next'
import style from './OverviewPage.module.scss'
import { ReactComponent as WrenchIcon } from 'img/wrench-cog.svg'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'

export default function OverviewPage(): JSX.Element {
    const { t } = useTranslation()
    const req = useControllerIssues()

    const isRequestSuccess = req.status === "success";

    const issuesLinkText = isRequestSuccess && req.data.length > 0 ? `${t('goToIssuesPageButtonTitle')}${isRequestSuccess ? ` (${req.data.length})` : null}` : t('goToIssuesPageButtonTitle');

    return (
        <>
            <NavigationBar>
                <Breadcrumb>
                    <CurrentPage currentPage={t('breadcrumbOverviewPageLink')} />
                </Breadcrumb>
                <IconLink
                    icon={LocationIcon}
                    to="/locations"
                    text={t('overviewPageLocationsLink')}
                />
                <IconLink
                    icon={WrenchIcon}
                    to="/issues"
                    text={issuesLinkText}
                />
                <IconLink
                    icon={ChipIcon}
                    to="/devicesBatch"
                    text={t('insertBatchWithDevicesMenuTitle')}
                />
                <IconLink
                    icon={SatelliteIcon}
                    to="/provisioning"
                    text={t('provisioningPageLink')}
                />
            </NavigationBar>
            <div className={style.page}>
                {/* <ControllerIssues /> */}
                <ControllerList />
            </div>
        </>
    )
}
