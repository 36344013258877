import { AccessControl, ChargePoint, ChargePriority } from 'chargePoint/types/chargePoint'
import { mapSuccess, mergeChargePointData, useGraphQl } from 'common'
import { useMemo } from 'react'

const chargePointDataQuery = `
    query ChargePointDataQuery($controllerId: String!, $chargePointId: String!) {
        controller(id: $controllerId) {
            chargePoint(id: $chargePointId, merge: true) {
                id
                accessControl
                clusterId: cluster
                connectors {
                    id
                    minAmpere
                    maxAmpere
                    phaseMapping
                    priority
                }
            }
            state {
                chargePoint(id: $chargePointId) {
                    id
                    ip
                    isConnected
                    vendor
                    fwVer
                    serial
                    model
                    fwStatus
                    diagStatus
                    connectors {
                        id
                        loadLimit
                        state
                        errorCode
                    }
                }
            }
        }
    }`

interface ChargePointQueryData {
    controller: {
        chargePoint: {
            id: string
            accessControl: AccessControl
            clusterId: string
            connectors: {
                id: number
                minAmpere: number
                maxAmpere: number
                phaseMapping: string
                priority: ChargePriority
            }[]
        }
        state: {
            chargePoint: {
                id: string
                ip: string
                isConnected: boolean
                vendor: string
                fwVer: string
                serial: string
                model: string
                fwStatus: string
                diagStatus: string
                connectors: {
                    id: number
                    loadLimit: number
                    state: string
                    errorCode: string
                }[]
            }
        }
    }
}

interface ChargePointData {
    chargePoint: ChargePoint
}

export default function useChargePointData(controllerId: string, chargePointId: string) {
    const [state, reexecuteQuery] = useGraphQl<ChargePointQueryData>(
        chargePointDataQuery,
        useMemo(
            () => ({ controllerId: controllerId, chargePointId: chargePointId }),
            [controllerId, chargePointId]
        )
    )

    const dataMapper = (data: ChargePointQueryData, controllerId: string): ChargePointData => {
        return {
            chargePoint: mergeChargePointData(
                data.controller.chargePoint ? [data.controller.chargePoint] : [],
                data.controller.state.chargePoint ? [data.controller.state.chargePoint] : [],
                controllerId
            )[0],
        }
    }

    return [
        useMemo(
            () => mapSuccess(state, (data) => dataMapper(data, controllerId)),
            [state, controllerId]
        ),
        reexecuteQuery,
    ] as const
}
