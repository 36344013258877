import { useGraphQlMutation } from 'common'

const scanQuery = `
    mutation Scan($deviceId: String!, $vendor: ChargePointVendorType!, $timeoutMs: Int!) {
        scan(input:{deviceId: $deviceId, vendor: $vendor, timeoutMs: $timeoutMs}) {
            id
            ip
            vendor
            model
            serialNumber
        }
    }
`

export type ChargePointVendorType =
    | 'SCHNEIDER'
    | 'IO_CHARGER'
    | 'VESTEL'
    | 'KEBA'
    | 'SCHNEIDER_AC_PRO'

export type ScanInput = {
    deviceId?: string
    controllerId?: string
    vendor: ChargePointVendorType
    timeoutMs: number
}

export type ScanResponse = {
    id?: string
    ip: string
    vendor: string
    model?: string
    serialNumber?: string
}

export default function useScan() {
    return useGraphQlMutation<{ scan: ScanResponse[] }, ScanInput>(scanQuery)
}
