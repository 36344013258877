import { useGraphQlMutation } from 'common'

const initiateChargePointsQuery = `
    mutation InitiateChargePointsQuery($id:String!, $setup:[ChargePointInitializationInput!]!){
        initiateChargepoints(input:{
            deviceId: $id,
            setup: $setup
        })
    }
`

export type PartialChargePointInitiationInput = {
    ip: string
    id: string
    vendor: string
}

export type ChargePointInitiationInput = {
    disableAuthentication: boolean
    maxAmpere: number
} & PartialChargePointInitiationInput

export type InitiateChargePointsInput = {
    id: string
    setup: ChargePointInitiationInput[]
}

export type InitiateChargePointsResponse = {
    initiateChargepoints: boolean
}

export default function useInitiateChargePoints() {
    return useGraphQlMutation<InitiateChargePointsResponse, InitiateChargePointsInput>(
        initiateChargePointsQuery
    )
}
