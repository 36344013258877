import { scaleOrdinal } from '@visx/scale'
import { StateTimeline } from 'chargePoint/types/stateTimeline'
import { Button } from 'common'
import useWindowWidth from 'common/hooks/useWindowWidth'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StateTimelineChart from '../StateTimelineChart/StateTimelineChart'
import StateTimelineLegend from '../StateTimelineChart/StateTimelineLegend'
import style from './ZoomableStateTimelineChart.module.scss'

interface ZoomableStateTimelineChartProps {
    stateTimeline: StateTimeline
    expandSelectedTimeRange: (newValue: 7 | 30) => void
}

interface ChartDatum {
    xStart: number
    width: number
    stateOrdinal: number
    date: string
}

interface TooltipData {
    top: number
    left: number
    chartPos: number
    datum?: ChartDatum
    date: Date
}

const timelineMargin = 50

export default function ZoomableStateTimelineChart({
    stateTimeline,
    expandSelectedTimeRange,
}: ZoomableStateTimelineChartProps) {
    const { t } = useTranslation()
    const [isZoomed, setIsZoomed] = useState<boolean>(false)
    const [tooltipData, setTooltipData] = useState<TooltipData | null>(null)
    const [selectedTimeRange, setSelectedTimeRange] = useState<7 | 30>(7)

    const [[startTimeBase, endTimeBase], setTimeBase] = useState(() => [
        new Date().getTime() - 86400 * 7 * 1000,
        new Date().getTime(),
    ])

    const [[startTime, endTime], setBounds] = useState(() => [startTimeBase, endTimeBase])

    useEffect(() => setBounds([startTimeBase, endTimeBase]), [startTimeBase, endTimeBase])

    const colors = stateTimeline.stateColors

    const colorScale = scaleOrdinal<string, string>({
        domain: stateTimeline.stateNames,
        range: colors,
    })

    const showTooltip = !!tooltipData

    const windowWidth = useWindowWidth()
    const width = Math.min(windowWidth, 1200) - 96 // 32 padding on each side from MainLayout + 16 padding on each side from Card

    const handleSetTimeRange = (newTimeRange: 7 | 30) => {
        setSelectedTimeRange(newTimeRange)
        expandSelectedTimeRange(newTimeRange)
        setIsZoomed(false)
        setTimeBase([new Date().getTime() - 86400 * newTimeRange * 1000, new Date().getTime()])
    }

    return (
        <div className={style.container}>
            <div className={style.chartHeader}>
                <h3 className={style.chartTitle}>{stateTimeline.name}</h3>
                <div className={style.buttonRow}>
                    {selectedTimeRange !== 7 && (
                        <Button
                            type="secondary"
                            dense
                            click={() => {
                                handleSetTimeRange(7)
                            }}
                        >
                            {t('zoomableChartShowWeek')}
                        </Button>
                    )}
                    {selectedTimeRange !== 30 && (
                        <Button
                            type="secondary"
                            dense
                            click={() => {
                                handleSetTimeRange(30)
                            }}
                        >
                            {t('zoomableChartShowMonth')}
                        </Button>
                    )}
                    {isZoomed && (
                        <Button
                            className={style.resetZoomButton}
                            dense
                            type="secondary"
                            click={() => {
                                setBounds([startTimeBase, endTimeBase])
                                setIsZoomed(false)
                            }}
                        >
                            {t('zoomableChartResetZoom')}
                        </Button>
                    )}
                </div>
            </div>
            <StateTimelineChart
                width={width}
                margin={timelineMargin}
                stateTimeline={stateTimeline}
                startTime={startTime}
                endTime={endTime}
                onZoom={(newStartDate, newEndDate) => {
                    setBounds([newStartDate, newEndDate])
                    setIsZoomed(true)
                }}
                setTooltipData={setTooltipData}
            />
            <StateTimelineLegend
                colors={colors}
                stateNames={stateTimeline.stateNames}
                margin={timelineMargin}
            />
            {showTooltip && (
                <div
                    className={style.tooltip}
                    key="tooltip"
                    style={{ top: tooltipData.top, left: tooltipData.left }}
                >
                    <div className={style.tooltipTitle}>
                        {moment(tooltipData?.date).format('dddd, MMM DD, HH:mm')}
                    </div>
                    {tooltipData?.datum?.stateOrdinal != null && (
                        <div
                            className={style.stateText}
                            style={{
                                color: colorScale(
                                    stateTimeline.stateNames[tooltipData?.datum?.stateOrdinal]
                                ),
                            }}
                        >
                            {stateTimeline.stateNames[tooltipData?.datum?.stateOrdinal]}
                        </div>
                    )}
                    {tooltipData?.datum?.stateOrdinal != null && (
                        <div>
                            {t('stateTimelineChartReportedAt', {
                                date: moment(tooltipData?.datum?.date).format(
                                    'dddd, MMM DD, HH:mm:ss'
                                ),
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
