import { useGraphQlMutation } from 'common'

const deleteChargePointQuery = `
    mutation DeleteChargePoint($id: Int!) {
        deleteChargePoint(id: $id)
    }
`

interface DeleteChargePointInput {
    id: number
}

interface DeleteChargePointResponse {
    deleteChargePoint: boolean
}

export default function useDeleteChargePoint() {
    return useGraphQlMutation<DeleteChargePointResponse, DeleteChargePointInput>(
        deleteChargePointQuery
    )
}
