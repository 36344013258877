import { AccessControl } from 'chargePoint'
import { useGraphQlMutation } from 'common'

const setAccessControlQuery = `
    mutation SetAccessControl($input: ControllerChargePointAccessInput!) {
        configureChargePointAccess(input: $input)
    }
`

interface ControllerChargePointAccessInput {
    input: {
        deviceId: string
        id: string
        accessControl: AccessControl
    }
}

interface SetChargerAccessControlResponse {
    configureChargePointAccess: boolean
}

export default function useSetChargerAccessControl() {
    return useGraphQlMutation<SetChargerAccessControlResponse, ControllerChargePointAccessInput>(
        setAccessControlQuery
    )
}
