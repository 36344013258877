import { IconButton } from 'common'
import { ChargePointConfig, ConnectorConfig } from 'config/types/controllerConfig'
import ConnectorConfigItem from './ConnectorConfigItem'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import style from './ConnectorConfigList.module.scss'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import swapListItem from 'config/utils/swapListElement'
import generateReactKey from 'config/utils/generateReactKey'
import { t } from 'i18next'

interface ConnectorConfigListProps {
    connectors: ConnectorConfig[]
    chargePoint: ChargePointConfig
}

export default function ConnectorConfigList({ connectors, chargePoint }: ConnectorConfigListProps) {
    const [_, setControllerConfig] = useControllerConfigState()

    const handleAddConnector = () => {
        const newConnector: ConnectorConfig = {
            connectorId: connectors.length + 1,
            chargePointId: chargePoint.id!,
            minAmpere: 7,
            maxAmpere: 32,
            phaseMapping: '',
            priority: 'NORMAL',
            reactKey: generateReactKey(),
        }

        setControllerConfig((prev) => {
            const oldChargePoint = prev.chargePoints.find((x) => x.id === chargePoint.id)!
            const newChargePoint = {
                ...oldChargePoint,
                connectors: [...oldChargePoint.connectors, newConnector],
            }

            return {
                ...prev,
                chargePoints: swapListItem(prev.chargePoints, oldChargePoint, newChargePoint),
            }
        })
    }
    const isAddEnabled = Number.isInteger(chargePoint.id)

    return (
        <div className={style.container}>
            {connectors.map((connector) => (
                <ConnectorConfigItem
                    key={`connector-${connector.reactKey || connector.connectorId}`}
                    connector={connector}
                    chargePoint={chargePoint}
                />
            ))}
            <IconButton
                text={(
                    isAddEnabled
                        ? t('controllerConfigPageConnectorAdd')
                        : t('controllerConfigPageConnectorAddDisabledHint')) as string
                }
                className={style.addConnectorButton}
                click={handleAddConnector}
                icon={PlusIcon}
                type="secondary"
                disabled={!isAddEnabled}
            />
        </div>
    )
}
