import classNames from 'classnames'
import { Card } from 'common'
import { DataRecord, RawChartDataMapper } from 'common/types/chartTypes'
import { useClusterLoadFetchFunction, ZoomableLoadChart } from 'controller'
import { ClusterLoadResponse } from 'controller/hooks/useClusterLoadFetchFunction'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import style from './ClusterLoad.module.scss'
import { useZoom } from 'controller/contexts/ZoomContext'

interface ClusterLoadProps {
    controllerId: string
    className?: string
}

export default function ClusterLoad({ controllerId, className }: ClusterLoadProps) {
    const [selectedCluster, setSelectedCluster] = useState<string | null>(null)
    const fetchFn = useClusterLoadFetchFunction(controllerId)
    
    const { setMirrorZoom } = useZoom();
    const { t } = useTranslation()

    const classes = classNames({ [`${className}`]: className })

    const handleClusterLegendClick = (cluster: string) => {
        setSelectedCluster(cluster)
    }

    const dataMapper = selectedCluster
        ? getMapperForClusterPhaseLoad(selectedCluster)
        : clusterMapper

    return (
        <Card
            title={t(selectedCluster ? 'clusterPhaseLoadTitle' : 'clusterLoadTitle', {
                cluster: selectedCluster,
            })}
            className={classes}
        >
                            <div>
                    <input
                        id="mirrorChartsTimeline"
                        type="checkbox"
                        className="w-auto mr-4"
                        defaultChecked={false}
                        onChange={(e) => {
                            setMirrorZoom && setMirrorZoom(e.currentTarget.checked)
                        }}
                    />
                    <label htmlFor="mirrorChartsTimeline">
                        {t('chargePointsMirrorClusterLoadMirror')}
                    </label>
                </div>
            <ZoomableLoadChart
                fetchFn={fetchFn}
                dataMapper={dataMapper}
                onClickLegendItem={!selectedCluster ? handleClusterLegendClick : undefined}
            />
            {selectedCluster ? (
                <button
                    className={style.backToClustersButton}
                    onClick={() => setSelectedCluster(null)}
                >
                    {t('clusterLoadChartBackButton')}
                </button>
            ) : (
                <p className={style.phaseLoadHint}>{t('clusterLoadPhaseHint')}</p>
            )}
        </Card>
    )
}

const clusterMapper: RawChartDataMapper<ClusterLoadResponse> = (data) => ({
    records: data.clusters.flatMap((cluster) =>
        cluster.data.map((x) => [x[0], cluster.clusterId, x[1]] as DataRecord)
    ),
    dataKeys: data.clusters.map((cluster) => cluster.clusterId),
})

function getMapperForClusterPhaseLoad(clusterId: string): RawChartDataMapper<ClusterLoadResponse> {
    return (data) => ({
        records:
            data.clusters
                .find((cluster) => cluster.clusterId === clusterId)
                ?.data.flatMap((dataPoint) =>
                    [0, 1, 2].map(
                        (phaseIndex) =>
                            [
                                dataPoint[0],
                                (phaseIndex + 1).toString(),
                                dataPoint[2 + phaseIndex],
                            ] as DataRecord
                    )
                ) || [],

        dataKeys: ['1', '2', '3'],
    })
}
