import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useDeleteCluster from 'config/hooks/useDeleteCluster'
import { ClusterConfig } from 'config/types/controllerConfig'
import { useTranslation } from 'react-i18next'
import style from './ClusterConfigItem.module.scss'

interface DeleteClusterModalProps {
    show: boolean
    close: () => void
    cluster: ClusterConfig
}

export default function DeleteClusterModal({ show, close, cluster }: DeleteClusterModalProps) {
    const { t } = useTranslation()
    const [deleteClusterState, executeDeleteCluster, reset] = useDeleteCluster()
    const [_, setControllerConfigState] = useControllerConfigState()

    const getModalContent = () => {
        switch (deleteClusterState.status) {
            case 'idle':
                return t('controllerConfigPageClusterDeleteModal', {
                    clusterId: cluster.clusterId,
                })
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete cluster: ${deleteClusterState.error}`
            case 'success':
                return (
                    <>
                        {deleteClusterState.data.deleteCluster
                            ? t('controllerConfigPageClusterDeleteModalSuccess', {
                                  clusterId: cluster.clusterId,
                              })
                            : 'Failed to delete cluster.'}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteClusterState.status === 'idle' || deleteClusterState.status === 'error') {
            reset()
            close()
        }
        if (deleteClusterState.status === 'success') {
            if (deleteClusterState.data?.deleteCluster) {
                setControllerConfigState((c) => ({
                    ...c,
                    controller: {
                        ...c.controller,
                        isDirty: true,
                    },
                    clusters: c.clusters.filter((x) => x.id !== cluster.id),
                }))
                close()
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteClusterState.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteCluster({ id: cluster.id! })}
            className={style.modal}
        >
            <h2>{t('controllerConfigPageClusterDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
