import { dataKeyComparator, useApiGetFunction } from 'common'
import { useCallback } from 'react'

// [timestamp, current, l1current, l2current, l3current, loadLimitAmpere]
export type ConnectorLoadDataPoint = [string, number, number, number, number, number]

export interface ConnectorLoadResponse {
    connectors: Connector[]
}

export interface Connector {
    connectorId: string
    data: ConnectorLoadDataPoint[]
}

export default function useConnectorLoadFetchFunction(
    controllerId: string
): (
    startDate: string,
    endDate: string,
    binSizeInMinutes: number
) => Promise<ConnectorLoadResponse> {
    const get = useApiGetFunction<ConnectorLoadResponse>()

    return useCallback(
        (startDate: string, endDate: string, binSizeInMinutes: number) =>
            get(
                `time-series/${controllerId}/connector-load?startDate=${startDate}&endDate=${endDate}&binSize=${binSizeInMinutes}`
            ).then((res) => ({
                connectors: res.connectors.sort((a, b) =>
                    dataKeyComparator(a.connectorId, b.connectorId)
                ),
            })),
        [controllerId, get]
    )
}
