import axios from 'axios'
import { useAxios } from 'common/hooks/useApi'
import { NetType } from 'config/types/controllerConfig'
import { ChargePointSetupRow, ChargePointSetupStatus } from 'electricianView/types/chargePointSetup'

interface PutChargePointSetupParams {
    id: number
    parkingSpot: string
    smsCode: string
    serialNumber: string
    netType: NetType
    phaseMapping: string
    description: string
    networkLocation: string
    status: ChargePointSetupStatus
    cluster: string
}

export default function usePutChargePointSetup(controllerId: string, accessToken?: string) {
    const getAuthenticatedAxiosInstance = useAxios()

    return async (params: PutChargePointSetupParams) => {
        const axiosClient = accessToken
            ? axios.create({ baseURL: process.env.REACT_APP_BACKEND_API_URL })
            : await getAuthenticatedAxiosInstance()

        const path =
            `/api/charge-point-setup/${controllerId}` + (accessToken ? `/${accessToken}` : '')
        return axiosClient
            .put(path, null, { params })
            .then((res) => res.data as ChargePointSetupRow)
    }
}
