import axios from 'axios'
import { Loadable } from 'common'
import { useAxios } from 'common/hooks/useApi'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import { useState } from 'react'

export default function useGetChargePointSetup(controllerId: string, accessToken?: string) {
    const [requestState, setRequestState] = useState<Loadable<ChargePointSetupRow[]>>({
        status: 'loading',
    })
    const getAuthenticatedAxiosInstance = useAxios()

    const get = async () => {
        const axiosClient = accessToken
            ? axios.create({ baseURL: process.env.REACT_APP_BACKEND_API_URL })
            : await getAuthenticatedAxiosInstance()

        const path =
            `/api/charge-point-setup/${controllerId}` + (accessToken ? `/${accessToken}` : '')
        return axiosClient
            .get<ChargePointSetupRow[]>(path)
            .then((res) => {
                setRequestState({ status: 'success', data: res.data })
                return res
            })
            .catch((err) => {
                setRequestState({ status: 'error', error: err })
                throw err
            })
    }

    return [requestState, get] as const
}
