import classNames from 'classnames'
import React, { useContext } from 'react'
import style from './NestableFrame.module.scss'

const NestableFrameContext = React.createContext<number>(0)

interface NestableFrameProps {
    children: React.ReactNode
    className?: string
}

export default function NestableFrame({ children, className }: NestableFrameProps) {
    const depth = useContext(NestableFrameContext)
    const depthClass = depth % 2 === 0 ? style.frameDepthEven : style.frameDepthOdd

    const classes = classNames(style.frame, depthClass, { [`${className}`]: !!className })

    return (
        <NestableFrameContext.Provider value={depth + 1}>
            <div className={classes}>{children}</div>
        </NestableFrameContext.Provider>
    )
}
