import { EditingMode, IKaTableProps, ITableProps, SortingMode, Table, kaReducer } from 'ka-table'
import { prettifyPhaseMapping } from '../PhaseMapper/PhaseMapper'
import { ChargePointSetupRow } from 'electricianView/types/chargePointSetup'
import { DispatchFunc } from 'ka-table/types'
import { useState } from 'react'
// import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'img/plus.svg'

import style from './ChargePointTable.module.scss'
import { IconButton } from 'common'
import { CreateChargePoint } from 'electricianView/pages/CreateChargePoint'

interface ChargePointTableProps {
    chargePointSetupRows: ChargePointSetupRow[]
    saveChargePointSetupRow?: (row: ChargePointSetupRow) => void
    deleteChargePointSetupRow?: (row: ChargePointSetupRow) => void
    readOnly: boolean
}

export function ChargePointTable({
    chargePointSetupRows,
    saveChargePointSetupRow = () => {},
    deleteChargePointSetupRow = () => {},
    readOnly,
}: ChargePointTableProps): JSX.Element {
    // const { locationId, controllerId, accessToken } = useParams() as {
    //     locationId: string
    //     controllerId: string
    //     accessToken?: string
    // }

    const { t } = useTranslation()

    const [chargePointViewVisible, setChargePointViewVisible] = useState(false)
    const [chargePointSetupRow, setChargePointSetupRow] = useState<ChargePointSetupRow | undefined>(
        undefined
    )

    const initTableProps: IKaTableProps = {
        columns: [
            { key: 'chargePoint', title: t('chargePoint'), width: 5 },
            // { key: 'parkingSpot', title: t('parkingSpot'), width: 32 },
            // { key: 'smsCode', title: t('smsCode'), width: 32 },
            // { key: 'serialNumber', title: t('serialNumber'), width: 48 },
            // { key: 'phaseMapping', title: t('phaseMapping'), width: 1 },
            // { key: 'description', title: t('description'), width: 64 },
            // { key: 'phase', title: t('phase'), width: 1 },
            { key: 'cluster', title: t('cluster'), width: 2 },
            { key: 'networkLocation', title: t('networkLocation'), width: 2 },
            // { key: 'actions', width: 48 },
        ],
        rowKeyField: 'serialNumber',
        sortingMode: SortingMode.Single,
        editingMode: EditingMode.Cell,

        columnResizing: false,

        childComponents: {
            dataRow: {
                elementAttributes: (props) => ({
                    title: `${props.rowData.name} ${props.rowData.phoneNumber}`,
                    onMouseEnter: (event, extendedEvent) => {
                        const {
                            childProps: { rowKeyValue },
                            dispatch,
                        } = extendedEvent
                        dispatch({ type: 'ROW_MOUSE_ENTER', rowKeyValue })
                    },
                    onMouseLeave: (event, { dispatch }) => {
                        dispatch({ type: 'ROW_MOUSE_LEAVE' })
                    },
                    onMouseDown: (event, props) => {
                        setChargePointSetupRow(props.childProps.rowData as ChargePointSetupRow)
                        setChargePointViewVisible(true)
                    },
                    className: (props.rowData as ChargePointSetupRow).failedToSave
                        ? style['row-problem']
                        : '',
                }),
            },
            cell: {
                content: (props) => {
                    if (props.column.key === 'chargePoint') {
                        const cpsr = props.rowData as ChargePointSetupRow
                        return (
                            <div className="flex flex-col">
                                <div className="flex flex-row items-center">
                                    {cpsr.failedToSave && <p>!</p>}
                                    <div className="text-base">
                                        {[cpsr.smsCode, cpsr.parkingSpot]
                                            .filter((x) => x)
                                            .join('-')}
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="text-sm text-gray-500 max-w-sm">
                                        {t('serial')}: {cpsr.serialNumber}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (props.column.key === 'phaseMapping') {
                        const cpsr = props.rowData as ChargePointSetupRow
                        return (
                            <div className="text-xs font-mono">
                                {prettifyPhaseMapping(cpsr.netType, cpsr.phaseMapping)}
                            </div>
                        )
                    }

                    return <div className="text-sm">{props.value}</div>
                },
            },
        },
    }

    const [tableProps, changeTableProps] = useState(initTableProps)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    return (
        <div>
            <div className={style['table-container']}>
                <Table {...tableProps} data={chargePointSetupRows} dispatch={dispatch} />
            </div>
            {!readOnly && (
                <IconButton
                    className="mt-4"
                    click={() => {
                        setChargePointSetupRow(undefined)
                        setChargePointViewVisible(true)
                    }}
                    type="primary"
                    dense
                    icon={PlusIcon}
                    text={t('addChargePoint')}
                />
            )}
            {chargePointViewVisible && (
                <div className="p-4 absolute top-0 left-0 bottom-0 right-0 sm:relative sm:mt-4 bg-white max-w-4xl">
                    <CreateChargePoint
                        existingTableRows={chargePointSetupRows}
                        chargePointSetupRow={chargePointSetupRow}
                        saveChargePoint={saveChargePointSetupRow}
                        deleteChargePoint={deleteChargePointSetupRow}
                        closeView={() => setChargePointViewVisible(false)}
                        key={chargePointSetupRow?.reactKey}
                    />
                </div>
            )}
        </div>
    )
}
