import { AxisBottom } from '@visx/axis'
import { localPoint } from '@visx/event'
import { scaleOrdinal, scaleTime } from '@visx/scale'
import { StateTimeline } from 'chargePoint/types/stateTimeline'
import { tickDateFormat } from 'common'
import { useMemo, useState } from 'react'
import style from './StateTimelineChart.module.scss'

interface ChartDatum {
    xStart: number
    width: number
    stateOrdinal: number
    date: string
}

interface TooltipData {
    left: number
    top: number
    chartPos: number
    datum?: ChartDatum
    date: Date
}

interface StateTimelineChartProps {
    stateTimeline: StateTimeline
    startTime: number
    endTime: number
    width: number
    margin: number
    onZoom?: (newStartDate: number, newEndDate: number) => void
    setTooltipData?: (data: TooltipData | null) => void
}

const chartBarHeight = 50

export default function StateTimelineChart({
    stateTimeline,
    startTime,
    endTime,
    width,
    margin,
    onZoom,
    setTooltipData,
}: StateTimelineChartProps) {
    const [zoomStart, setZoomStart] = useState<number | null>(null)
    const [zoomCursorPos, setZoomCursorPos] = useState<number | null>(null)
    const [verticalCrosshairPos, setVerticalCrosshairPos] = useState<number | null>(null)

    const colorScale = scaleOrdinal<string, string>({
        domain: stateTimeline.stateNames,
        range: stateTimeline.stateColors,
    })

    const timeScale = scaleTime<number>({
        domain: [startTime, endTime],
        range: [0, width - 2 * margin],
    })

    const chartData: ChartDatum[] = useMemo(() => {
        const timelineWidth = width - 2 * margin
        return stateTimeline.points
            .map((point, i) => {
                const xStart = timeScale(new Date(point.date).getTime())
                const xEnd = stateTimeline.points[i + 1]
                    ? timeScale(new Date(stateTimeline.points[i + 1].date).getTime())
                    : timelineWidth
                return {
                    xStart,
                    width: xEnd - xStart,
                    stateOrdinal: point.stateOrdinal,
                    date: point.date,
                }
            })
            .filter((datum) => datum.xStart < timelineWidth && datum.xStart + datum.width >= 0)
            .map((datum) => {
                const clampedXStart = Math.max(0, datum.xStart)
                const newWidth = datum.xStart - clampedXStart + datum.width

                return {
                    ...datum,
                    xStart: clampedXStart,
                    width: Math.min(timelineWidth - clampedXStart, newWidth),
                }
            })
        // timeScale is determined by startTime, endTime and width which are all in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeline, width, startTime, endTime])

    const handleMouseMove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const coords = localPoint(e.currentTarget, e)!

        if (isOutsideBarChart(coords)) {
            return
        }

        if (zoomStart) {
            setZoomCursorPos(coords.x)
        }

        const rect = e.currentTarget.getBoundingClientRect()

        setVerticalCrosshairPos(coords.x)

        setTooltipData &&
            setTooltipData({
                left: coords.x + rect.left + 8,
                top: coords.y + rect.top + 8,
                chartPos: coords.x,
                datum: chartData.find(
                    (x) => coords.x - margin >= x.xStart && coords.x - margin < x.xStart + x.width
                ),
                date: timeScale.invert(coords.x - margin),
            })
    }

    const isOutsideBarChart = (coords: { x: number; y: number }) =>
        coords.x < margin || coords.x + margin > width

    const handleMouseDown = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const coords = localPoint(e.currentTarget, e)!

        if (isOutsideBarChart(coords) || e.button !== 0) {
            // button 0 is left click
            return
        }

        setZoomStart(coords.x)
        setZoomCursorPos(coords.x)
    }

    const handleMouseUp = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (!zoomStart || !zoomCursorPos || e.button !== 0) {
            // button 0 is left click
            return
        }
        const coords = localPoint(e.currentTarget, e)!

        const newStartDate = timeScale.invert(Math.min(zoomStart, coords.x) - margin)
        const newEndDate = timeScale.invert(Math.max(zoomStart, coords.x) - margin)

        onZoom && onZoom(newStartDate.getTime(), newEndDate.getTime())

        setZoomStart(null)
        setZoomCursorPos(null)
    }

    return (
        <svg
            width={width}
            height={chartBarHeight + 24}
            className={style.svg}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => {
                setTooltipData && setTooltipData(null)
                setZoomCursorPos(null)
                setZoomStart(null)
                setVerticalCrosshairPos(null)
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <g>
                {chartData.map((x, i) => (
                    <rect
                        key={i}
                        x={Math.floor(x.xStart) + margin}
                        y={0}
                        height={chartBarHeight}
                        width={Math.ceil(x.width + 1)}
                        fill={colorScale(stateTimeline.stateNames[x.stateOrdinal])}
                    />
                ))}
            </g>
            {verticalCrosshairPos !== null && <VerticalCrosshair xPos={verticalCrosshairPos} />}
            <g
                width={width - 2 * margin}
                x={margin}
                y={chartBarHeight}
                transform={`translate(${margin},${chartBarHeight})`}
            >
                <AxisBottom
                    scale={timeScale}
                    numTicks={Math.ceil(width / 150)}
                    tickFormat={(x) => tickDateFormat(x.valueOf())}
                />
            </g>
            {zoomStart !== null && zoomCursorPos !== null && (
                <ChartSelection start={zoomStart} cursorPos={zoomCursorPos} />
            )}
        </svg>
    )
}

interface VerticalCrosshairProps {
    xPos: number
}

function VerticalCrosshair({ xPos }: VerticalCrosshairProps) {
    return (
        <rect x={xPos} y={0} height={chartBarHeight} width={2} fill={'black'} fillOpacity={0.5} />
    )
}

interface ChartSelectionProps {
    start: number
    cursorPos: number
}

function ChartSelection({ start, cursorPos }: ChartSelectionProps) {
    const selectionStart = Math.min(start, cursorPos)
    const zoomSelectionWidth = Math.max(start, cursorPos) - selectionStart

    return (
        <rect
            x={selectionStart}
            width={zoomSelectionWidth}
            y={0}
            height={chartBarHeight}
            fill={'black'}
            fillOpacity={0.2}
        />
    )
}
