import { useGraphQlMutation } from "common";

const InsertDevicesBatchMutation = `
mutation AddBatchWithProvisionings(
    $input: CreateBatchWithProvisioningsInput!
) {
    addBatchWithProvisionings(input: $input)
}
`;

export type insertDevicesBatchResponse = {
    data: {
        addBatchWithProvisionings: Array<string>
    }
}

interface Device {
    serialNumber: string;
    primaryMac: string;
    secondaryMac: string;
}

interface Input {
    batchName: string;
    hardwareVersionId: string;
    devices: Device[];
}

interface insertDevicesBatchArgs {
    input: Input;
}

export default function useInsertDevicesBatch() {
    return useGraphQlMutation<insertDevicesBatchResponse, insertDevicesBatchArgs>(InsertDevicesBatchMutation);
}
