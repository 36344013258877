import { Card, Loadable, SpinnerWithHeight } from 'common'
import { NetworkAdapter } from 'controller/types/networkAdapter'
import { useTranslation } from 'react-i18next'

interface NetworkAdaptersProps {
    adapters: Loadable<NetworkAdapter[]>
}

export default function NetworkAdapters({ adapters }: NetworkAdaptersProps) {
    const { t } = useTranslation()

    const getContent = () => {
        switch (adapters.status) {
            case 'error':
                return `Failed to load controller adapters: ${adapters.error}`
            case 'loading':
                return <SpinnerWithHeight height={100} />
            case 'success':
                return (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('networkAdapterName')}</th>
                                <th>{t('networkAdapterIp')}</th>
                                <th>{t('networkAdapterIsConnected')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adapters.data.map((adapter) => (
                                <tr key={adapter.name}>
                                    <td>{adapter.name}</td>
                                    <td>{adapter.ip}</td>
                                    <td>{String(adapter.isUp)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
        }
    }
    return <Card title={t('networkAdaptersCardTitle')}>{getContent()}</Card>
}
