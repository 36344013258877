import useRenameChargePoint from 'chargePoint/hooks/useRenameChargePoint'
import { Button, Modal, SpinnerWithHeight, ValidatedTextField } from 'common'
import useValidatedField from 'common/hooks/useValidatedField'
import { stringNonEmptyValidator } from 'config/utils/validators'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './RenameChargePointModal.module.scss'

interface RenameChargePointModalProps {
    controllerId: string
    chargePointId: string
    show: boolean
    close: () => void
}

export default function RenameChargePointModal({
    controllerId,
    chargePointId,
    show,
    close,
}: RenameChargePointModalProps) {
    const { t } = useTranslation()
    const [renameChargePoint, executeRenameChargePoint, reset] = useRenameChargePoint()

    const [moveTransactions, setMoveTransactions] = useState(false)

    const newNameField = useValidatedField<string>('', stringNonEmptyValidator)

    const handleRename = () => {
        executeRenameChargePoint({
            input: {
                deviceId: controllerId,
                oldIdentifier: chargePointId,
                newIdentifier: newNameField.value,
                moveTransactions,
            },
        })
    }

    const handleClose = () => {
        close()
        if (renameChargePoint.status === 'success' || renameChargePoint.status === 'error') {
            newNameField.reset()
            setMoveTransactions(false)
            reset()
        }
    }

    const getContent = () => {
        switch (renameChargePoint.status) {
            case 'error':
                return `Failed to rename charge point: ${renameChargePoint.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return renameChargePoint.data.renameChargePoint.success ? (
                    <p className={style.successText}>{t('renameChargePointModalRenameSuccess')}</p>
                ) : (
                    <>
                        <p className={style.errorText}>
                            Something went wrong when renaming charge point:
                        </p>
                        <p>{renameChargePoint.data.renameChargePoint.message}</p>
                    </>
                )
            case 'idle':
                return (
                    <>
                        <div className={style.modalContent}>
                            <ValidatedTextField
                                title={t('renameChargePointModalNewNameField')}
                                field={newNameField}
                            />
                            <div className={style.checkboxContainer}>
                                <input
                                    id="moveTransactions"
                                    type="checkbox"
                                    className={style.checkbox}
                                    checked={moveTransactions}
                                    onChange={() => {
                                        setMoveTransactions(!moveTransactions)
                                    }}
                                />
                                <label htmlFor="moveTransactions">
                                    {t('renameChargePointModalMoveTransactions')}
                                </label>
                            </div>
                            <div className={style.buttonRow}>
                                <Button type="secondary" click={handleClose}>
                                    {t('modalCancel')}
                                </Button>
                                <Button
                                    type="primary"
                                    click={handleRename}
                                    disabled={!newNameField.isValid}
                                >
                                    {t('renameChargePointModalConfirmRename')}
                                </Button>
                            </div>
                        </div>
                    </>
                )
        }
    }
    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('renameChargePointModalTitle')}</h2>
            {getContent()}
        </Modal>
    )
}
