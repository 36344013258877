import { mapSuccess, useGraphQl } from 'common'
import { BusinessUnit } from 'config/types/controllerConfig'

const businessUnitClaimsQuery = `
    query BusinessUnitClaimsQuery {
        businessUnitClaims
    }
`

type BusinessUnitClaimsQueryData = {
    businessUnitClaims: BusinessUnit[]
}

export default function useBusinessUnitClaims() {
    const [state, refetch] = useGraphQl<BusinessUnitClaimsQueryData>(businessUnitClaimsQuery)
    return [mapSuccess(state, (data) => data.businessUnitClaims), refetch] as const
}
