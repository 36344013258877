import { SortDirection } from 'ka-table'
import { Column } from 'ka-table/models'

function sortAscending(a: any, b: any) {
    return a === b ? 0 : a > b ? 1 : -1
}

function sortDescending(a: any, b: any) {
    return a === b ? 0 : a > b ? -1 : 1
}

export function defaultKaTableSort({ column }: { column: Column }) {
    return (column.sortDirection === SortDirection.Ascend) ? sortAscending : sortDescending
}
