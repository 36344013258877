import { Configuration as MsalConfiguration } from '@azure/msal-browser'

const msalConf: MsalConfiguration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
        authority: 'https://login.microsoftonline.com/5a6741a5-c5f9-4f82-90ed-c23747406f0a',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
}

export const config = {
    auth: {
        msal: msalConf,
        scopes: ['User.Read'],
        extraScopesToConsent: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
        redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    },
}
