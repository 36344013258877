import classNames from 'classnames'
import { ValidatedField } from 'common/hooks/useValidatedField'
import style from './ValidatedFields.module.scss'

interface ValidatedSelectFieldProps<T extends string> {
    title?: string
    field: ValidatedField<T>
    options: { value: T; text?: string }[]
    forceModified?: boolean
    forceUnmodified?: boolean
    className?: string
    classNameInner?: string
    placeholder?: string
    disabled?: boolean
}

export default function ValidatedSelectField<T extends string>({
    title,
    field,
    options,
    forceModified,
    forceUnmodified,
    className,
    classNameInner,
    placeholder,
    disabled,
}: ValidatedSelectFieldProps<T>) {
    const selectFieldClasses = classNames({
        [style.field]: true,
        [style.modified]: (field.isModified || forceModified) && !forceUnmodified,
        [style.invalid]: !field.isValid,
        [`${classNameInner}`]: !!classNameInner,
    })

    const containerClasses = classNames({
        [style.container]: true,
        [`${className}`]: !!className,
    })

    return (
        <div className={containerClasses}>
            {title && <label className={style.label}>{title}</label>}
            <select
                value={field.value}
                className={selectFieldClasses}
                onChange={(e) => {
                    field.setValue(e.target.value as T)
                }}
                disabled={disabled}
            >
                {placeholder && (
                    <option
                        disabled={true}
                        className={`${style.optionBase} ${style.unmodified}`}
                        value=""
                    >
                        {placeholder}
                    </option>
                )}
                {options.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                        className={classNames({
                            [style.optionBase]: true,
                            [style.unmodified]:
                                option.value === field.initialValue && !forceModified,
                            [style.modified]: option.value !== field.initialValue || forceModified,
                            [style.invalid]: !field.validator(option.value),
                            [style.option]: true,
                        })}
                    >
                        {option.text ?? option.value}
                    </option>
                ))}
            </select>
        </div>
    )
}
