import { AccessControl } from 'chargePoint'
import { accessControlValues } from 'chargePoint/types/chargePoint'
import {
    Button,
    ConfirmModalButtonRow,
    Modal,
    SpinnerWithHeight,
    ValidatedSelectField,
} from 'common'
import useValidatedField from 'common/hooks/useValidatedField'
import useApproveChargePointSetup from 'electricianView/hooks/useApproveChargePointSetup'
import { useTranslation } from 'react-i18next'
import style from './ApproveModal.module.scss'

interface ApproveModalProps {
    show: boolean
    controllerId: number
    close: () => void
}

export default function ApproveModal({ show, controllerId, close }: ApproveModalProps) {
    const { t } = useTranslation()
    const [approveRequestState, executeApprove, reset] = useApproveChargePointSetup()

    const accessControlField = useValidatedField<AccessControl>('CONTROLLER_OPEN', () => true)

    const handleClose = () => {
        if (approveRequestState.status !== 'loading') {
            reset()
        }
        close()
    }

    const getContent = () => {
        switch (approveRequestState.status) {
            case 'idle':
                return (
                    <>
                        <h2>{t('approveModalTitle')}</h2>
                        <ValidatedSelectField
                            title={t('approveModalDefaultAccessControl')}
                            field={accessControlField}
                            options={accessControlValues.map((x) => ({ value: x }))}
                            forceModified={true}
                        />
                        <p>{t('approveModalBody')}</p>
                        <ConfirmModalButtonRow
                            onConfirm={() =>
                                executeApprove({
                                    id: controllerId,
                                    accessControlType: accessControlField.value,
                                })
                            }
                            onCancel={close}
                        />
                    </>
                )
            case 'loading':
                return (
                    <>
                        <h2>{t('approveModalTitle')}</h2>
                        <SpinnerWithHeight height={72} />
                    </>
                )
            case 'success':
                return (
                    <>
                        <h2>{t('approveModalTitle')}</h2>
                        {approveRequestState.data.approveChargePointSetup ? (
                            <p>{t('approveModalSuccess')}</p>
                        ) : (
                            <p>Something went wrong when approving charge point setup</p>
                        )}
                        <Button
                            type="secondary"
                            click={handleClose}
                            className={style.modalCloseButton}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'error':
                return (
                    <>
                        <p>Something went wrong when approving charge point setup:</p>
                        <p>{approveRequestState.error.toString()}</p>
                        <Button
                            type="secondary"
                            click={handleClose}
                            className={style.modalCloseButton}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }
    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            {getContent()}
        </Modal>
    )
}
