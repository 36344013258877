import { Button, ConfirmModal, SpinnerWithHeight } from 'common'
import useDeleteControllerConfig from 'config/hooks/useDeleteControllerConfig'
import { ControllerConfig } from 'config/types/controllerConfig'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './ControllerDeviceConfig.module.scss'

interface DeleteControllerModalProps {
    show: boolean
    close: () => void
    controller: ControllerConfig
}

export default function DeleteControllerModal({
    show,
    close,
    controller,
}: DeleteControllerModalProps) {
    const { t } = useTranslation()
    const [deleteController, executeDeleteController, reset] = useDeleteControllerConfig()
    const navigate = useNavigate()

    const getModalContent = () => {
        switch (deleteController.status) {
            case 'idle':
                return t('controllerConfigDeleteModal')
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return `Failed to delete controller: ${deleteController.error}`
            case 'success':
                return (
                    <>
                        <p>
                            {deleteController.data.deleteControllerConfig
                                ? t('controllerConfigDeleteModalSuccess')
                                : `Something went wrong when deleting controller. ${JSON.stringify(
                                      deleteController.data
                                  )}`}
                        </p>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    const handleClose = () => {
        if (deleteController.status === 'idle' || deleteController.status === 'error') {
            reset()
            close()
        }
        if (deleteController.status === 'success') {
            if (deleteController.data?.deleteControllerConfig) {
                close()
                navigate(`/location/${controller.locationId}`)
            }
        }
    }

    return (
        <ConfirmModal
            show={show}
            showButtons={deleteController.status === 'idle'}
            close={handleClose}
            onConfirm={() => executeDeleteController({ id: controller.id! })}
            className={style.modal}
        >
            <h2>{t('controllerConfigDeleteModalTitle')}</h2>
            {getModalContent()}
        </ConfirmModal>
    )
}
