import { StateTimeline } from 'chargePoint/types/stateTimeline'
import { Loadable, useApiGetFunction } from 'common'
import { useEffect, useState } from 'react'

// [timestamp, stateOrdinal]
export type ConnectorStateDataPoint = [string, number]

export interface ConnectorStateResponse {
    deviceId: string
    chargePointId: string
    connectors: Connector[]
}

export interface Connector {
    connectorId: string
    data: ConnectorStateDataPoint[]
}

export default function useConnectorState(
    controllerId: string,
    chargePointId: string,
    startDate: string,
    endDate: string
): Loadable<StateTimeline[]> {
    const get = useApiGetFunction<ConnectorStateResponse>()
    const [state, setState] = useState<Loadable<StateTimeline[]>>({ status: 'loading' })

    useEffect(() => {
        get(
            `time-series/${controllerId}/${chargePointId}/connector-state?startDate=${startDate}&endDate=${endDate}`
        )
            .then((res) =>
                setState({
                    status: 'success',
                    data: res.connectors.map((conn) => ({
                        name: conn.connectorId,
                        points: conn.data
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map((x) => ({ date: x[0], stateOrdinal: x[1] })),
                        stateNames: [
                            'Available',
                            'Preparing',
                            'Charging',
                            'SuspendedEVSE',
                            'SuspendedEV',
                            'Finishing',
                            'Reserved',
                            'Unavailable',
                            'Faulted',
                        ],
                        stateColors: [
                            '#2ca02c',
                            '#bcbd22',
                            '#1f77b4',
                            '#e377c2',
                            '#9467bd',
                            '#8c564b',
                            '#c2ebff',
                            '#7f7f7f',
                            '#d62728',
                        ],
                    })),
                })
            )
            .catch((err) => setState({ status: 'error', error: err }))
    }, [controllerId, chargePointId, startDate, endDate, get])

    return state
}
