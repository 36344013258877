import useCurrentConnectorLoad from 'chargePoint/hooks/useCurrentConnectorLoad'
import { Connector } from 'chargePoint/types/chargePoint'
import { Card, IconButton, Loadable, Spinner, SpinnerWithHeight } from 'common'
import { useTranslation } from 'react-i18next'
import { ReactComponent as RebootIcon } from 'img/restart.svg'

import style from './ChargePointConnectorList.module.scss'
import useConnectorChangeAvailability from 'controller/hooks/useConnectorChangeAvailability'
import React from 'react'

interface ChargePointConnectorListProps {
    controllerId: string
    chargePointId: string
    connectors: Loadable<Connector[]>
}

export default function ChargePointConnectorList({
    connectors,
    controllerId,
    chargePointId,
}: ChargePointConnectorListProps) {
    const data = useCurrentConnectorLoad(controllerId)
    const { t } = useTranslation()

    const getPhaseLoad = (conn: Connector) => {
        if (data.status === 'error') {
            return <span title={'Failed to load phase load ' + data.error}>...</span>
        }
        if (data.status === 'loading') {
            return <Spinner size={60} />
        }

        const id = `${chargePointId}#${conn.id}`
        const phases = data.data
            .find((x) => x.connectorId === id)
            ?.phases.map((phase) => phase.toFixed(1)) || ['-', '-', '-']

        return `[${phases.join(', ')}]A`
    }

    const getLoadLimitText = (conn: Connector) => {
        if (!conn.loadLimit) {
            return ''
        }
        if (conn.loadLimit < 1000) {
            return `${conn.loadLimit} A`
        } else {
            return t('chargePointConnectorListNoLoadLimit')
        }
    }

    const [_, changeConnectorAvailability] = useConnectorChangeAvailability()

    const setConnectorAvailability = async (connectorId: number, state?: string) => {
            
        const result = await changeConnectorAvailability({ deviceId: controllerId, chargePointId: chargePointId, connectorId: connectorId,
            isAvailable: state?.toLowerCase() === "unavailable" })

        if (!result.data)
            return;

        const info = result.data.changeAvailability;

        alert(t('chargePointConnectorMakeUnavailableResultMessage', { success: info.success, status: info.status }))
    }

    const getTableBodyContent = () => {
        switch (connectors.status) {
            case 'error':
                return (
                    <tr>
                        <td colSpan={100}>{`Failed to load connectors`}</td>
                    </tr>
                )
            case 'loading':
                return (
                    <tr>
                        <td colSpan={100}>
                            <SpinnerWithHeight height={100} />
                        </td>
                    </tr>
                )
            case 'success':               

                return connectors.data.map((conn) => (
                    <React.Fragment key={conn.id}>
                    <tr >
                        <td>{conn.id}</td>
                        <td>{conn.state}</td>
                        <td>{conn.errorCode}</td>
                        <td>{getPhaseLoad(conn)}</td>
                        <td>{getLoadLimitText(conn)}</td>
                        <td>{conn.phaseMapping}</td>
                        <td>{conn.minAmpere}</td>
                        <td>{conn.maxAmpere}</td>
                        <td>{conn.priority}</td>
                    </tr>
                    {conn.id > 0 && <tr >
                        <td colSpan={4}>
                    <IconButton
                                click={() => setConnectorAvailability(conn.id, conn.state)}
                                icon={RebootIcon}
                                text={(
                                    conn.state?.toLowerCase() !== 'unavailable'
                                        ? t('chargePointConnectorMakeUnavailable')
                                        : t('chargePointConnectorMakeAvailable')) as string
                                }
                                title={t('chargePointDetailsDescriptionRename')}
                                type="primary"
                            />
                            </td>
                    </tr> }
                    </React.Fragment>
                ))
        }
    }

    return (
        <Card title={t('chargePointConnectorListTitle')} className={style.card}>
            <table>
                <thead>
                    <tr>
                        <th>{t('chargePointConnectorListId')}</th>
                        <th>{t('chargePointConnectorListState')}</th>
                        <th>{t('chargePointConnectorListError')}</th>
                        <th>{t('chargePointConnectorListLoad')}</th>
                        <th>{t('chargePointConnectorListLimit')}</th>
                        <th>{t('chargePointConnectorListPhaseMapping')}</th>
                        <th>{t('chargePointConnectorListAmpereMin')}</th>
                        <th>{t('chargePointConnectorListAmpereMax')}</th>
                        <th>{t('chargePointConnectorListPriority')}</th>
                    </tr>
                </thead>
                <tbody>{getTableBodyContent()}</tbody>
            </table>
        </Card>
    )
}
