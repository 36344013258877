import { DelayedLoadable, mapSuccessDelayed, useGraphQlDelayed } from 'common'
import { useMemo } from 'react'

const controllerLogsDirectQuery = `
    query ControllerLogs($id: String!, $limit: Int!, $filter: String!) {
        controllerLogs(id: $id, limit: $limit, regexFilter: $filter)
    }
`

export interface ControllerLogsDirectQueryData {
    controllerLogs: string
}

export default function useControllerLogsDirect(
    controllerId: string,
    limit: number,
    regexFilter: string | null
): [DelayedLoadable<string>, () => void] {
    const [state, execute] = useGraphQlDelayed<ControllerLogsDirectQueryData>(
        controllerLogsDirectQuery,
        { id: controllerId, limit, filter: regexFilter }
    )

    return [
        useMemo(() => mapSuccessDelayed(state, (data) => data.controllerLogs), [state]),
        execute,
    ]
}
