import { ValidatedSelectField, ValidatedTextField } from 'common'
import NestableFrame from 'common/components/NestableFrame/NestableFrame'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useCreateSensor from 'config/hooks/useCreateSensor'
import useUpdateSensor from 'config/hooks/useUpdateSensor'
import {
    CurrentSensorType,
    currentSensorTypeValues,
    ModbusModel,
    modbusModelValues,
    SensorConfig,
    SensorInterfaceType,
    sensorInterfaceTypeValues,
} from 'config/types/controllerConfig'
import swapListItem from 'config/utils/swapListElement'
import { numberValidator } from 'config/utils/validators'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'
import TrashCanButton from '../TrashCanButton/TrashCanButton'
import DeleteSensorModal from './DeleteSensorModal'
import style from './SensorConfigList.module.scss'

interface SensorConfigItemProps {
    sensor: SensorConfig
}

export default function SensorConfigItem({ sensor }: SensorConfigItemProps) {
    const isNew = !Number.isInteger(sensor.id)
    const { t } = useTranslation()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [controllerConfig, setControllerConfig] = useControllerConfigState()
    const dispatchPopup = usePopupModal()

    const validClusters = controllerConfig.clusters.filter((x) => Number.isInteger(x.id))

    const [createSensorState, executeCreateSensor] = useCreateSensor()
    const [updateSensorState, executeUpdateSensor] = useUpdateSensor()

    const clusterIdField = useValidatedField(sensor.clusterId.toString(), (value) =>
        validClusters.some((x) => x.id === +value)
    )
    const interfaceTypeField = useValidatedField<SensorInterfaceType | ''>(
        sensor.sensorInterface || '',
        (value) => sensorInterfaceTypeValues.some((x) => x === value)
    )
    const inputVoltageField = useValidatedField(
        sensor.inputVoltage?.toString() || '0',
        numberValidator
    )
    const currentSensorTypeField = useValidatedField(
        sensor.currentSensorType ?? '',
        (value) => currentSensorTypeValues.some((x) => x === value),
        (newValue) => {
            if (newValue === 'CT') {
                inputVoltageField.setValue('333')
            } else if (newValue === 'ROGOWSKI') {
                inputVoltageField.setValue('100')
            }
        }
    )
    const outputCurrentField = useValidatedField(
        sensor.outputCurrent?.toString() || '',
        numberValidator
    )
    const filterField = useValidatedField(sensor.filter || '', () => true)
    const modbusModelField = useValidatedField<ModbusModel | ''>(sensor.model || '', (value) =>
        modbusModelValues.some((x) => x === value)
    )
    const slaveIdField = useValidatedField(sensor.slaveId?.toString() || '0', numberValidator)

    const fields = [
        clusterIdField,
        interfaceTypeField,
        inputVoltageField,
        currentSensorTypeField,
        outputCurrentField,
        filterField,
        modbusModelField,
        slaveIdField,
    ]

    const analogFields = [
        clusterIdField,
        interfaceTypeField,
        inputVoltageField,
        currentSensorTypeField,
        outputCurrentField,
    ]

    const modbusFields = [clusterIdField, interfaceTypeField, modbusModelField, slaveIdField]

    const handleDelete = () => {
        if (isNew) {
            setControllerConfig((prev) => ({
                ...prev,
                sensors: prev.sensors.filter((x) => x !== sensor),
            }))
        } else {
            setShowDeleteModal(true)
        }
    }

    const outputCurrentOptionsCT = [
        '50',
        '100',
        '200',
        '300',
        '350',
        '400',
        '500',
        '800',
        '1000',
        '1500',
        '4000',
    ]
    const outputCurrentOptionsRogowski = ['1000', '1500', '4000']

    const outputCurrentOptions =
        currentSensorTypeField.value === 'CT'
            ? outputCurrentOptionsCT
            : outputCurrentOptionsRogowski

    const inputVoltageOptions = currentSensorTypeField.value === 'CT' ? ['333'] : ['100']

    const resetFields = () => fields.forEach((f) => f.reset())

    const activeFields = interfaceTypeField.value === 'MODBUS' ? modbusFields : analogFields

    const allValid = activeFields.every((field) => field.isValid)
    const anyModified = activeFields.some((field) => field.isModified)

    const isSaving =
        createSensorState.status === 'loading' || updateSensorState.status === 'loading'

    const saveEnabled = allValid && anyModified && !isSaving

    const handleSave = () => {
        const sensorBase = {
            id: sensor.id,
            clusterId: +clusterIdField.value,
            sensorInterface: interfaceTypeField.value as SensorInterfaceType,
            currentSensorType: null,
            inputVoltage: null,
            outputCurrent: null,
            filter: null,
            model: null,
            slaveId: null,
        }
        const newSensor =
            interfaceTypeField.value === 'ANALOG'
                ? {
                      ...sensorBase,
                      currentSensorType: currentSensorTypeField.value as CurrentSensorType,
                      inputVoltage: Number(inputVoltageField.value),
                      outputCurrent: Number(outputCurrentField.value),
                      filter: filterField.value.length > 0 ? filterField.value : null,
                  }
                : {
                      ...sensorBase,
                      model: modbusModelField.value as ModbusModel,
                      slaveId: Number(slaveIdField.value),
                  }

        if (isNew) {
            executeCreateSensor(newSensor)
                .then((res) => {
                    if (Number.isInteger(res?.data?.createSensor.id)) {
                        setControllerConfig((prev) => ({
                            ...prev,
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                            sensors: swapListItem(prev.sensors, sensor, res.data!.createSensor!),
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when creating sensor',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) =>
                    dispatchPopup({
                        title: 'Something went wrong when creating sensor',
                        message: err.toString(),
                    })
                )
        } else {
            executeUpdateSensor(newSensor as typeof newSensor & { id: number })
                .then((res) => {
                    if (Number.isInteger(res?.data?.updateSensor.id)) {
                        setControllerConfig((prev) => ({
                            ...prev,
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                            sensors: swapListItem(prev.sensors, sensor, res.data!.updateSensor!),
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when updating sensor',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) =>
                    dispatchPopup({
                        title: 'Something went wrong when updating sensor',
                        message: err.toString(),
                    })
                )
        }
    }
    return (
        <NestableFrame className={style.itemContainer}>
            <div className={style.inputRow}>
                <ValidatedSelectField
                    field={clusterIdField}
                    title={t('controllerConfigPageSensorFieldCluster')}
                    forceModified={isNew}
                    options={validClusters.map((x) => ({
                        value: x.id!.toString(),
                        text: x.clusterId,
                    }))}
                />
                <ValidatedSelectField
                    field={interfaceTypeField}
                    title={t('controllerConfigPageSensorFieldInterfaceType')}
                    forceModified={isNew}
                    options={sensorInterfaceTypeValues.map((x) => ({ value: x }))}
                    placeholder={t('controllerConfigPageSensorInterfaceTypePlaceholder')}
                />

                <TrashCanButton
                    onClick={handleDelete}
                    title={t('controllerConfigPageSensorDelete')}
                    className={style.trashButton}
                />
            </div>
            {interfaceTypeField.value === 'ANALOG' && (
                <div className={style.inputRow}>
                    <ValidatedSelectField
                        field={currentSensorTypeField}
                        options={currentSensorTypeValues.map((x) => ({ value: x }))}
                        forceModified={isNew}
                        title={t('controllerConfigPageFieldCurrentSensorType')}
                        placeholder={t('controllerConfigPageCurrentSensorTypePlaceholder')}
                    />
                    <ValidatedSelectField
                        field={inputVoltageField}
                        forceModified={isNew}
                        options={inputVoltageOptions.map((x) => ({ value: x }))}
                        title={t('controllerConfigPageSensorFieldInputVoltage')}
                    />
                    <ValidatedSelectField
                        field={outputCurrentField}
                        forceModified={isNew}
                        options={outputCurrentOptions.map((x) => ({ value: x }))}
                        title={t('controllerConfigPageSensorFieldOutputCurrent')}
                        placeholder={t('controllerConfigPageOutputCurrentPlaceholder')}
                    />
                </div>
            )}
            {interfaceTypeField.value === 'MODBUS' && (
                <div className={style.inputRow}>
                    <ValidatedSelectField
                        field={modbusModelField}
                        options={modbusModelValues.map((x) => ({ value: x }))}
                        forceModified={isNew}
                        title={t('controllerConfigPageSensorFieldModbusModel')}
                        placeholder={t('controllerConfigPageModbusModelPlaceholder')}
                    />
                    <ValidatedTextField
                        field={slaveIdField}
                        forceModified={isNew}
                        title={t('controllerConfigPageSensorFieldSlaveId')}
                    />
                </div>
            )}
            <SaveButton
                click={handleSave}
                text={t('controllerConfigPageSensorSave')}
                className={style.saveButton}
                disabled={!saveEnabled}
                loading={isSaving}
            />
            <DeleteSensorModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                sensor={sensor}
            />
        </NestableFrame>
    )
}
