// This hook fetches the devices that were already adopted
// but not yet assigned to a location.

import { mapSuccess, useGraphQl } from 'common'

const GetCompletedProvisioningsQuery = `
     query GetCompletedProvisionings {
         adoptedDevices {
             provisioningId
             serialNumber
             batchName
             provisioningStatus
             hardwareVersion
    }
}
`

export interface IadoptedDevice {
    provisioningId: string
    serialNumber: string
    batchName: string
    provisioningStatus: string
    hardwareVersion: string
}

type GetCompletedProvisioningsQueryData = {
    adoptedDevices: IadoptedDevice[]
}

export default function useGetProvisionedDevices() {
    const [state, refetch] = useGraphQl<GetCompletedProvisioningsQueryData>(GetCompletedProvisioningsQuery)
    return [mapSuccess(state, (data) => data.adoptedDevices), refetch] as const
}
