import { mapSuccessDelayed, useGraphQlDelayed } from 'common'

const controllerLogsQuery = `
    query ControllerLogsAzureQuery(
        $deviceId: String!
        $minimumSeverityLevel: Int
        $limit: Int
        $searchTerm: String
    ) {
        controllerLogsAzure(
            deviceId: $deviceId
            minimumSeverityLevel: $minimumSeverityLevel
            limit: $limit
            searchTerm: $searchTerm
        ) {
            timestamp
            message
            severityLevel
            loggerName
        }
    }
`

export interface ControllerLogsQueryData {
    controllerLogsAzure: {
        timestamp: string
        message: string
        severityLevel: SeverityLevel
        loggerName: string
    }[]
}

export type SeverityLevel = 0 | 1 | 2 | 3

export interface ControllerLog {
    date: string
    logger: string
    severity: number
    message: string
}

export default function useControllerLogs(
    controllerId: string,
    limit: number,
    searchTerm: string | null,
    minimumSeverityLevel: SeverityLevel
) {
    const [state, execute] = useGraphQlDelayed<ControllerLogsQueryData>(
        controllerLogsQuery,
        {
            deviceId: controllerId,
            limit,
            searchTerm,
            minimumSeverityLevel,
        },
        false
    )
    return [
        mapSuccessDelayed(state, (data) =>
            data.controllerLogsAzure.map(
                (log) =>
                    ({
                        date: log.timestamp,
                        logger: log.loggerName,
                        severity: log.severityLevel,
                        message: log.message,
                    } as ControllerLog)
            )
        ),
        execute,
    ] as const
}
