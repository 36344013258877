import { useGraphQlMutation } from 'common'

interface Attribute {
    name: string;
    value: string;
}

interface Device {
    id: string;
    attributes: Attribute[];
    updated_ts: string;
}

interface DevicesResponse {
    devices: Device[];
}

const requestDevicesQuery = `
 mutation GetMenderDevices($status:String!){
               menderDevicesByStatus(status: $status){
                   id
                   updated_ts
                   attributes {
                      name
                      value
                    }
}}
`

export type requestDevicesQueryVariables = { status: string }
export type requestDevicesQueryQueryData = DevicesResponse

export default function useProvisioningDevices() {
    return useGraphQlMutation<requestDevicesQueryQueryData, requestDevicesQueryVariables>(
        requestDevicesQuery
    )
}