import useSetChargerAccessControl from 'chargePoint/hooks/useSetChargerAccessControl'
import { AccessControl, accessControlValues } from 'chargePoint/types/chargePoint'
import { Button, Card } from 'common'
import { useControllerData } from 'controller'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import style from './ChargePointAccessControl.module.scss'

const accessControlPascalCase: Record<AccessControl, string> = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    CONTROLLER_OPEN: 'ControllerOpen',
    CONTROLLER_CLOSED: 'ControllerClosed',
    CONTROLLER_APP: 'ControllerApp',
}

interface ChargePointAccessControlProps {
    controllerId: string
    chargePointId: string
    currentAccessControl?: AccessControl
}

export default function ChargePointAccessControl({
    controllerId,
    chargePointId,
    currentAccessControl,
}: ChargePointAccessControlProps) {
    const { t } = useTranslation()
    const [submitState, executeMutation] = useSetChargerAccessControl()
    const [selectedAccessControl, setSelectedAccessControl] = useState<AccessControl | null>(
        currentAccessControl || null
    )

    useEffect(() => {
        if (currentAccessControl !== selectedAccessControl && currentAccessControl) {
            setSelectedAccessControl(currentAccessControl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAccessControl])

    const controllerData = useControllerData(controllerId)

    const isNotFrigg =
        controllerData.status === 'success' && controllerData.data.controller.erpSystem !== 'frigg'

    const submitButtonEnabled =
        currentAccessControl !== null &&
        selectedAccessControl &&
        selectedAccessControl !== currentAccessControl &&
        submitState.status === 'idle' &&
        isNotFrigg

    return (
        <Card title={t('chargePointAccessControlTitle')} className={style.card}>
            <form className={style.form}>
                <fieldset id="accessControl">
                    {accessControlValues.map((accessControlValue) => (
                        <div key={accessControlValue} className={style.option}>
                            <input
                                id={accessControlValue}
                                type="radio"
                                name="accessControl"
                                disabled={!currentAccessControl}
                                className={style.radio}
                                onChange={() => setSelectedAccessControl(accessControlValue)}
                                checked={accessControlValue === selectedAccessControl}
                            />
                            <label
                                htmlFor={accessControlValue}
                                className={
                                    accessControlValue === currentAccessControl
                                        ? style.currentText
                                        : undefined
                                }
                            >
                                {t(
                                    `chargePointAccessControlOption${accessControlPascalCase[accessControlValue]}`
                                )}
                            </label>
                        </div>
                    ))}
                </fieldset>
                <Button
                    type="primary"
                    click={(e) => {
                        e.preventDefault()
                        executeMutation({
                            input: {
                                deviceId: controllerId,
                                id: chargePointId,
                                accessControl: selectedAccessControl!,
                            },
                        })
                    }}
                    disabled={!submitButtonEnabled}
                >
                    {t('chargePointAccessControlSubmit')}
                </Button>
            </form>
            {submitState.status === 'loading' && <p>{t('chargePointAccessControlSubmitting')}</p>}
            {submitState.status === 'success' && <p>{t('chargePointAccessControlSuccess')}</p>}
            {submitState.status === 'error' && (
                <p>{`Failed to set access control: ${submitState.error}`}</p>
            )}
            {!currentAccessControl && (
                <p className={style.needsConfiguration}>
                    {t('chargePointAccessControlNeedsConfiguration')}
                </p>
            )}
            {!isNotFrigg && controllerData.status === 'success' && (
                <p className={style.needsConfiguration}>
                    {t('chargePointAccessControlCanOnlyBeChangedInFrigg')}
                </p>
            )}
        </Card>
    )
}
