import roundToBinSize from './roundToBinSize'

export default function generateTimestampSequence(
    startDate: string | number,
    endDate: string | number,
    intervalInMinutes: number
): Date[] {
    const timestampIntervalMilliseconds = intervalInMinutes * 60 * 1000

    const timestampStart = roundToBinSize(new Date(startDate).getTime(), intervalInMinutes, 'floor')
    const timestampEnd = roundToBinSize(new Date(endDate).getTime(), intervalInMinutes, 'ceil')

    const numDataPoints = (timestampEnd - timestampStart) / timestampIntervalMilliseconds + 1

    return Array.from(new Array(numDataPoints).keys()).map(
        (i) => new Date(i * timestampIntervalMilliseconds + timestampStart)
    )
}
