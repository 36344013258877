import { curveStepAfter } from '@visx/curve'
import { Axis, DataProvider, Grid, LineSeries, XYChart } from '@visx/xychart'
import { Datum, tickDateFormat, tickDateFormatShort } from 'common'
import useWindowWidth from 'common/hooks/useWindowWidth'
import { useLayoutEffect, useRef, useState } from 'react'
import ChartSelection from './ChartSelection'
import Legend from './Legend'
import LoadChartTooltip from './LoadChartTooltip'

export interface LoadChartProps {
    dataPoints: Datum[]
    dataKeys: string[]
    onZoom?: (startIndex: number, endIndex: number) => void
    onClickLegendItem?: (itemKey: string) => void
}

export default function LoadChart({
    dataPoints,
    dataKeys,
    onZoom,
    onClickLegendItem,
}: LoadChartProps) {
    const [zoomStart, setZoomStart] = useState<number | null>(null)
    const [zoomCursorPos, setZoomCursorPos] = useState<number | null>(null)
    const prevDataPointLengthRef = useRef<number>(dataPoints.length)

    const handleZoom = (index0: number, index1: number) => {
        const startIndex = Math.min(index0, index1)
        const endIndex = Math.max(index0, index1)
        setZoomStart(null)
        setZoomCursorPos(null)
        onZoom && startIndex !== endIndex && onZoom(startIndex, endIndex)
    }

    const windowWidth = useWindowWidth()

    // Update zoom selection indexes when data length changes
    useLayoutEffect(() => {
        if (zoomStart) {
            const lengthRatio = dataPoints.length / prevDataPointLengthRef.current
            setZoomStart(Math.min(Math.round(zoomStart * lengthRatio), dataPoints.length - 1))
            zoomCursorPos &&
                setZoomCursorPos(
                    Math.min(Math.round(zoomCursorPos * lengthRatio), dataPoints.length - 1)
                )
        }
        prevDataPointLengthRef.current = dataPoints.length
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPoints.length])

    const height = 300
    const width = Math.min(windowWidth, 1200) - 96 // 32 padding on each side from MainLayout + 16 padding on each side from Card

    const firstDataKey = dataKeys[0]

    return (
        <DataProvider xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
            <div
                style={{ height: height }}
                onMouseUp={() => {
                    if (zoomStart !== null && zoomCursorPos !== null) {
                        handleZoom(zoomStart, zoomCursorPos)
                    }
                }}
            >
                <XYChart
                    height={height}
                    width={width}
                    onPointerDown={onZoom ? (e) => setZoomStart(e.index) : undefined}
                    onPointerMove={(e) => zoomStart !== null && setZoomCursorPos(e.index)}
                    onPointerUp={(e) =>
                        e.key === firstDataKey && // This event gets fired for every dataKey, so ignore all but the first one
                        zoomStart !== null &&
                        handleZoom(zoomStart, e.index)
                    }
                    onPointerOut={(e) => {
                        const rect = (e.target as HTMLElement).getBoundingClientRect()
                        const isWithinHeight =
                            e.pageY >= rect.top && e.pageY < rect.top + rect.height

                        if (isWithinHeight) {
                            if (e.pageX < rect.left) {
                                setZoomCursorPos(0)
                            } else {
                                setZoomCursorPos(dataPoints.length - 1)
                            }
                        }
                    }}
                    pointerEventsDataKey="all"
                >
                    <Grid columns={false} numTicks={4} />
                    <XAxis dataPoints={dataPoints} chartWidth={width} />
                    <Axis orientation="left" tickFormat={(y) => `${y} A`} numTicks={4} />
                    <LoadChartTooltip dataKeys={dataKeys} showVerticalCrosshair={!zoomStart} />
                    {dataKeys.map((dataKey) => (
                        <LineSeries
                            key={dataKey}
                            dataKey={dataKey}
                            data={dataPoints}
                            curve={curveStepAfter}
                            xAccessor={(x) => x.date}
                            yAccessor={(x) => x.lines[dataKey]?.y}
                        />
                    ))}
                    {zoomStart !== null && zoomCursorPos !== null && (
                        <ChartSelection
                            selectionStart={zoomStart}
                            selectionEnd={zoomCursorPos}
                            chartWidth={width}
                            chartHeight={height}
                            numDatapoints={dataPoints.length}
                        />
                    )}
                </XYChart>
            </div>
            <Legend onClickItem={onClickLegendItem} />
        </DataProvider>
    )
}

function XAxis({ dataPoints, chartWidth }: { dataPoints: Datum[]; chartWidth: number }) {
    const validTickSpacingsInMinutes = [
        1,
        5,
        15,
        30,
        60,
        120,
        180,
        360,
        720,
        1440,
        1440 * 3,
        1440 * 7,
    ]

    const timeRange =
        new Date(dataPoints.at(-1)!.date).getTime() - new Date(dataPoints[0].date).getTime()

    const maxTicks = chartWidth / 100
    const minTimeRangePerTick = timeRange / maxTicks

    const tickSpacing: number =
        validTickSpacingsInMinutes.find((x) => x * 60000 > minTimeRangePerTick) ||
        validTickSpacingsInMinutes.at(-1)!

    const tickValues = dataPoints
        .filter((dataPoint) => {
            const date = new Date(dataPoint.date)
            const minuteOfDay = date.getHours() * 60 + date.getMinutes()
            const epochDay = Math.floor(date.getTime() / (24 * 3600 * 1000))
            const daySpacing = tickSpacing / 1440

            const dayIsOkay = tickSpacing <= 1440 || epochDay % daySpacing === 0

            return minuteOfDay % tickSpacing === 0 && dayIsOkay
        })
        .map((x) => x.date)

    // Don't show weekdays if chart contains more than 10 days
    const tickFormat = timeRange / (24 * 3600 * 1000) > 10 ? tickDateFormatShort : tickDateFormat

    return <Axis orientation="bottom" tickValues={tickValues} tickFormat={tickFormat} />
}
