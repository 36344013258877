import { useGraphQlMutation } from 'common'

const changeAvailabilityQuery = `
mutation ChangeAvailability($deviceId: String!, $chargePointId: String!, $connectorId: Int!, $isAvailable: Boolean!) {
	changeAvailability(input:{deviceId: $deviceId, chargePointId: $chargePointId, connectorId: $connectorId, isAvailable: $isAvailable}) {
		status,
		message,
		success		
	} 
}
`

enum ChangeAvailabilityStatus {
    accepted = "ACCEPTED",
    rejected = "REJECTED",
    scheduled = "SCHEDULED"
}

interface ChangeAvailabilityInput {
    deviceId: string
    chargePointId: string
    connectorId: number
    isAvailable: boolean
}

interface ChangeAvailabilityResponse {
    changeAvailability: {
        status?: ChangeAvailabilityStatus
        message?: string
        success: boolean
    } 
}

export default function useConnectorChangeAvailability() {
    
    return useGraphQlMutation<ChangeAvailabilityResponse, ChangeAvailabilityInput>(
        changeAvailabilityQuery
    )
}
