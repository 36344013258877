import { useGraphQlMutation } from 'common'

const renameChargePointQuery = `
    mutation RenameChargePoint(
        $input: RenameChargePointInput!
    ) {
        renameChargePoint(
            input: $input
        ) {
            success
            message
        }
    }
`

interface RenameChargePointInput {
    input: {
        deviceId: string
        oldIdentifier: string
        newIdentifier: string
        moveTransactions: boolean
    }
}

interface RenameChargePointResponse {
    renameChargePoint: {
        success: boolean
        message?: string
    }
}

export default function useRenameChargePoint() {
    return useGraphQlMutation<RenameChargePointResponse, RenameChargePointInput>(
        renameChargePointQuery
    )
}
