import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { DataContext } from '@visx/xychart'
import { useContext } from 'react'

import style from './Legend.module.scss'

interface LegendProps {
    onClickItem?: (itemText: string) => void
}

export default function Legend({ onClickItem }: LegendProps) {
    const { colorScale, margin } = useContext(DataContext)
    const svgSize = 24
    const lineWidth = 4

    const ItemWrapper = onClickItem ? 'button' : 'div'
    return (
        <LegendOrdinal scale={colorScale!}>
            {(items) => (
                <div className={style.legend} style={{ marginLeft: margin?.left }}>
                    {items.map((item) => (
                        <LegendItem key={item.text}>
                            <ItemWrapper
                                className={style.item}
                                onClick={onClickItem ? () => onClickItem(item.text) : undefined}
                            >
                                <svg width={svgSize} height={svgSize}>
                                    <rect
                                        fill={item.value}
                                        x={0}
                                        y={(svgSize - lineWidth) / 2}
                                        width={svgSize}
                                        height={lineWidth}
                                        rx={2}
                                    />
                                </svg>
                                <LegendLabel align="left" margin="0 4px">
                                    {item.text}
                                </LegendLabel>
                            </ItemWrapper>
                        </LegendItem>
                    ))}
                </div>
            )}
        </LegendOrdinal>
    )
}
