import { ChargePointInitiationInput } from 'chargePoint/hooks/useInitiateChargePoints'
import { Breadcrumb, ControllerPageLink, CurrentPage, OverviewPageLink } from 'common'
import { InitiateChargePoints } from 'controller/components/InitializeChargePoints/InitializeChargePoints'
import ScanComponent from 'controller/components/ScanComponent/ScanComponent'
import useScan, { ScanInput, ScanResponse } from 'controller/hooks/useScan'
import { EditingMode, IKaTableProps, ITableProps, SortingMode, Table, kaReducer } from 'ka-table'
import {
    deselectAllRows,
    deselectRow,
    openEditor,
    selectAllRows,
    selectRow,
    updateData,
} from 'ka-table/actionCreators'
import { ICellProps, ICellTextProps, IHeadCellProps } from 'ka-table/props'
import { DispatchFunc } from 'ka-table/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const SelectionCell: React.FC<ICellTextProps> = ({ rowKeyValue, dispatch, isSelectedRow }) => {
    return (
        <input
            type="checkbox"
            checked={isSelectedRow}
            onChange={(event: any) => {
                if (event.currentTarget.checked) {
                    dispatch(selectRow(rowKeyValue))
                } else {
                    dispatch(deselectRow(rowKeyValue))
                }
            }}
        />
    )
}

const SelectionHeader = ({ props }: { props: IHeadCellProps }) => {
    return (
        <input
            type="checkbox"
            checked={props.areAllRowsSelected}
            onChange={(_) => {
                if (props.areAllRowsSelected) {
                    props.dispatch(deselectAllRows())
                } else {
                    props.dispatch(selectAllRows())
                }
            }}
        />
    )
}

export default function ControllerScanPage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }
    const { t } = useTranslation()

    const scan = useScan()[1]

    const initialTableProps: IKaTableProps = {
        columns: [
            { key: 'selection-cell', style: { width: 2 } },
            { key: 'id', title: t('id'), style: { width: 4 } },
            { key: 'vendor', title: t('vendor'), style: { width: 16 } },
            { key: 'model', title: t('model'), style: { width: 16 } },
            { key: 'ip', title: t('ip'), style: { width: 48 } },
            { key: 'serialNumber', title: t('serialNumber'), style: { width: 96 } },
        ],
        data: [],
        selectedRows: [],
        rowKeyField: 'ip',
        sortingMode: SortingMode.Single,
        editingMode: EditingMode.Cell,
        editableCells: [{ columnKey: 'id', rowKeyValue: '' }],
        childComponents: {
            cell: {
                content: (props: ICellProps) => {
                    switch (props.column.key) {
                        case 'selection-cell':
                            return <SelectionCell {...props} />
                        case 'serialNumber':
                        case 'ip':
                            return (
                                <div className="font-mono text-xs break-words">
                                    {props.rowData[props.column.key]}
                                </div>
                            )
                        case 'id':
                            return props.isEditableCell ? undefined : (
                                <button
                                    className="text-xs break-words select-text"
                                    onClick={() =>
                                        dispatch(openEditor(props.rowData.ip, props.column.key))
                                    }
                                    onKeyUp={(e) => e.stopPropagation()}
                                >
                                    {props.rowData[props.column.key]}
                                </button>
                            )
                        default:
                            return (
                                <div className="text-xs break-words">
                                    {props.rowData[props.column.key]}
                                </div>
                            )
                    }
                },
                elementAttributes: () => ({
                    onKeyUp: (e) => e.stopPropagation(), // Editable ID will immediately lose focus when pressing enter otherwise
                }),
            },

            headCell: {
                content: (props: IHeadCellProps) => {
                    if (props.column.key === 'selection-cell') {
                        return <SelectionHeader props={props} />
                    }
                },
            },
        },
    }

    const [scanning, setScanning] = useState(false)
    const [tableProps, changeTableProps] = useState(initialTableProps)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    function scanFunc(input: ScanInput): Promise<ScanResponse[] | null> {
        return scan(input).then((res) => {
            if (res.error || !res.data) {
                console.error(res)
                return null
            } else {
                return res.data.scan
            }
        })
    }

    function onScan(scanResponses: ScanResponse[]) {
        dispatch(updateData(scanResponses))
    }

    return (
        <>
            <Breadcrumb>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <CurrentPage currentPage={t('controllerScanTitle')} />
            </Breadcrumb>

            <div className="grid grid-cols-10 gap-4 mt-4">
                <div className="col-span-8  border border-gray-200 bg-white">
                    <Table {...tableProps} dispatch={dispatch} />
                </div>
                <div className="col-span-2">
                    <ScanComponent
                        controllerId={controllerId}
                        onScan={onScan}
                        scanFunction={scanFunc}
                        onScanningChanged={setScanning}
                    />
                    <InitiateChargePoints
                        chargePointsToInitiate={
                            tableProps.data
                                ?.filter((row: ScanResponse) =>
                                    tableProps.selectedRows?.includes(row.ip)
                                )
                                .map((row: ScanResponse) => ({
                                    ip: row.ip,
                                    id: row.id,
                                    vendor: row.vendor,
                                })) as ChargePointInitiationInput[]
                        }
                        disabled={scanning}
                        deviceId={controllerId}
                    />
                </div>
            </div>
        </>
    )
}
