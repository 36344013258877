import { Button, Card, SpinnerWithHeight } from 'common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './AssigningTable.module.scss'
import DeviceAdoptingModal from '../../ProvisioningActions/DeviceAdoptingModal'
import useGetProvisionedDevices, { IadoptedDevice } from 'provisioning/hooks/useGetProvisionedDevices'

export default function AssigningTable() {
    const [filter, setFilter] = useState<string>('')
    const [selectedDevice, setSelectedDevice] = useState<IadoptedDevice>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { t } = useTranslation()
    const [adoptedDevices, refreshAdoptedDevices] = useGetProvisionedDevices()

    const deviceFilter = (device: IadoptedDevice) => {
        return (
            device.provisioningStatus.toLowerCase().includes(filter) ||
            device.serialNumber.toLowerCase().includes(filter)
        )
    }

    useEffect(()=>{
        refreshAdoptedDevices()
    }, [isModalOpen, refreshAdoptedDevices])

    const getContent = () => {
        switch (adoptedDevices.status) {
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'error':
                return <div>{`Failed to load list of pending devices ${adoptedDevices.error}`}</div>
            case 'success':
                return (
                    <>
                        <input
                           placeholder={t('searchInputPlaceholder')}
                           className={style.searchInput}
                           onChange={(e) => setFilter(e.target.value.toLowerCase())}
                        />
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('serialNumber')}</th>
                                    <th>{t('batchName')}</th>
                                    <th>{t('hardwareVersion')}</th>
                                    <th>{t('status')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adoptedDevices.data
                                .filter(deviceFilter)
                                    .map((device) => {
                                        return (
                                            <tr
                                                key={device.provisioningId}
                                            >
                                                <td>{device.serialNumber}</td>
                                                <td>{device.batchName}</td>
                                                <td>{device.hardwareVersion}</td>
                                                <td>{device.provisioningStatus}</td>
                                                <td>
                                                    <Button
                                                        type="primary"
                                                        click={(e) => {
                                                            e.preventDefault()
                                                            setIsModalOpen(true);
                                                            setSelectedDevice(device)
                                                        }}
                                                    >
                                                        {t('assignToLocation')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </>
                )
        }
    }

    return (
        <Card>
            {getContent()}
            {isModalOpen && <DeviceAdoptingModal
                show={isModalOpen}
                close={() => {setSelectedDevice(undefined); setIsModalOpen(false); }}
                device={selectedDevice}
            />}
        </Card>
    )
}
