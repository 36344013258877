import { Card, SpinnerWithHeight } from "common"
import { defaultKaTableSort } from "common/utils/kaTableSort"
import { DataType, EditingMode, IKaTableProps, ITableProps, kaReducer, SortingMode, Table } from "ka-table"
import { DispatchFunc } from "ka-table/types"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface PreviewTableProps {
    data: {
        serialNumber: string,
        primaryMac: string,
        secondaryMac: string
}[]
}

export default function PreviewTable({data}: PreviewTableProps) {
    const { t } = useTranslation()
    const [searchText, setSearchText] = useState('')
    const tablePropsInit: IKaTableProps = {
        columns: [
            { key: 'serialNumber', title: t('serialNumber'), dataType: DataType.String, filterRowValue: '' },
            { key: 'primaryMac', title: t('primaryMac') },
            { key: 'secondaryMac', title: t('secondaryMac') },
        ],
        data: data,
        rowKeyField: 'serialNumber',
        sortingMode: SortingMode.Single,
        sort: defaultKaTableSort,
        editingMode: EditingMode.None,
        searchText: searchText,
        search: ({ searchText, rowData, column }) => {
            if (column.key === 'id') {
                return rowData.id.toLowerCase().includes(searchText.toLowerCase())
            } 
                return false
        }
    }

    const [tableProps, changeTableProps] = useState(tablePropsInit)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    useEffect(() => {
        changeTableProps((prevState) => ({
            ...prevState,
            data
        }))
    }, [changeTableProps, data])

    let content: JSX.Element | string = <SpinnerWithHeight height={144} />
            content = (
                <>
                    <div className="flex w-full">
                        <input
                            className="ml-auto max-w-sm my-2"
                            placeholder={t('search')}
                            type="search"
                            value={searchText}
                            onChange={(event) => {
                                setSearchText(event.currentTarget.value)
                                dispatch({ type: 'Search', searchText: event.currentTarget.value })
                            }}
                        />
                    </div>
                    <Table {...tableProps} dispatch={dispatch} />
                </>
            )

    return (
        <Card title={t('devicesBatch')}>
            {content}
        </Card>
    )

}
