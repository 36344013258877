import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import usePingModule from 'controller/hooks/usePingModule'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import style from './ControllerModules.module.scss'

interface PingModalProps {
    show: boolean
    close: () => void
    controllerId: string
    targetModule: string
}

export default function PingModal({ show, close, controllerId, targetModule }: PingModalProps) {
    const { t } = useTranslation()
    const [pingResult, ping, reset] = usePingModule()

    useEffect(() => {
        reset()
        // We don't want reset in the dependency array, as it is not stable when requestState changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetModule, controllerId])

    const handleClose = () => {
        if (pingResult.status === 'success' || pingResult.status === 'error') {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (pingResult.status) {
            case 'idle':
                return (
                    <>
                        {t('controllerModulesPingConfirmModal', {
                            controllerId,
                            module: targetModule,
                        })}
                        <ConfirmModalButtonRow
                            onCancel={close}
                            onConfirm={() => {
                                ping({ deviceId: controllerId, module: targetModule })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to ping module: ${pingResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {pingResult.data.pingModule ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerModulesPingSuccess')}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>Failed to ping module</span>
                        )}{' '}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('controllerModulesPingTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
