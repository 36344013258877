import { useGraphQlMutation } from 'common'

const commissionControllerQuery = `
    mutation CommissionController($deviceId: String!) {
        commissionController(deviceId: $deviceId)
    }
`

interface CommissionControllerInput {
    deviceId: string
}

interface CommissionControllerResponse {
    commissionController: boolean
}

export default function useCommissionController() {
    return useGraphQlMutation<CommissionControllerResponse, CommissionControllerInput>(
        commissionControllerQuery
    )
}
