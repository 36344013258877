import { useGraphQlMutation } from "common";

const requestFirmwareDeleteQuery = `
mutation RemoveFirmware($id:String!, $fw:String!){
    removeFirmware(deviceId:$id, firmware:$fw)
}
`;

type removeFirmwareQueryVariables = { id: string; fw: string };
type removeFirmwareResponse = { removeFirmware: boolean };

export default function useRemoveFirmware() {
    return useGraphQlMutation<removeFirmwareResponse, removeFirmwareQueryVariables>(requestFirmwareDeleteQuery);
}
