import { useGraphQlMutation } from "common";

const requestFirmwareDownloadQuery = `
mutation UpgradeFirmware($deviceId:String!, $chargePointIds:[String!]!, $firmware:String!){
    upgradeFirmware(input:{deviceId:$deviceId, chargePoints:$chargePointIds, firmware:$firmware}){status, chargePoints{id, existingFwVersion, willBeUpgraded, reasonForExclusion}}
  }
`;

export type FirmwareUpgradeStatus = "FILE_NOT_FOUND" | "MANIFEST_NOT_FOUND" | "SUCCESS";
export type FirmwareUpdateResponseItem = { existingFwVersion: string, id: string, reasonForExclusion: string, willBeUpgraded: boolean }

type firmwareUpgradeQueryVariables = { chargePointIds: string[], deviceId: string, firmware: string };
type firmwareUpgradeResponse = { upgradeFirmware: { chargePoints:  FirmwareUpdateResponseItem[] } };

export default function useRequestFirmwareUpgrade() {
    return useGraphQlMutation<firmwareUpgradeResponse, firmwareUpgradeQueryVariables>(requestFirmwareDownloadQuery);
}
