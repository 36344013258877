import { useGraphQlMutation } from 'common'

const updateControllerConfigQuery = `
    mutation UpdateControllerConfig(
        $id: Int!
        $locationId: Int!
        $deviceId: String!
        $name: String!
        $loadBalancingEnabled: Boolean!
        $sensorLoggerEnabled: Boolean!
        $sensorLoggerIntervalMs: Int!
        $isOcppProxyEnabled: Boolean!
        $enableRouting: Boolean!
    ) {
        updateControllerConfig(
            id: $id
            locationId: $locationId
            deviceId: $deviceId
            name: $name
            loadBalancingEnabled: $loadBalancingEnabled
            sensorLoggerEnabled: $sensorLoggerEnabled
            sensorLoggerIntervalMs: $sensorLoggerIntervalMs
            isOcppProxyEnabled: $isOcppProxyEnabled,
            enableRouting: $enableRouting
        ) {
            id
            locationId
            deviceId
            name
            loadBalancingEnabled
            sensorLoggerEnabled
            sensorLoggerIntervalMs
            isOcppProxyEnabled,
            enableRouting
        }
    }
`

interface UpdateControllerConfigInput {
    id: number
    locationId: number
    deviceId: string
    name: string
    loadBalancingEnabled: boolean
    sensorLoggerEnabled: boolean
    sensorLoggerIntervalMs: number,
    isOcppProxyEnabled: boolean,
    enableRouting: boolean
}

interface UpdateControllerConfigResponse {
    updateControllerConfig: {
        id: number
        locationId: number
        deviceId: string
        name: string
        loadBalancingEnabled: boolean
        sensorLoggerEnabled: boolean
        sensorLoggerIntervalMs: number,
        isOcppProxyEnabled: boolean,
        enableRouting: boolean
    }
}

export default function useUpdateControllerConfig() {
    return useGraphQlMutation<UpdateControllerConfigResponse, UpdateControllerConfigInput>(
        updateControllerConfigQuery
    )
}
