import { useGraphQlMutation } from 'common'

const setMigrationStatusQuery = `
    mutation SetMigrationStatus($deviceId:String!, $status:Boolean!){
        setControllerMigrationDoneStatus(deviceId : $deviceId, status: $status){
            status
            message
        }
}`

interface SetMigrationStatusResponseInput {
    deviceId: string
    status: boolean
}

interface MigrationStatusItem {
    status: boolean
    message: string
}

interface UseSetMigrationStatusResponse {
    setControllerMigrationDoneStatus: MigrationStatusItem
}

export default function useSetMigrationStatus() {
    return useGraphQlMutation<UseSetMigrationStatusResponse, SetMigrationStatusResponseInput>(
        setMigrationStatusQuery
    )
}
