export default function dataKeyComparator(a: string, b: string) {
    // Make root always appear first, then sort alphabetically.
    if (a === 'root') {
        return -1
    }
    if (b === 'root') {
        return 1
    }
    if (a < b) {
        return -1
    }
    if (b < a) {
        return 1
    }
    return 0
}
