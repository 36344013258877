import { useGraphQlMutation } from 'common'

const rebootChargePointQuery = `
    mutation RebootChargePoint($deviceId: String!, $chargePointId: String!) {
        rebootChargePoint(deviceId: $deviceId, chargePointId: $chargePointId)
    }
`

interface RebootChargePointInput {
    deviceId: string
    chargePointId: string
}

interface RebootChargePointResponse {
    rebootChargePoint?: boolean
}

export default function useRebootChargePoint() {
    return useGraphQlMutation<RebootChargePointResponse, RebootChargePointInput>(
        rebootChargePointQuery
    )
}
