import { Card, SpinnerWithHeight } from 'common'
import useDhcpLeases from 'controller/hooks/useDhcpLeases'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import style from './DhcpLeases.module.scss'

interface DhcpLeasesProps {
    controllerId: string
}

export default function DhcpLeases({ controllerId }: DhcpLeasesProps): JSX.Element {
    const { t } = useTranslation()

    const req = useDhcpLeases(controllerId)

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return `Failed to load DHCP leases: ${req.error}`
            case 'loading':
                return <SpinnerWithHeight height={120} />
            case 'success':
                return (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('dhcpLeaseIp')}</th>
                                <th>{t('dhcpLeaseMac')}</th>
                                <th>{t('dhcpLeaseClient')}</th>
                                <th>{t('dhcpLeaseDate')}</th>
                                <th>{t('dhcpLeaseChargePoint')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {req.data.map((row) => (
                                <tr key={row.ipAddress}>
                                    <td>{row.ipAddress}</td>
                                    <td>{row.macAddress}</td>
                                    <td>{row.clientId}</td>
                                    <td title={row.date}>
                                        {moment(row.date).format('YYYY-MM-DD HH:mm:ss')}
                                    </td>
                                    <td>{row.chargePointId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
        }
    }

    return (
        <Card className={style.card} title={t('dhcpLeasesTitle')}>
            {getContent()}
        </Card>
    )
}
