import { Loadable, mapSuccess, useGraphQl } from 'common'
import { useMemo } from 'react'

const controllerModulesQuery = `
    query ControllerModulesQuery($id: String!) {
        controller(id: $id) {
            modules {
                name
                state
                updateTime
            }
        }
    }
`

export interface ControllerModulesQueryData {
    controller: {
        modules: {
            name: string
            state: string
            updateTime: string
        }[]
    }
}

type ControllerModulesData = {
    name: string
    state: string
    updateTime: string
}[]

export default function useControllerModules(
    controllerId: string
): Loadable<ControllerModulesData> {
    const [state] = useGraphQl<ControllerModulesQueryData>(
        controllerModulesQuery,
        useMemo(() => ({ id: controllerId }), [controllerId])
    )

    return useMemo(() => mapSuccess(state, (data) => data.controller.modules), [state])
}
