import { Spinner } from 'common'

import style from './SpinnerWithHeight.module.scss'

interface SpinnerWithHeightProps {
    height: number
}

export default function SpinnerWithHeight({ height }: SpinnerWithHeightProps) {
    return (
        <div className={style.container} style={{ height }}>
            <Spinner size={60} />
        </div>
    )
}
