import { Breadcrumb, CurrentPage, IconLink, NavigationBar, OverviewPageLink } from 'common'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import { useTranslation } from 'react-i18next'
import style from './ProvisioningPage.module.scss'
import { ReactComponent as WrenchIcon } from 'img/wrench-cog.svg'
import { ReactComponent as ChipIcon } from 'img/cogBox.svg'
import { useControllerIssues } from 'controller/hooks/useControllerIssues'
import { AssigningTable, ProvisioningTable } from 'provisioning'
import Tabs, { Tab } from 'common/components/Tabs/Tabs'
import { useMemo, useState } from 'react'

export default function ProvisioningPage(): JSX.Element {
    const { t } = useTranslation()
    const req = useControllerIssues()

    const isRequestSuccess = req.status === "success";
    const issuesLinkText = isRequestSuccess && req.data.length > 0 ? `${t('goToIssuesPageButtonTitle')}${isRequestSuccess ? ` (${req.data.length})` : null}` : t('goToIssuesPageButtonTitle');

    const tabsArray: Tab[] = useMemo(() => {
        return [
            {
                text: `${t('Provisioning')}`,
                value: 'provisioning',
            },
            {
                text: `${t('Assigning to location')}`,
                value: 'assigning',
            },
        ];
    }, [t]);

    const [selectedTab, setSelectedTab] = useState<Tab>(tabsArray[0]);

    return (
        <>
            <NavigationBar>
                <Breadcrumb>
                    <OverviewPageLink />
                    <CurrentPage currentPage={t('provisioningPageLink')} />
                </Breadcrumb>
                <IconLink
                    icon={LocationIcon}
                    to="/locations"
                    text={t('overviewPageLocationsLink')}
                />
                <IconLink
                    icon={WrenchIcon}
                    to="/issues"
                    text={issuesLinkText}
                />
                <IconLink
                    icon={ChipIcon}
                    to="/devicesBatch"
                    text={t('insertBatchWithDevicesMenuTitle')}
                />
            </NavigationBar>
            <div className={style.page}>
            <Tabs tabs={tabsArray} activeTab={selectedTab} onTabClick={setSelectedTab} />
            {selectedTab.value === 'provisioning' ? <ProvisioningTable /> : <AssigningTable />}
            </div>
        </>
    )
}
