import { ChargePriority } from 'chargePoint/types/chargePoint'
import { useGraphQlMutation } from 'common'

const updateConnectorQuery = `
    mutation UpdateConnector(
        $id: Int!
        $connectorId: Int!
        $priority: ChargePriority!
        $minAmpere: Float!
        $maxAmpere: Float!
        $phaseMapping: String!
    ) {
        updateConnector(
            id: $id
            connectorId: $connectorId
            priority: $priority
            minAmpere: $minAmpere
            maxAmpere: $maxAmpere
            phaseMapping: $phaseMapping
        ) {
            id
            chargePointId
            connectorId
            priority
            minAmpere
            maxAmpere
            phaseMapping
        }
    }
`

interface UpdateConnectorInput {
    id: number
    connectorId: number
    priority: ChargePriority
    minAmpere: number
    maxAmpere: number
    phaseMapping: string
}

interface UpdateConnectorResponse {
    updateConnector: {
        id: number
        chargePointId: number
        connectorId: number
        priority: ChargePriority
        minAmpere: number
        maxAmpere: number
        phaseMapping: string
    }
}

export default function useUpdateConnector() {
    return useGraphQlMutation<UpdateConnectorResponse, UpdateConnectorInput>(updateConnectorQuery)
}
