import { mapSuccess, useGraphQl } from 'common'
import { BusinessUnit } from 'config/types/controllerConfig'
import { useMemo } from 'react'

const locationsByBuClaimsQuery = `
    query GetLocationsByBusinessUnit($businessUnit: String!) {
        locationsByBusinessUnit(businessUnit: $businessUnit){
           id
           name
           businessUnit
       }
    }
`

type locationsByBuClaimsQueryData = {
    locationsByBusinessUnit: {
        id: number
        deviceId: string
        name: string
    }[]
}

export default function useGetLocationsByBUs(businessUnit: BusinessUnit) {
    const [state, refetch] = useGraphQl<locationsByBuClaimsQueryData>(
        locationsByBuClaimsQuery,
        useMemo(() => ({ businessUnit }), [businessUnit])
    )
    return [
        mapSuccess(state, (data) => data),
        refetch,
    ] as const
}
