import { DelayedLoadable, Loadable } from 'common'

export default function mapSuccess<TDataIn, TDataOut, TError>(
    state: Loadable<TDataIn, TError>,
    mapper: (data: TDataIn) => TDataOut
): Loadable<TDataOut, TError> {
    return state.status === 'success' ? { ...state, data: mapper(state.data) } : state
}

export function mapSuccessDelayed<TDataIn, TDataOut, TError>(
    state: DelayedLoadable<TDataIn, TError>,
    mapper: (data: TDataIn) => TDataOut
): DelayedLoadable<TDataOut, TError> {
    return state.status === 'success' ? { ...state, data: mapper(state.data) } : state
}
