import {
    Breadcrumb,
    Card,
    IconLink,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import {
    CurrentPage,
    LocationListPageLink,
    LocationPageLink,
} from 'common/components/Breadcrumb/Breadcrumb'
import ChargePointConfigList from 'config/components/ChargePointConfigList/ChargePointConfigList'
import ClusterConfigList from 'config/components/ClusterConfigList/ClusterConfigList'
import ControllerDeviceConfig from 'config/components/ControllerDeviceConfig/ControllerDeviceConfig'
import SensorConfigList from 'config/components/SensorConfigList/SensorConfigList'
import useControllerConfig from 'config/hooks/useControllerConfig'
import {
    ControllerConfigContext,
    type ControllerConfigContextType,
} from 'config/hooks/useControllerConfigState'
import { ControllerConfigComposite } from 'config/types/controllerConfig'
import { ReactComponent as DnsIcon } from 'img/dns.svg'
import { ReactComponent as LightningIcon } from 'img/lightning-bolt.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import style from './ControllerConfigPage.module.scss'

export default function ControllerConfigPage() {
    const { t } = useTranslation()
    const { id, locationId } = useParams() as { id: string; locationId: string }
    const [requestState] = useControllerConfig(+id)
    const configStateArray = useState<ControllerConfigComposite>(
        null as any
    ) as ControllerConfigContextType
    const [configState, setConfigState] = configStateArray

    useEffect(() => {
        if (requestState.status === 'success') {
            const sortedConfigState = {
                ...requestState.data.controllerConfig,
                clusters: requestState.data.controllerConfig.clusters
                    .slice()
                    .sort((a, b) => a.id! - b.id!),
                chargePoints: requestState.data.controllerConfig.chargePoints
                    .slice()
                    .sort((a, b) => a.id! - b.id!),
            }
            setConfigState((_) => sortedConfigState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState.status, setConfigState])

    const getContent = () => {
        switch (requestState.status) {
            case 'loading':
                return <SpinnerWithHeight height={300} />
            case 'error':
                return `Failed to load controller config: ${requestState.error}`
            case 'success':
                return configState !== null ? (
                    <ControllerConfigContext.Provider value={configStateArray}>
                        <h3>{t('controllerConfigPageDeviceConfig')}</h3>
                        <ControllerDeviceConfig controller={configState.controller} />
                        <h3>{t('controllerConfigPageSensors')}</h3>
                        <SensorConfigList sensors={configState.sensors} />
                        <h3>{t('controllerConfigPageClusters')}</h3>
                        <ClusterConfigList clusters={configState.clusters} />
                        <h3>{t('controllerConfigPageChargePoints')}</h3>
                        <ChargePointConfigList chargePoints={configState.chargePoints} />
                    </ControllerConfigContext.Provider>
                ) : (
                    <SpinnerWithHeight height={300} />
                )
        }
    }

    const locationName =
        requestState.status === 'success' ? requestState.data.controllerConfig.location.name : null

    const deviceId =
        requestState.status === 'success'
            ? requestState.data.controllerConfig.controller.deviceId
            : null

    return (
        <>
            <NavigationBar>
                <Breadcrumb className={style.breadcrumb}>
                    <OverviewPageLink />
                    <LocationListPageLink />
                    <LocationPageLink locationId={locationId} locationName={locationName} />
                    <CurrentPage
                        currentPage={
                            requestState.status === 'success'
                                ? requestState.data.controllerConfig.controller.deviceId
                                : id
                        }
                    />
                </Breadcrumb>
                {deviceId && (
                    <IconLink
                        to={`/location/${locationId}/controller/${id}/electrician`}
                        icon={LightningIcon}
                        text={t('controllerConfigToToElectricianView')}
                    />
                )}
                {deviceId && (
                    <IconLink
                        to={`/controller/${deviceId}`}
                        icon={DnsIcon}
                        text={t('controllerConfigGotoLiveView')}
                    />
                )}
            </NavigationBar>
            <Card title={t('controllerConfigPageTitle')}>{getContent()}</Card>
        </>
    )
}
