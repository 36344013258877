import useRequestDiagnosticFiles from 'chargePoint/hooks/useRequestDiagnosticFiles'
import { Breadcrumb, ControllerPageLink, CurrentPage, OverviewPageLink } from 'common'
import { DataType, IKaTableProps, ITableProps, SortingMode, Table, kaReducer } from 'ka-table'
import { updateData } from 'ka-table/actionCreators'
import { ICellProps } from 'ka-table/props'
import { DispatchFunc } from 'ka-table/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReactComponent as FileDownload } from 'img/file-download.svg'
import useRequestDiagnostics from 'chargePoint/hooks/useRequestDiagnostics'
import { ChargePointPageLink } from 'common/components/Breadcrumb/Breadcrumb'
import { subDays } from 'date-fns'
import usePopupModal from 'common/hooks/usePopupModal'
import { Tooltip } from 'react-tooltip'
import useChargePointData from 'chargePoint/hooks/useChargePointData'
import SaveButton from 'config/components/SaveButton/SaveButton'
import { ReactComponent as WrenchCogIcon } from 'img/wrench-cog.svg'

export default function ChargePointDiagnosticsPage(): JSX.Element {
    const { controllerId, chargePointId } = useParams() as {
        controllerId: string
        chargePointId: string
    }

    const { t } = useTranslation()

    const [diagnosticFilesLoadable, refreshDiagnosticFiles] = useRequestDiagnosticFiles(
        controllerId,
        chargePointId
    )

    const requestDiagnosticsRaw = useRequestDiagnostics()[1]
    function requestDiagnostics({ from, to }: { from: Date; to: Date }) {
        return requestDiagnosticsRaw({
            deviceId: controllerId,
            chargePointId: chargePointId,
            from: from.toISOString(),
            to: to.toISOString(),
        })
    }

    const tablePropsInit: IKaTableProps = {
        columns: [
            { key: 'name', title: t('name'), style: { width: 128 } },
            {
                key: 'lastModified',
                title: t('lastModified'),
                dataType: DataType.Date,
                style: { width: 48 },
            },
            { key: 'uri', style: { width: 8 } },
        ],
        data: [],
        sortingMode: SortingMode.Single,
        rowKeyField: 'name',
        childComponents: {
            cell: {
                content: (props: ICellProps) => {
                    if (props.column.key === 'uri') {
                        return (
                            <a href={props.value}>
                                <FileDownload className="w-6 ml-auto fill-gray-500 hover:fill-blue-400" />
                            </a>
                        )
                    }
                    const value = props.rowData[props.column.key].toString()
                    return (
                        <div>
                            <div id={value} className="truncate">
                                {value}
                            </div>
                            <Tooltip anchorId={value} content={value} />
                        </div>
                    )
                },
            },
        },
        format: ({ column, value }) => {
            if (column.dataType === DataType.Date) {
                return value && value.toLocaleString('nb-NO')
            }
        },
    }

    const [tableProps, changeTableProps] = useState(tablePropsInit)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    useEffect(() => {
        if (diagnosticFilesLoadable.status === 'success') {
            const data = diagnosticFilesLoadable.data
            if (tableProps.data?.length !== data.length) {
                setIsLoading(false)
            }
            dispatch(updateData(data))
        }
    }, [diagnosticFilesLoadable, tableProps.data?.length])

    const [fromDate, setFromDate] = useState(new Date())

    function onDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        const numDays = Number(e.target.value)
        setFromDate(subDays(new Date(), numDays))
    }

    const [isLoading, setIsLoading] = useState(false)

    const dispatchPopup = usePopupModal()

    const [chargePointDataLoadable, refreshChargePointData] = useChargePointData(
        controllerId,
        chargePointId
    )

    useEffect(() => {
        const interval = setInterval(() => {
            if (isLoading && chargePointDataLoadable.status === 'success') {
                const chargePointData = chargePointDataLoadable.data
                switch (chargePointData.chargePoint.diagnosticStatus) {
                    case 'UPLOADING':
                        refreshChargePointData()
                        break
                    case 'UPLOADED':
                        refreshDiagnosticFiles()
                        setIsLoading(false)
                        break
                }
            }
        }, 15_000)

        return () => clearInterval(interval)
    }, [isLoading, chargePointDataLoadable.status, refreshChargePointData, refreshDiagnosticFiles, chargePointDataLoadable])

    return (
        <>
            <div className="flex justify-between">
                <Breadcrumb>
                    <OverviewPageLink />
                    <ControllerPageLink controllerId={controllerId} />
                    <ChargePointPageLink
                        controllerId={controllerId}
                        chargePointId={chargePointId}
                    />
                    <CurrentPage currentPage={t('chargePointDiagnosticsTitle')} />
                </Breadcrumb>
                <div className="flex flex-row gap-2">
                    <div>
                        <div className="font-light text-sm">{t('daysToRequest')}</div>
                        <input
                            className="w-28"
                            type="number"
                            onChange={onDateChange}
                            defaultValue={1}
                        />
                    </div>
                    <SaveButton
                        className="h-full fill-white"
                        icon={WrenchCogIcon}
                        click={() => {
                            setIsLoading(true)
                            requestDiagnostics({ from: fromDate, to: new Date() })
                                .then((res) => {
                                    if (!res.data?.requestDiagnostics || res.error) {
                                        setIsLoading(false)
                                        dispatchPopup({ message: t('failedToRequestDiagnostics') })
                                    }
                                })
                                .catch(() => {
                                    setIsLoading(false)
                                    dispatchPopup({ message: t('failedToRequestDiagnostics') })
                                })
                        }}
                        loading={isLoading}
                        disabled={isLoading}
                        text={t('requestDiagnostics')}
                    />
                </div>
            </div>
            <div className="pt-4">
                <Table {...tableProps} />
            </div>
        </>
    )
}
