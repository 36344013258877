import { Link } from 'react-router-dom'

import style from './IconLink.module.scss'

interface IconLinkProps {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    text: string
    to: string
}

export default function IconLink({ icon: Icon, text, to }: IconLinkProps) {
    return (
        <Link to={to} className={style.link}>
            <Icon className={style.icon} />
            <span>{text}</span>
        </Link>
    )
}
