import { AccessControl } from 'chargePoint'
import { ChargePriority } from 'chargePoint/types/chargePoint'
import { Location } from 'location/types/location'

export interface ConnectorConfig {
    id?: number
    chargePointId: number
    connectorId: number
    minAmpere: number
    maxAmpere: number
    phaseMapping: string
    priority: ChargePriority
    reactKey?: number // Needed react key since id doesn't exist for new items
}

export interface ChargePointConfig {
    id?: number
    clusterId: number
    chargePointId: string
    accessControl: AccessControl
    connectors: ConnectorConfig[]
    reactKey?: number // Needed react key since id doesn't exist for new items
}

export const netTypeValues = ['IT', 'TN', 'TT'] as const
export const sensorInterfaceTypeValues = ['ANALOG', 'MODBUS'] as const
export const currentSensorTypeValues = ['CT', 'ROGOWSKI'] as const
export const modbusModelValues = ['CARLO_GAVAZZI_EM210', 'SCHNEIDER_IEM3555'] as const
export const businessUnitValues = ['MER_NORWAY', 'MER_SWEDEN', 'MER_GERMAY', 'MER_UNITED_KINGDOM'] as const

export enum EBusinessUnits {
    MER_NORWAY = "MerNorway",
    MER_SWEDEN = "MerSweden",
    MER_GERMAY = "MerGermany",
    MER_UNITED_KINGDOM = "MerUnitedKingdom",
}


export type NetType = (typeof netTypeValues)[number]
export type SensorInterfaceType = (typeof sensorInterfaceTypeValues)[number]
export type CurrentSensorType = (typeof currentSensorTypeValues)[number]
export type ModbusModel = (typeof modbusModelValues)[number]
export type BusinessUnit = (typeof businessUnitValues)[number]

export interface SensorConfig {
    id?: number
    clusterId: number
    sensorInterface: SensorInterfaceType | null
    currentSensorType: CurrentSensorType | null
    inputVoltage: number | null
    outputCurrent: number | null
    filter: string | null
    model: ModbusModel | null
    slaveId: number | null
    reactKey?: number // Needed react key since id doesn't exist for new items
}

export interface ClusterConfig {
    id?: number
    clusterId: string
    controllerId: number
    fuseLimit: number
    headroom: number
    netType: NetType
    parentId: number | null
    reactKey?: number // Needed react key since id doesn't exist for new items
}

export interface ControllerConfig {
    id: number
    deviceId: string
    locationId: number
    name: string
    loadBalancingEnabled: boolean
    sensorLoggerEnabled: boolean
    sensorLoggerIntervalMs: number
    isOcppProxyEnabled: boolean
    enableRouting: boolean
    isDirty: boolean
    isCommissioned: boolean
}

export interface ControllerConfigComposite {
    location: Location
    controller: ControllerConfig
    clusters: ClusterConfig[]
    chargePoints: ChargePointConfig[]
    sensors: SensorConfig[]
}
