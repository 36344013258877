import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './Breadcrumb.module.scss'

export function OverviewPageLink(): JSX.Element {
    const { t } = useTranslation()

    return (
        <li>
            <Link to="/">{t('breadcrumbOverviewPageLink')}</Link>
        </li>
    )
}

interface ControllerPageLinkProps {
    controllerId: string
}

export function ControllerPageLink({ controllerId }: ControllerPageLinkProps): JSX.Element {
    return (
        <li>
            <Link to={`/controller/${controllerId}`}>{controllerId}</Link>
        </li>
    )
}

interface ChargePointPageLinkProps {
    controllerId: string
    chargePointId: string
}

export function ChargePointPageLink({ controllerId, chargePointId }: ChargePointPageLinkProps): JSX.Element {
    return (
        <li>
            <Link to={`/controller/${controllerId}/chargepoint/${chargePointId}`}>{chargePointId}</Link>
        </li>
    )
}

interface ControllerLogsPageLinkProps {
    controllerId: string
}

export function ControllerLogsPageLink({ controllerId }: ControllerLogsPageLinkProps): JSX.Element {
    const { t } = useTranslation()

    return (
        <li>
            <Link to={`/controller/${controllerId}/logs`}>
                {t('breadcrumbControllerLogsPageLink')}
            </Link>
        </li>
    )
}

interface LocationPageLinkProps {
    locationId: string
    locationName: string | null
}

export function LocationPageLink({ locationId, locationName }: LocationPageLinkProps): JSX.Element {
    return (
        <li>
            <Link to={`/location/${locationId}`}>{locationName || locationId}</Link>
        </li>
    )
}

export function LocationListPageLink(): JSX.Element {
    const { t } = useTranslation()
    return (
        <li>
            <Link to={`/locations/`}>{t('breadcrumbLocationListPageLink')}</Link>
        </li>
    )
}

export function DevicesBatchPageLink(): JSX.Element {
    const { t } = useTranslation()
    return (
        <li>
            <Link to={`/devicesBatch`}>{t('insertBatchWithDevicesMenuTitle')}</Link>
        </li>
    )
}

interface CurrentPageProps {
    currentPage: string
}

export function CurrentPage({ currentPage }: CurrentPageProps): JSX.Element {
    return <li>{currentPage}</li>
}

interface BreadcrumbProps {
    children: JSX.Element | JSX.Element[]
    className?: string
}

export function Breadcrumb({ children, className }: BreadcrumbProps): JSX.Element {
    const classes = classNames(style.breadcrumb, { [`${className}`]: className })

    return <ul className={classes}>{children}</ul>
}
