import { useGraphQlMutation } from 'common'

const setOcppServerQuery = `
    mutation SetOcppServer($input:SetOcppServerOptionsInput!, $deviceId : String!){
        setOcppServer(deviceId: $deviceId, options: $input){
            success
            message
            identity
        }
}`

interface SetOcppServerOptionsInput {
    deviceId: string
    input: {
        serverUri: string
    }
}

interface OcppServerItem {
    identity: string
    success: boolean
    message: string
}

interface UseSetOcppServerResponse {
    setOcppServer: OcppServerItem[]
}

export default function useSetOcppServer() {
    return useGraphQlMutation<UseSetOcppServerResponse, SetOcppServerOptionsInput>(
        setOcppServerQuery
    )
}
