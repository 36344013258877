import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import Modal from '../Modal/Modal'

import style from './ConfirmModal.module.scss'

interface ConfirmModalProps {
    show: boolean
    close: () => void
    onConfirm: () => void
    showButtons?: boolean
    children?: ReactNode
    className?: string
}

export default function ConfirmModal({
    show,
    close,
    children,
    onConfirm,
    showButtons = true,
    className,
}: ConfirmModalProps) {
    return (
        <Modal show={show} onClickOutside={close} className={className}>
            {children}
            {showButtons && <ConfirmModalButtonRow onConfirm={onConfirm} onCancel={close} />}
        </Modal>
    )
}

interface ConfirmModalButtonRowProps {
    onConfirm: () => void
    onCancel: () => void
}

export function ConfirmModalButtonRow({ onConfirm, onCancel }: ConfirmModalButtonRowProps) {
    const { t } = useTranslation()
    return (
        <div className={style.buttonRow}>
            <Button type="secondary" click={onCancel}>
                {t('confirmModalCancel')}
            </Button>
            <Button type="primary" click={onConfirm}>
                {t('confirmModalConfirm')}
            </Button>
        </div>
    )
}
