export interface ChargePoint {
    id: string
    controllerId: string
    accessControl?: AccessControl
    clusterId?: string
    ip?: string
    isConnected?: boolean
    vendor?: string
    firmwareVersion?: string
    serialNumber?: string
    model?: string
    firmwareStatus?: string
    diagnosticStatus?: string
    connectors: Connector[]
}

export interface Connector {
    id: number
    minAmpere?: number
    maxAmpere?: number
    phaseMapping?: string
    priority?: ChargePriority
    loadLimit?: number
    state?: string
    errorCode?: string
}

export const accessControlValues = [
    'CONTROLLER_OPEN',
    'CONTROLLER_CLOSED',
    'CONTROLLER_APP',
    'OPEN',
    'CLOSED',
] as const

export type AccessControl = (typeof accessControlValues)[number]

export const chargePriorityValues = ['LOW', 'NORMAL', 'HIGH'] as const

export type ChargePriority = (typeof chargePriorityValues)[number]
