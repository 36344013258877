import { AccessControl } from 'chargePoint'
import { useGraphQlMutation } from 'common'

const approveChargePointSetupQuery = `
    mutation ApproveChargePointSetup($id: Int!, $accessControlType: AccessControlType!) {
        approveChargePointSetup(id: $id, accessControlType: $accessControlType)
    }
`

interface ApproveChargePointSetupInput {
    id: number
    accessControlType: AccessControl
}

interface ApproveChargePointSetupResponse {
    approveChargePointSetup?: boolean
}

export default function useApproveChargePointSetup() {
    return useGraphQlMutation<ApproveChargePointSetupResponse, ApproveChargePointSetupInput>(
        approveChargePointSetupQuery
    )
}
