import { useGraphQlMutation } from 'common'
import { BusinessUnit } from 'config/types/controllerConfig'

const createLocationQuery = `
    mutation CreateLocation(
        $name: String!
        $businessUnit: BusinessUnit!
    ) {
        createLocation(
            name: $name
            businessUnit: $businessUnit
        ) {
            id
            name
            businessUnit
        }
    }
`

interface CreateLocationInput {
    name: string
    businessUnit: BusinessUnit
}

interface CreateLocationResponse {
    createLocation: {
        id: number
        name: string
        businessUnit: BusinessUnit
    }
}

export default function useCreateLocation() {
    return useGraphQlMutation<CreateLocationResponse, CreateLocationInput>(createLocationQuery)
}
