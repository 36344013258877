import { ChargePointList } from 'chargePoint'
import {
    Breadcrumb,
    Card,
    CurrentPage,
    IconLink,
    Loadable,
    mapSuccess,
    NavigationBar,
    OverviewPageLink,
    SpinnerWithHeight,
} from 'common'
import ClusterList from 'controller/components/ClusterList/ClusterList'
import ClusterLoad from 'controller/components/ClusterLoad/ClusterLoad'
import ConnectorLoad from 'controller/components/ConnectorLoad/ConnectorLoad'
import ControllerDetails from 'controller/components/ControllerDetails/ControllerDetails'
import useControllerData from 'controller/hooks/useControllerData'
import useControllerConnectionState from 'chargePoint/hooks/useControllerConnectionState'
import ZoomableStateTimelineChart from 'chargePoint/components/ZoomableStateTimelineChart/ZoomableStateTimelineChart'
import { ReactComponent as ChipIcon } from 'img/chip.svg'
import { ReactComponent as CogBox } from 'img/cogBox.svg'
import { ReactComponent as DnsIcon } from 'img/dns.svg'
import { ReactComponent as TextListIcon } from 'img/formatListText.svg'
import { ReactComponent as RadarIcon } from 'img/radar.svg'
import { ReactComponent as ServerIcon } from 'img/server_icon.svg'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import moment from 'moment'
import style from './ControllerPage.module.scss'
import { ZoomProvider } from 'controller/contexts/ZoomContext'

interface DataTimeRange {
    dataTimeRange: 7 | 30
    startDate: string
    endDate: string
}

export default function ControllerPage(): JSX.Element {
    const { controllerId } = useParams() as { controllerId: string }

    const { t } = useTranslation()

    const req = useControllerData(controllerId)

    const isRequestSuccess = req.status === 'success'
    const configId = isRequestSuccess ? req.data.controller.configId : null
    const locationId = isRequestSuccess ? req.data.controller.locationId : null
    const expandSelectedTimeRange = (newTimeRange: 7 | 30) => {
        if (newTimeRange > dataTimeRange) {
            setDataTimeRange({
                dataTimeRange: newTimeRange,
                startDate: moment().subtract(newTimeRange, 'days').toISOString(),
                endDate: moment().toISOString(),
            })
        }
    }
    const [{ dataTimeRange, startDate, endDate }, setDataTimeRange] = useState<DataTimeRange>(
        () => ({
            dataTimeRange: 7,
            startDate: moment().subtract(7, 'days').toISOString(),
            endDate: moment().toISOString(),
        })
    )
    const getChartLoadingOrErrorContent = (chartData: Loadable<unknown>) => {
        switch (chartData.status) {
            case 'error':
                return `Failed to load chart data: ${chartData.error}`
            case 'loading':
                return <SpinnerWithHeight height={163} />
        }
    }
    const connectionTimeline = useControllerConnectionState(controllerId, startDate, endDate)
    return (
        <>
            <NavigationBar>
                <Breadcrumb>
                    <OverviewPageLink />
                    <CurrentPage currentPage={controllerId} />
                </Breadcrumb>
                {configId && locationId && (
                    <IconLink
                        to={`/location/${locationId}/controller/${configId}/config`}
                        icon={CogBox}
                        text={t('controllerGotoConfig')}
                    />
                )}
                <IconLink
                    to={`/controller/${controllerId}/ocpp`}
                    icon={ServerIcon}
                    text={t('controllerOcppTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/logs`}
                    icon={TextListIcon}
                    text={t('controllerLogsTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/details`}
                    icon={DnsIcon}
                    text={t('deviceDetailsTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/scan`}
                    icon={RadarIcon}
                    text={t('controllerScanTitle')}
                />
                <IconLink
                    to={`/controller/${controllerId}/firmware`}
                    icon={ChipIcon}
                    text={t('controllerFirmwareTitle')}
                />
            </NavigationBar>
            <div className={style.page}>
                <ControllerDetails controller={mapSuccess(req, (st) => st.controller)} />

                <ClusterList clusters={mapSuccess(req, (st) => st.clusters)} />
                <Card className={style.chargePoints} title={t('connectionStatusTitle')}>
                    {connectionTimeline.status === 'success' ? (
                        <ZoomableStateTimelineChart
                            stateTimeline={connectionTimeline.data}
                            expandSelectedTimeRange={expandSelectedTimeRange}
                        />
                    ) : (
                        getChartLoadingOrErrorContent(connectionTimeline)
                    )}
                </Card>
                <ChargePointList
                    chargePoints={mapSuccess(req, (st) => st.chargePoints)}
                    className={style.chargePoints}
                />
                <ZoomProvider>
                    <>
                        <ClusterLoad controllerId={controllerId} className={style.clusterLoad} />
                        <ConnectorLoad
                            controllerId={controllerId}
                            className={style.connectorLoad}
                        />
                    </>
                </ZoomProvider>
            </div>
        </>
    )
}
