import { IconButton } from 'common'
import { ClusterConfig } from 'config/types/controllerConfig'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusIcon } from 'img/plus.svg'
import ClusterConfigItem from '../ClusterConfigItem/ClusterConfigItem'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import generateReactKey from 'config/utils/generateReactKey'

interface ClusterConfigListProps {
    clusters: ClusterConfig[]
}

export default function ClusterConfigList({ clusters }: ClusterConfigListProps) {
    const { t } = useTranslation()
    const rootClusters = clusters.filter((cluster) => !cluster.parentId)

    const [controllerConfig, setControllerConfig] = useControllerConfigState()

    const handleAddRoot = () => {
        setControllerConfig((prev) => ({
            ...prev,
            clusters: [
                ...prev.clusters,
                {
                    clusterId: 'root',
                    parentId: null,
                    controllerId: controllerConfig.controller.id,
                    fuseLimit: 0,
                    headroom: 0,
                    netType: 'TN',
                    reactKey: generateReactKey(),
                },
            ],
        }))
    }
    return (
        <div>
            {rootClusters.map((cluster) => (
                <ClusterConfigItem
                    key={`cluster-${cluster.reactKey || cluster.id}`}
                    config={cluster}
                />
            ))}
            {rootClusters.length === 0 && (
                <IconButton
                    text={t('controllerConfigPageClusterAddRoot')}
                    type="secondary"
                    icon={PlusIcon}
                    /* className={style.inputRow}*/
                    click={handleAddRoot}
                />
            )}
        </div>
    )
}
