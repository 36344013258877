import { mapSuccess, useGraphQl } from "common";
import { FirmwareManifest } from "../types/firmwaremanifest";

const availableFirmwareQuery = `
query AvailableFirmware {
    availableFirmware {
       version
       filename
       hash
       vendor
       model
       validUpgradeVersions
   }
}
`;

type AvailableFirmwareQueryData = { availableFirmware: FirmwareManifest[] };

export default function useAvailableFirmware() {
    const [state, reexecuteQuery] = useGraphQl<AvailableFirmwareQueryData>(availableFirmwareQuery);
    return [mapSuccess(state, (data) => data.availableFirmware), reexecuteQuery] as const;
}
