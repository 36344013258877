import { authExchange } from '@urql/exchange-auth'
import { msalInstance } from 'auth'
import isMsalExpiryError from 'common/utils/isMsalAuthError'
import { config } from 'config'
import { createClient, dedupExchange, fetchExchange, Operation } from 'urql'

type AuthState = { expireTime: Date; token: string } | null

function addAuthToOperation({
    authState,
    operation,
}: {
    authState: AuthState
    operation: Operation
}) {
    if (!authState) {
        return operation
    }

    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}

    return {
        ...operation,
        context: {
            ...operation.context,
            fetchOptions: {
                ...fetchOptions,
                headers: {
                    ...fetchOptions.headers,
                    Authorization: `Bearer ${authState.token}`,
                },
            },
        },
    }
}

async function getAuth(): Promise<AuthState> {
    const msalAccount = msalInstance.getAllAccounts()[0]

    if (!msalAccount) {
        return Promise.resolve(null)
    }
    const msalArgs = {
        scopes: [process.env.REACT_APP_BACKEND_API_SCOPE as string],
        account: msalInstance.getAllAccounts()[0],
    }
    return msalInstance
        .acquireTokenSilent(msalArgs)
        .catch((error) => {
            if (isMsalExpiryError(error)) {
                msalInstance.acquireTokenRedirect({
                    ...msalArgs,
                    redirectUri: config.auth.redirectUri,
                })
            }
            throw error
        })
        .then((res) => ({ token: res.accessToken, expireTime: res.expiresOn! }))
        .catch(() => null)
}

function willAuthError({ authState }: { authState: AuthState }) {
    return !authState || authState.expireTime < new Date()
}

const client = createClient({
    url: `${process.env.REACT_APP_BACKEND_API_URL}/graphql/`,
    exchanges: [
        dedupExchange,
        authExchange({ addAuthToOperation, getAuth, willAuthError }),
        fetchExchange,
    ],
})

export default client
