import { mapSuccess, useGraphQl } from 'common'
import { Location } from 'location/types/location'

const locationListQuery = `
query LocationsListQuery {
    locations {
        id
        name
        businessUnit
    }
}
`

type LocationListQueryData = { locations: Location[] }

export default function useLocation() {
    const [state, refetch] = useGraphQl<LocationListQueryData>(locationListQuery)
    return [mapSuccess(state, (data) => data.locations), refetch] as const
}
