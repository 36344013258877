import { AccessControl } from 'chargePoint/types/chargePoint'
import { Transaction } from 'chargePoint/types/transaction'
import { Loadable, mapSuccess, useGraphQl } from 'common'
import { useMemo } from 'react'

const transactionListQuery = `
    query GetTransactions($id: String!, $chargePointId: String, $con: Int) {
        controller(id: $id) {
            transactions(chargePoint: $chargePointId, connector: $con) {
                transactionId
                chargePoint
                connector
                access
                isCompleted
                meterStartWh
                meterStopWh
                reason
                startIdTag
                stopIdTag
                startTimestamp
                stopTimestamp
                startErp
                stopErp
            }
        }
    }
`

interface TransactionListResponse {
    controller: {
        transactions: {
            transactionId: string
            chargePoint: string
            connector: number
            access: AccessControl
            isCompleted: boolean
            meterStartWh: number
            meterStopWh: number | null
            reason: string | null
            startIdTag: string
            stopIdTag: string | null
            startTimestamp: string
            stopTimestamp: string | null
            startErp: string
            stopErp: string | null
        }[]
    }
}

export default function useChargePointTransactionList(
    controllerId: string,
    chargePointId: string
): Loadable<Transaction[]> {
    const [req] = useGraphQl<TransactionListResponse>(
        transactionListQuery,
        useMemo(
            () => ({ id: controllerId, chargePointId, con: null }),
            [controllerId, chargePointId]
        )
    )

    return useMemo(
        () =>
            mapSuccess(req, (st) =>
                st.controller.transactions.map((tx) => ({
                    transactionId: tx.transactionId,
                    chargePointId: tx.chargePoint,
                    connector: tx.connector,
                    access: tx.access,
                    isCompleted: tx.isCompleted,
                    meterStartWh: tx.meterStartWh,
                    meterStopWh: tx.meterStopWh,
                    reason: tx.reason,
                    startIdTag: tx.startIdTag,
                    stopIdTag: tx.stopIdTag,
                    startTimestamp: tx.startTimestamp,
                    stopTimestamp: tx.stopTimestamp,
                    startErp: tx.startErp,
                    stopErp: tx.stopErp,
                }))
            ),
        [req]
    )
}
