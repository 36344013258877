import { accessControlValues } from 'chargePoint/types/chargePoint'
import { ValidatedSelectField, ValidatedTextField } from 'common'
import NestableFrame from 'common/components/NestableFrame/NestableFrame'
import usePopupModal from 'common/hooks/usePopupModal'
import useValidatedField from 'common/hooks/useValidatedField'
import useControllerConfigState from 'config/hooks/useControllerConfigState'
import useCreateChargePoint from 'config/hooks/useCreateChargePoint'
import useUpdateChargePoint from 'config/hooks/useUpdateChargePoint'
import { ChargePointConfig } from 'config/types/controllerConfig'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConnectorConfigList from '../ConnectorConfigList/ConnectorConfigList'
import SaveButton from '../SaveButton/SaveButton'
import TrashCanButton from '../TrashCanButton/TrashCanButton'
import style from './ChargePointConfigItem.module.scss'
import DeleteChargePointModal from './DeleteChargePointModal'

interface ChargePointConfigItemProps {
    chargePoint: ChargePointConfig
}

export default function ChargePointConfigItem({ chargePoint }: ChargePointConfigItemProps) {
    const isNew = !Number.isInteger(chargePoint.id)
    const { t } = useTranslation()
    const [controllerConfig, setControllerConfig] = useControllerConfigState()
    const chargePointIdField = useValidatedField(
        chargePoint.chargePointId,
        (value) => value.length > 0
    )
    const accessControlField = useValidatedField(chargePoint.accessControl, () => true)

    const validClusters = controllerConfig.clusters.filter((x) => Number.isInteger(x.id))

    const clusterIdField = useValidatedField(chargePoint.clusterId.toString(), () => true)

    const dispatchPopup = usePopupModal()

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [createChargePointState, executeCreateChargePoint] = useCreateChargePoint()
    const [updateChargePointState, executeUpdateChargePoint] = useUpdateChargePoint()

    const chargePointFields = [chargePointIdField, accessControlField, clusterIdField]

    const allValid = chargePointFields.every((x) => x.isValid)
    const anyModified = chargePointFields.some((x) => x.isModified)

    const isSaving =
        createChargePointState.status === 'loading' || updateChargePointState.status === 'loading'

    const saveEnabled = allValid && anyModified && !isSaving

    const resetFields = () => chargePointFields.forEach((x) => x.reset())

    const handleSave = () => {
        if (isNew) {
            executeCreateChargePoint({
                chargePointId: chargePointIdField.value,
                accessControl: accessControlField.value,
                clusterId: +clusterIdField.value,
            })
                .then((res) => {
                    if (Number.isInteger(res.data?.createChargePoint.id)) {
                        setControllerConfig((prev) => ({
                            ...prev,
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                            chargePoints: prev.chargePoints.map((x) =>
                                x === chargePoint ? { ...x, ...res.data!.createChargePoint } : x
                            ),
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when creating charge point',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) => dispatchPopup({ message: 'Failed to create charge point: ' + err }))
        } else {
            executeUpdateChargePoint({
                id: chargePoint.id!,
                chargePointId: chargePointIdField.value,
                accessControl: accessControlField.value,
                clusterId: +clusterIdField.value,
            })
                .then((res) => {
                    if (Number.isInteger(res.data?.updateChargePoint.id)) {
                        setControllerConfig((prev) => ({
                            ...prev,
                            controller: {
                                ...prev.controller,
                                isDirty: true,
                            },
                            chargePoints: prev.chargePoints.map((x) =>
                                x === chargePoint ? { ...x, ...res.data!.updateChargePoint } : x
                            ),
                        }))
                        resetFields()
                    } else {
                        dispatchPopup({
                            title: 'Something went wrong when updating charge point',
                            message: JSON.stringify(res.data),
                        })
                    }
                })
                .catch((err) =>
                    dispatchPopup({
                        title: 'Failed to update charge point',
                        message: err.toString(),
                    })
                )
        }
    }

    const handleDelete = () => {
        if (isNew) {
            setControllerConfig((prev) => ({
                ...prev,
                chargePoints: prev.chargePoints.filter((x) => x !== chargePoint),
            }))
        } else {
            setShowDeleteModal(true)
        }
    }

    return (
        <NestableFrame className={style.container}>
            <div className={style.chargePointRow}>
                <ValidatedTextField
                    title={t('controllerConfigPageChargePointFieldChargePointId')}
                    field={chargePointIdField}
                    forceModified={isNew}
                />
                <ValidatedSelectField
                    title={t('controllerConfigPageChargePointFieldAccessControl')}
                    field={accessControlField}
                    options={accessControlValues.map((x) => ({ value: x }))}
                    forceModified={isNew}
                />
                <ValidatedSelectField
                    title={t('controllerConfigPageChargePointFieldCluster')}
                    field={clusterIdField}
                    options={validClusters.map((x) => ({
                        value: x.id!.toString(),
                        text: x.clusterId,
                    }))}
                    forceModified={isNew}
                />
                <div className={style.buttonRow}>
                    <SaveButton
                        text={t('controllerConfigPageChargePointSave')}
                        click={handleSave}
                        disabled={!saveEnabled}
                        loading={isSaving}
                    />
                    <TrashCanButton
                        onClick={handleDelete}
                        className={style.trashCan}
                        title={t('controllerConfigPageChargePointDelete')}
                    />
                </div>
            </div>
            <ConnectorConfigList connectors={chargePoint.connectors} chargePoint={chargePoint} />
            <DeleteChargePointModal
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                chargePoint={chargePoint}
            />
        </NestableFrame>
    )
}
