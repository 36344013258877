import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface ZoomProviderProps {
    children: React.ReactElement;
}

interface ZoomProps {
    mirrorZoom: boolean;
    setMirrorZoom?: React.Dispatch<React.SetStateAction<boolean>>, 
    isZoomed: boolean;
    setIsZoomed?: React.Dispatch<React.SetStateAction<boolean>>, 
    startDate?: string,
    endDate?: string,
    setZoom?: (isZoomed: boolean, startDate?: string, endDate?: string) => void
};

export const ZoomContext = createContext<ZoomProps>({isZoomed: false, mirrorZoom: false});

export const ZoomProvider = (props: ZoomProviderProps) => {
    const [mirrorZoom, setMirrorZoom] = useState<boolean>(false)
    const [isZoomed, setIsZoomed] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<string>()    
    const [endDate, setEndDate] = useState<string>()

    const setZoom = useCallback((isZoomed: boolean, startDate?: string, endDate?: string) => {
        setIsZoomed(isZoomed);
            setStartDate(startDate)
            setEndDate(endDate)
    },
    [])


    const context = useMemo(()=>{
        return {
            mirrorZoom,
            setMirrorZoom,
            isZoomed,
            startDate,
            endDate,
            setZoom,
        }
    },[
            mirrorZoom,
            setMirrorZoom,
            isZoomed,
            startDate,
            endDate,
            setZoom,
        ]);

    return <ZoomContext.Provider value={context} {...props}/>
}

export const useZoom = (): ZoomProps => useContext(ZoomContext);
