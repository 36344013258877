import { useGraphQlMutation } from "common";

const assignDeviceMutation = `
mutation CreateControllerConfig(
    $locationId: Int!
    $deviceId: String!
    $name: String!
    $provisioningId: UUID!
) {
    createControllerConfig(
        locationId: $locationId
        deviceId: $deviceId
        name: $name
        provisioningId: $provisioningId
    ) {
        id
        locationId
        deviceId
        name
        loadBalancingEnabled
        sensorLoggerEnabled
        sensorLoggerIntervalMs
        isOcppProxyEnabled
    }
}
`;


export type createControllerConfigResponse = {
    data: {
        createControllerConfig: {
            id: number;
            locationId: number;
            deviceId: string;
            name: string;
            provisioningId: string;
        }
    }
}

export type assignDeviceMutationArgs = { locationId: number; deviceId: string; name: string, provisioningId: string; };

export default function useAssignDevice() {
    return useGraphQlMutation<createControllerConfigResponse, assignDeviceMutationArgs>(assignDeviceMutation);
}
