import { Button, Modal, Spinner } from 'common'
import { FirmwareManifest } from 'firmware/types/firmwaremanifest'
import { EditingMode, IKaTableProps, SortingMode, Table, ITableProps, kaReducer } from 'ka-table'
import { ICellProps, ICellTextProps, IHeadCellProps } from 'ka-table/props'
import { DispatchFunc } from 'ka-table/types'
import {
    deselectAllRows,
    deselectRow,
    selectAllRows,
    selectRow,
    updateData,
} from 'ka-table/actionCreators'
import { useEffect, useState } from 'react'
import { ChargePoint } from 'chargePoint/types/chargePoint'
import { useTranslation } from 'react-i18next'
import useRequestFirmwareUpgrade from 'firmware/hooks/useRequestFirmwareUpgrade'

interface UploadFirmwareModalProps {
    show: boolean
    onClickOutside?: React.MouseEventHandler
    firmwareManifest?: FirmwareManifest
    controllerId: string
    chargePoints: ChargePoint[]
}

const SelectionCell: React.FC<ICellTextProps> = ({ rowKeyValue, dispatch, isSelectedRow }) => {
    return (
        <input
            type="checkbox"
            checked={isSelectedRow}
            onChange={(event: any) => {
                if (event.currentTarget.checked) {
                    dispatch(selectRow(rowKeyValue))
                } else {
                    dispatch(deselectRow(rowKeyValue))
                }
            }}
        />
    )
}

const SelectionHeader = ({ props }: { props: IHeadCellProps }) => {
    return (
        <input
            type="checkbox"
            checked={props.areAllRowsSelected}
            onChange={(_) => {
                if (props.areAllRowsSelected) {
                    props.dispatch(deselectAllRows())
                } else {
                    props.dispatch(selectAllRows())
                }
            }}
        />
    )
}

export default function UploadFirmwareModal({
    show,
    onClickOutside,
    firmwareManifest,
    controllerId,
    chargePoints,
}: UploadFirmwareModalProps) {
    const { t } = useTranslation()

    const requestFirmwareUpgrade = useRequestFirmwareUpgrade()[1]

    const initTableProps: IKaTableProps = {
        columns: [
            { key: 'selection-cell', style: { width: 4 } },
            { key: 'id', title: t('id'), style: { width: 64 } },
            { key: 'vendor', title: t('vendor'), style: { width: 84 } },
            { key: 'model', title: t('model'), style: { width: 84 } },
            { key: 'firmwareVersion', title: t('firmwareVersion'), style: { width: 96 } },
            { key: 'isUpgrading', style: { width: 4 } },
        ],
        data: [],
        height: '360px',
        rowKeyField: 'id',
        sortingMode: SortingMode.Single,
        editingMode: EditingMode.None,
        childComponents: {
            cell: {
                content: (props: ICellProps) => {
                    const firmwareManifest = props.rowData['firmwareManifest']
                    switch (props.column.key) {
                        case 'selection-cell':
                            return <SelectionCell {...props} />
                        case 'firmwareVersion':
                            if (firmwareManifest?.version === props.rowData['firmwareVersion']) {
                                return <div>{t('upToDate')}</div>
                            } else {
                                return (
                                    <div className="text-xs flex flex-row justify-between">
                                        <div>{props.rowData['firmwareVersion']}</div>
                                        <div>→</div>
                                        <div>{firmwareManifest?.version ?? 'unknown'}</div>
                                    </div>
                                )
                            }
                        case 'isUpgrading':
                            return props.rowData['isUpgrading'] ? <Spinner size={30} /> : null
                        default:
                            return (
                                <div className="text-xs break-words">
                                    {props.rowData[props.column.key]}
                                </div>
                            )
                    }
                },
                elementAttributes: (props: ICellProps) => {
                    const firmwareManifest = props.rowData['firmwareManifest']

                    const sameVendor = firmwareManifest?.vendor === props.rowData['vendor']
                    const sameModel = firmwareManifest?.model === props.rowData['model']
                    const sameFirmware = firmwareManifest?.validUpgradeVersions.includes(
                        props.rowData['firmwareVersion']
                    )

                    const red = 'rgb(154, 12, 12)'
                    const yellow = 'rgb(154, 108, 4)'
                    const green = 'rgb(16, 154, 64)'

                    let color
                    if (props.column.key === 'vendor') {
                        color = sameVendor ? green : red
                    } else if (props.column.key === 'model') {
                        color = sameModel ? (sameVendor ? green : yellow) : red
                    } else if (props.column.key === 'firmwareVersion') {
                        color = sameVendor && sameModel ? (sameFirmware ? green : yellow) : red
                    }
                    return { style: { color } }
                },
            },
            headCell: {
                content: (props: IHeadCellProps) => {
                    if (props.column.key === 'selection-cell') {
                        return <SelectionHeader props={props} />
                    }
                },
            },
        },
    }

    const [isUpgrading, setIsUpgrading] = useState(false)
    const [tableProps, changeTableProps] = useState(initTableProps)
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) => kaReducer(prevState, action))
    }

    useEffect(() => {
        dispatch(deselectAllRows())
        const data = chargePoints
            .filter(
                (chargePoint: ChargePoint) =>
                    chargePoint?.vendor === firmwareManifest?.vendor &&
                    chargePoint?.model === firmwareManifest?.model &&
                    firmwareManifest?.validUpgradeVersions.includes(chargePoint?.firmwareVersion ?? '')
            )
            .map((cp: ChargePoint) => ({ ...cp, firmwareManifest }))

        dispatch(updateData(data))
    }, [chargePoints, firmwareManifest])

    const modalContent = (
        <div className="grid grid-cols-3 max-w-6xl">
            <div className="col-span-2 p-4 pr-2 border-r border-gray-200">
                <h2 className="text-lg text-slate-600">{t('chargePointListTitle')}</h2>
                <div className="w-full border-b border-gray-200 py-2" />
                <Table {...tableProps} dispatch={dispatch} />
                <div className="text-xs">
                    {t('uploadFirmwareModalUpgradableChargePoints')}: {tableProps.data?.length}
                </div>
            </div>
            <div className="col-span-1 p-4 pr-2 flex flex-col">
                <h2 className="text-lg text-slate-600">{t('selectedFirmware')}</h2>
                <div className="w-full border-b border-gray-200 py-2 mb-4" />
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row justify-between border-b border-gray-200">
                        <div className="text-sm">{t('filename')}</div>
                        <div className="text-xs text-right w-2/3 break-words">
                            {firmwareManifest?.filename}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between border-b border-gray-200">
                        <div className="text-sm">{t('vendor')}</div>
                        <div className="text-xs">{firmwareManifest?.vendor}</div>
                    </div>
                    <div className="flex flex-row justify-between border-b border-gray-200">
                        <div className="text-sm">{t('model')}</div>
                        <div className="text-xs">{firmwareManifest?.model}</div>
                    </div>
                    <div className="flex flex-row justify-between border-b border-gray-200">
                        <div className="text-sm">{t('version')}</div>
                        <div className="text-xs">{firmwareManifest?.version}</div>
                    </div>
                </div>
                {
                    <Button
                        type="secondary"
                        className="text-xs h-12 mt-auto w-full text-center"
                        disabled={
                            tableProps.selectedRows?.length === 0 || firmwareManifest === undefined || isUpgrading
                        }
                        click={() => {
                            setIsUpgrading(true)
                            requestFirmwareUpgrade({
                                deviceId: controllerId,
                                chargePointIds: tableProps.selectedRows as string[],
                                firmware: firmwareManifest!.filename,
                            }).then((res) => {
                                if (res.error) {
                                    setIsUpgrading(false)
                                } else {
                                    alert(t('upgradeQueuedSuccessfully'))
                                }
                            })
                        }}
                    >
                        {t('upgradeSelected')}
                    </Button>
                }
            </div>
        </div>
    )

    let content = null
    if (chargePoints.length > 0) {
        content = modalContent
    } else {
        content = <Spinner size={30} />
    }

    return (
        <Modal show={show} onClickOutside={isUpgrading ? () => {} : onClickOutside}>
            {content}
        </Modal>
    )
}
