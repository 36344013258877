import { Breadcrumb, Card, OverviewPageLink, SpinnerWithHeight } from 'common'
import { CurrentPage, LocationListPageLink } from 'common/components/Breadcrumb/Breadcrumb'
import LocationConfig from 'config/components/LocationConfig/LocationConfig'
import useLocation from 'location/hooks/useLocation'
import { useParams } from 'react-router-dom'
import { CombinedError } from 'urql'
import style from './LocationPage.module.scss'

export default function LocationPage(): JSX.Element {
    const { locationId } = useParams() as { locationId: string }

    function onError(error: CombinedError) {
        return (
            <>
                <div>Error: {error.name}</div>
                <div>Cause: {error.toString()}</div>
            </>
        )
    }

    const [loadable, refetch] = useLocation(+locationId)

    const getContent = () => {
        switch (loadable.status) {
            case 'loading':
                return <SpinnerWithHeight height={163} />
            case 'success':
                return <LocationConfig config={loadable.data} refreshLocation={refetch} />
            case 'error':
                return onError(loadable.error)
        }
    }

    const locationName = loadable.status === 'success' ? loadable.data.location.name : null

    return (
        <>
            <Breadcrumb className={style.breadcrumb}>
                <OverviewPageLink />
                <LocationListPageLink />
                <CurrentPage currentPage={locationName || locationId} />
            </Breadcrumb>
            <div className={style.page}>
                <Card className={style.card}>{getContent()}</Card>
            </div>
        </>
    )
}
