import classNames from 'classnames'
import { ReactNode } from 'react'
import style from './Modal.module.scss'
import { IconButton } from 'common'
import { ReactComponent as CloseIcon } from 'img/close.svg'

interface ModalProps {
    show: boolean
    children?: ReactNode
    onClickOutside?: React.MouseEventHandler
    className?: string
}

export default function Modal({ show, children, onClickOutside, className }: ModalProps) {
    const classes = classNames(style.modal, { [`${className}`]: className })
    return show ? (
        <div className={style.background} onClick={onClickOutside}>
            <div className={classes} onClick={(e) => e.stopPropagation()}>
            <IconButton
                className={style.modalCloseButton}
                click={onClickOutside as ()=>void}
                icon={CloseIcon}
                type="secondary"
            />
                {children}
            </div>
        </div>
    ) : null
}
