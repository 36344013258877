import { Breadcrumb, ControllerPageLink, CurrentPage, OverviewPageLink } from 'common'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ChargePointPageLink } from 'common/components/Breadcrumb/Breadcrumb'
import useChargePointTransactionList from 'chargePoint/hooks/useChargePointTransactionList'
import ChargePointTransactionList from 'chargePoint/components/ChargePointTransactionList/ChargePointTransactionList'

export default function ChargePointTransactionssPage(): JSX.Element {
    const { controllerId, chargePointId } = useParams() as {
        controllerId: string
        chargePointId: string
    }

    const { t } = useTranslation()

    const transactions = useChargePointTransactionList(controllerId, chargePointId)

    return (
        <>
            <Breadcrumb>
                <OverviewPageLink />
                <ControllerPageLink controllerId={controllerId} />
                <ChargePointPageLink controllerId={controllerId} chargePointId={chargePointId} />
                <CurrentPage currentPage={t('chargePointTransactionsTitle')} />
            </Breadcrumb>
            <div className="flex flex-row gap-2 mt-4">
                <ChargePointTransactionList transactions={transactions} />
            </div>
        </>
    )
}
