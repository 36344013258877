import { Button, ConfirmModalButtonRow, Modal, SpinnerWithHeight } from 'common'
import useConfigureLoggingLevel from 'controller/hooks/useConfigureLoggingLevel'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import style from './LoggingConfigurationModal.module.scss'

const loggingLevels = ['ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE']

interface LoggingConfigurationModalProps {
    show: boolean
    close: () => void
    controllerId: string
}

export default function LoggingConfigurationModal({
    show,
    close,
    controllerId,
}: LoggingConfigurationModalProps) {
    const { t } = useTranslation()
    const selectRef = useRef<HTMLSelectElement | null>(null)
    const [configureLoggingResult, configureLogging, reset] = useConfigureLoggingLevel()

    const handleClose = () => {
        if (
            configureLoggingResult.status === 'success' ||
            configureLoggingResult.status === 'error'
        ) {
            reset()
        }
        close()
    }

    const getModalContent = () => {
        switch (configureLoggingResult.status) {
            case 'idle':
                return (
                    <>
                        <span>{t('controllerLoggingConfigurationModal', { controllerId })}</span>
                        <select
                            ref={selectRef}
                            className={style.select}
                            defaultValue={t('controllerLoggingConfigurationModalPlaceholderOption')}
                        >
                            <option disabled={true}>
                                {t('controllerLoggingConfigurationModalPlaceholderOption')}
                            </option>
                            {loggingLevels.map((loggingLevel) => (
                                <option value={loggingLevel} key={loggingLevel}>
                                    {t(
                                        `controllerLoggingConfigurationModalLogLevel${loggingLevel}`
                                    )}
                                </option>
                            ))}
                        </select>
                        <ConfirmModalButtonRow
                            onCancel={handleClose}
                            onConfirm={() => {
                                if (
                                    !selectRef.current ||
                                    !loggingLevels.includes(selectRef.current.value)
                                ) {
                                    return
                                }
                                configureLogging({
                                    deviceId: controllerId,
                                    logLevel: selectRef.current.value,
                                })
                            }}
                        />
                    </>
                )
            case 'loading':
                return <SpinnerWithHeight height={72} />
            case 'error':
                return (
                    <>
                        <span
                            className={style.actionResultError}
                        >{`Failed to set logging filter: ${configureLoggingResult.error}`}</span>
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'success':
                return (
                    <>
                        {configureLoggingResult.data.configureLoggingFilter ? (
                            <span className={style.actionResultSuccess}>
                                {t('controllerLoggingConfigurationModalSuccess')}
                            </span>
                        ) : (
                            <span className={style.actionResultError}>
                                Failed to set logging filter
                            </span>
                        )}
                        <Button
                            className={style.modalCloseButton}
                            type="primary"
                            click={handleClose}
                        >
                            {t('modalClose')}
                        </Button>
                    </>
                )
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('controllerLoggingConfigurationModalTitle')}</h2>
            {getModalContent()}
        </Modal>
    )
}
