import {
    Button,
    IconLink,
    Modal,
    SpinnerWithHeight,
    ValidatedSelectField,
    ValidatedTextField,
} from 'common'
import useValidatedField from 'common/hooks/useValidatedField'
import SaveButton from 'config/components/SaveButton/SaveButton'
import { stringNonEmptyValidator } from 'config/utils/validators'
import useCreateLocation from 'location/hooks/useCreateLocation'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LocationIcon } from 'img/location.svg'
import style from './CreateLocationModal.module.scss'
import usePopupModal from 'common/hooks/usePopupModal'
import { BusinessUnit } from 'config/types/controllerConfig'
import snakeCaseToPascalCase from 'common/utils/snakeCaseToPascalCase'

interface CreateLocationModalProps {
    close: () => void
    refreshLocations: () => void
    disallowedNames: string[]
    allowedBusinessUnits: BusinessUnit[]
    show: boolean
}

export default function CreateLocationModal({
    close,
    refreshLocations,
    disallowedNames,
    allowedBusinessUnits,
    show,
}: CreateLocationModalProps) {
    const { t } = useTranslation()
    const nameField = useValidatedField<string>('', stringNonEmptyValidator)
    const businessUnitField = useValidatedField<BusinessUnit>(
        allowedBusinessUnits[0],
        (businessUnit) => allowedBusinessUnits.some((x) => x === businessUnit)
    )
    const dispatchPopup = usePopupModal()

    const [createLocation, executeCreateLocation, resetCreateLocation] = useCreateLocation()

    const handleCreateLocation = () => {
        if (disallowedNames.includes(nameField.value)) {
            dispatchPopup({
                title: 'Failed to create location',
                message: 'Location name already exists',
            })
            return
        }
        executeCreateLocation({
            name: nameField.value,
            businessUnit: businessUnitField.value as BusinessUnit,
        })
    }

    const handleClose = () => {
        if (createLocation.status !== 'loading') {
            nameField.reset()
            resetCreateLocation()
        }
        if (createLocation.status === 'success') {
            refreshLocations()
        }
        close()
    }

    const options = allowedBusinessUnits.map((businessUnit) => ({
        value: businessUnit,
        text: snakeCaseToPascalCase(businessUnit),
    }))

    const getContent = () => {
        switch (createLocation.status) {
            case 'idle':
                return (
                    <div className={style.container}>
                        <ValidatedTextField
                            field={nameField}
                            title={t('createLocationModalFieldName')}
                        />
                        <ValidatedSelectField
                            field={businessUnitField}
                            title={t('createLocationModalFieldBusinessUnit')}
                            options={options}
                            forceModified
                        />
                        <div className={style.buttonRow}>
                            <Button type="secondary" click={handleClose}>
                                {t('modalCancel')}
                            </Button>
                            <SaveButton
                                click={handleCreateLocation}
                                text={t('createLocationModalSave')}
                                disabled={!nameField.isValid}
                            />
                        </div>
                    </div>
                )
            case 'loading':
                return <SpinnerWithHeight height={50} />
            case 'success':
                return (
                    <>
                        <p className={style.successText}>{t('createLocationModalSuccess')}</p>
                        <IconLink
                            to={`/location/${createLocation.data.createLocation.id}`}
                            text={createLocation.data.createLocation.name}
                            icon={LocationIcon}
                        />
                        <Button type="secondary" click={handleClose} className={style.closeButton}>
                            {t('modalClose')}
                        </Button>
                    </>
                )
            case 'error':
                return `Failed to create location: ${createLocation.error}`
        }
    }

    return (
        <Modal show={show} onClickOutside={handleClose} className={style.modal}>
            <h2>{t('createLocationModalTitle')}</h2>
            {getContent()}
        </Modal>
    )
}
