import { AccessControl, ChargePoint } from 'chargePoint'
import { ChargePriority } from 'chargePoint/types/chargePoint'
import unique from 'just-unique'

interface ConfigChargePointData {
    id: string
    accessControl: AccessControl
    clusterId: string
    connectors: {
        id: number
        minAmpere: number
        maxAmpere: number
        phaseMapping: string
        priority: ChargePriority
    }[]
}

interface StateChargePointData {
    id: string
    ip: string
    isConnected: boolean
    vendor: string
    fwVer: string
    serial: string
    model: string
    fwStatus: string
    diagStatus: string
    connectors: {
        id: number
        loadLimit: number
        state: string
        errorCode: string
    }[]
}

export default function mergeChargePointData(
    chargePointsFromConfig: ConfigChargePointData[],
    chargePointsFromState: StateChargePointData[],
    controllerId: string
): ChargePoint[] {
    const chargePointIds = unique([
        ...chargePointsFromConfig.map((x) => x.id),
        ...chargePointsFromState.map((x) => x.id),
    ])

    const chargePoints = chargePointIds.map((id) => {
        const fromConfig = chargePointsFromConfig.find((x) => x.id === id)
        const fromState = chargePointsFromState.find((x) => x.id === id)

        const connectorIds = []
        if (fromConfig) {
            connectorIds.push(...fromConfig.connectors.map((x) => x.id))
        }
        if (fromState) {
            connectorIds.push(...fromState.connectors.map((x) => x.id))
        }

        const connectors = unique(connectorIds).map((connectorId) => {
            const cCon = fromConfig?.connectors.find((x) => x.id === connectorId)! || {}
            const sCon = fromState?.connectors.find((x) => x.id === connectorId)! || {}

            return {
                id: cCon.id || sCon.id,
                minAmpere: cCon.minAmpere,
                maxAmpere: cCon.maxAmpere,
                phaseMapping: cCon.phaseMapping,
                priority: cCon.priority,
                loadLimit: sCon.loadLimit,
                state: sCon.state,
                errorCode: sCon.errorCode,
            }
        })

        const fc = fromConfig! || {}
        const fs = fromState! || {}

        return {
            id: fc.id || fs.id,
            controllerId: controllerId,
            accessControl: fc.accessControl,
            clusterId: fc.clusterId,
            ip: fs.ip,
            isConnected: fs.isConnected,
            vendor: fs.vendor,
            firmwareVersion: fs.fwVer,
            serialNumber: fs.serial,
            model: fs.model,
            firmwareStatus: fs.fwStatus,
            diagnosticStatus: fs.diagStatus,
            connectors: connectors,
        }
    })

    return chargePoints
}
