import MaterialSpinner from 'react-spinner-material'

interface SpinnerProps {
    size: 60 | 30 | 16
    className?: string
}

export default function Spinner({ size, className }: SpinnerProps): JSX.Element {
    return (
        <MaterialSpinner
            className={className}
            radius={size}
            stroke={(size === 16) ? 2 : 4}
            color="#1976d2"
        />
    )
}
