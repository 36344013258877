import { Transaction } from 'chargePoint/types/transaction'
import { Card, Loadable, SpinnerWithHeight } from 'common'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import style from './ChargePointTransactionList.module.scss'

interface ChargePointTransactionListProps {
    transactions: Loadable<Transaction[]>
}

export default function ChargePointTransactionList({
    transactions,
}: ChargePointTransactionListProps) {
    const { t } = useTranslation()

    const getContent = () => {
        switch (transactions.status) {
            case 'loading':
                return <SpinnerWithHeight height={200} />
            case 'error':
                return `Failed to load charge point transactions: ${transactions.error}`
            case 'success':
                return (
                    <table>
                        <thead>
                            <tr>
                                <th>{t('chargePointTransactionListConnector')}</th>
                                <th>{t('chargePointTransactionListStartTime')}</th>
                                <th>{t('chargePointTransactionListDuration')}</th>
                                <th>{t('chargePointTransactionListEnergyAmount')}</th>
                                <th>{t('chargePointTransactionListStartTag')}</th>
                                <th>{t('chargePointTransactionListStopReason')}</th>
                                <th>{t('chargePointTransactionListAccess')}</th>
                                <th>{t('chargePointTransactionListErp')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.data.map((tx) => (
                                <tr key={tx.transactionId}>
                                    <td>{tx.connector}</td>
                                    <td>
                                        <span title={moment(tx.startTimestamp).toLocaleString()}>
                                            {moment(tx.startTimestamp).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                            )}
                                        </span>
                                    </td>
                                    <td>{getDuration(tx.startTimestamp, tx.stopTimestamp)}</td>
                                    <td>{getWattHours(tx.meterStartWh, tx.meterStopWh)}</td>
                                    <td>{tx.startIdTag}</td>
                                    <td>{tx.reason}</td>
                                    <td>{tx.access}</td>
                                    <td>{tx.startErp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
        }
    }

    return (
        <Card className={style.card} title={t('chargePointTransactionListTitle')}>
            {getContent()}
        </Card>
    )
}

function getDuration(start: string, end: string | null): string {
    if (!end) {
        return '---'
    }

    const durationSeconds = (new Date(end).getTime() - new Date(start).getTime()) / 1000

    const hours = Math.floor(durationSeconds / 3600)
    const minutes = Math.floor((durationSeconds % 3600) / 60)
    const seconds = durationSeconds % 60

    if (durationSeconds < 60) {
        return `${seconds}s`
    }
    if (durationSeconds < 3600) {
        return `${minutes}m ${seconds}s`
    }
    return `${hours}h ${minutes}m ${seconds}s`
}

function getWattHours(start: number, end: number | null) {
    if (end == null) {
        return '---'
    }

    return `${end - start} Wh`
}
