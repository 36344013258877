import { ControllerConfigComposite } from 'config/types/controllerConfig'
import React, { useContext } from 'react'

export type ControllerConfigContextType = [
    ControllerConfigComposite,
    (reducer: (oldState: ControllerConfigComposite) => ControllerConfigComposite) => void
]

export const ControllerConfigContext = React.createContext<ControllerConfigContextType>(null as any)

export default function ControllerConfigProvider() {
    return useContext(ControllerConfigContext)
}
