import { LogoutButton } from 'auth'
import { ReactComponent as DashboardIcon } from 'img/dashboard.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './Header.module.scss'
import { useMsal } from '@azure/msal-react'

export default function Header(): JSX.Element {
    const { t } = useTranslation()
    const { accounts } = useMsal()
    return (
        <div className={style.header}>
            <Link className={style.iconLink} to={'/'}>
                <DashboardIcon className={style.dashboardIcon} />
            </Link>
            <h1>
                <Link to={'/'}>{t('headerTitle')}</Link>
            </h1>
            {accounts.length > 0 && <LogoutButton />}
        </div>
    )
}
